// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from "lodash";
import { useState } from "react";

import {
  Flex,
  Input,
  TabContent,
  Textarea,
  makeToast,
} from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";

import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  InputLabel,
  RequestForm,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { requiredLabel } from "src/components/required";
import { JiraIssueRequest, useCreateIssue } from "src/hooks/useCreateIssue";
import { getUserInformation } from "src/utils";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { ThankYou } from "src/pages/thank-you";
import { StringParam, useQueryParam } from "use-query-params";
import {
  AutomationPriorityOptions,
  AutomationRequestType,
  AutomationRequestTypeOptions,
  BugPriorityOptions,
  DescribeRequest,
  GPTChatVersionOptions,
  ProductOptions,
  Products,
  RequestCategory,
  RequestCategoryOptions,
  TestProp,
  WorkImpactOptions,
} from "./data";
import { getJiraPriorityId } from "./utils/jiraUtils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GrowthEngineering = (props: TestProp) => {
  const userInfo = getUserInformation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState(1);
  const [describeRequest] = useQueryParam("describe_request", StringParam);
  const [requestTitle, setRequestTitle] = useState<string>("");
  const [requestCategory, setRequestCategory] = useState<string>("");
  const [product, setProduct] = useState<string>("");
  const [workImpact, setWorkImpact] = useState<string>("");
  const [keyStakeholders, setKeyStakeholders] = useState<string>("");
  const [context, setContext] = useState<string>("");
  const [acceptanceCriteriaNew, setAcceptanceCriteriaNew] =
    useState<string>("");
  const [acceptanceCriteriaExisting, setAcceptanceCriteriaExisting] =
    useState<string>("");
  const [automationRequestType, setAutomationRequestType] =
    useState<string>("");
  const [requestFeedbackSummary, setRequestFeedbackSummary] =
    useState<string>("");
  const [gptChatVersion, setGptChatVersion] = useState<string>("");
  const [requestSummary, setRequestSummary] = useState<string>("");
  const [automationPriority, setAutomationPriority] = useState<string>("");
  const [bugSummary, setBugSummary] = useState<string>("");
  const [acceptanceCriteriaBug, setAcceptanceCriteriaBug] =
    useState<string>("");
  const [bugPriority, setBugPriority] = useState<string>("");
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();

  const onSubmit = async () => {
    if (createJiraIssueRes.loading) {
      return; // Prevent duplicate submissions
    }

    let issueData = `
          *Reporter*: ${userInfo?.email}
          *Request Type*: ${describeRequest}`;
    issueData += `
          *Title*: ${requestTitle}`;
    if (
      describeRequest === DescribeRequest.ProductRequestFeedback &&
      requestCategory === RequestCategory.NewProduct
    ) {
      issueData += `
            *Request/Feedback Summary*: ${requestFeedbackSummary}
            *Work Impact*: ${workImpact}
            *Key Stakeholders*: ${keyStakeholders}
            *Acceptance Criteria*: ${acceptanceCriteriaNew}
          `;
    }
    if (
      describeRequest === DescribeRequest.ProductRequestFeedback &&
      requestCategory === RequestCategory.ExistingProduct
    ) {
      issueData += `
        *Product*: ${product}`;
      if (product === Products.GPT) {
        issueData += `
          *Chat Version*: ${gptChatVersion}`;
      }
      issueData += `      
           *Context/Challenge*: ${context}
            *Acceptance Criteria*: ${acceptanceCriteriaExisting}
          `;
    }
    if (describeRequest === DescribeRequest.WorkflowAutomation) {
      issueData += `
            *Request Summary*: ${requestSummary}
            *Work Impact*: ${workImpact}
            *Priority*: ${automationPriority}
            *Key Stakeholders*: ${keyStakeholders}
          `;
    }
    if (describeRequest === DescribeRequest.BugReport) {
      issueData += `
        *Product*: ${product}`;
      if (product === Products.GPT) {
        issueData += `
          *Chat Version*: ${gptChatVersion}`;
      }
      issueData += `
        *Acceptance Criteria*: ${acceptanceCriteriaBug}
        *Bug Summary*: ${bugSummary}
        *Bug Priority*: ${bugPriority}
      `;
    }
    const jiraConfig = getJiraConfig("Growth Engineering", {
      describeRequest,
      requestCategory,
    });
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!");
      return; // Required
    }

    const priorityId = getJiraPriorityId(
      describeRequest,
      automationPriority,
      bugPriority
    );

    const jiraIssueRequest: JiraIssueRequest = {
      fields: {
        project: {
          key: jiraConfig.jiraProjectKey,
        },
        description: issueData,
        summary: requestTitle,
        issuetype: {
          name: "Task",
        },
        // eslint-disable-next-line camelcase
        customfield_10000: jiraConfig.epicLink,
        labels: jiraConfig.labels,
        priority: {
          id: priorityId,
        },
      },
    };
    if (describeRequest === DescribeRequest.BugReport) {
      jiraIssueRequest.fields.issuetype.name = "Bug";
    } else {
      jiraIssueRequest.fields.issuetype.name = "Task";
    }

    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      // If result is null and there's an error state, display the toast notification
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };

  const reset = () => {
    setContext("");
  };

  const disabledSubmitButton =
    !describeRequest ||
    !requestTitle ||
    (describeRequest === DescribeRequest.BugReport &&
      (!product || !acceptanceCriteriaBug || !bugPriority || !bugSummary)) ||
    (describeRequest === DescribeRequest.ProductRequestFeedback &&
      !requestCategory) ||
    (describeRequest === DescribeRequest.ProductRequestFeedback &&
      requestCategory === RequestCategory.NewProduct &&
      (!workImpact || !requestFeedbackSummary)) ||
    (describeRequest === DescribeRequest.ProductRequestFeedback &&
      requestCategory === RequestCategory.ExistingProduct &&
      (!context || !acceptanceCriteriaExisting || !product)) ||
    (describeRequest === DescribeRequest.WorkflowAutomation &&
      automationRequestType !== AutomationRequestType.Slidebot &&
      (!workImpact ||
        !automationRequestType ||
        !requestSummary ||
        !automationPriority)) ||
    createJiraIssueRes.loading;
  return (
    <>
      <NavigateBackButton />
      <RequestForm>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>{describeRequest}</FormHeading>
              <TabContent active={activeTab === 1}>
                <FormDetails>
                  {describeRequest ===
                    DescribeRequest.ProductRequestFeedback && (
                    <InputLabel
                      label="Request Category"
                      htmlFor="request-category"
                      formatLabel={requiredLabel}
                    >
                      <SubText>
                        Please choose the category that best fits your request.
                        This could be a technical issue, a new feature request,
                        a process improvement suggestion, etc.
                      </SubText>
                      <Select
                        inputId="request-category"
                        isClearable
                        isDisabled={false}
                        isSearchable
                        fluid
                        options={RequestCategoryOptions}
                        onChange={(selectedOption) => {
                          if (!selectedOption) {
                            reset();
                          }
                          setRequestCategory(selectedOption?.value);
                        }}
                        value={RequestCategoryOptions.find(
                          (o) => o.value === requestCategory
                        )}
                      />
                    </InputLabel>
                  )}
                  <InputLabel
                    label="Title"
                    htmlFor="request-title"
                    formatLabel={requiredLabel}
                  >
                    <SubText>Enter a brief title for your request.</SubText>
                    <Input
                      id="request-title"
                      required
                      maxLength={200}
                      fullWidth
                      value={requestTitle}
                      onChange={(e) => setRequestTitle(e.target.value)}
                    />
                  </InputLabel>

                  {requestCategory === RequestCategory.NewProduct && (
                    <>
                      <InputLabel
                        label="Request/Feedback Summary"
                        htmlFor="request-feedback-summary"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Please provide a brief summary of your request or
                          feedback. This should underline your desired product
                          functionalities, the reasons behind its necessity, and
                          any specific features it should encompass. or
                          highlight the primary problems you're encountering.
                        </SubText>
                        <Textarea
                          id="request-feedback-summary"
                          vSize="xl"
                          fullWidth
                          maxLength={500}
                          value={requestFeedbackSummary}
                          onChange={(e) =>
                            setRequestFeedbackSummary(e.target.value)
                          }
                        />
                      </InputLabel>
                      <InputLabel
                        label="Acceptance Criteria"
                        htmlFor="acceptance-criteria-new"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          List the specific conditions that the product must
                          meet for your request to be accepted. These could
                          include functional requirements, performance
                          standards, or other criteria that will determine
                          whether the product meets your needs.
                        </SubText>
                        <Textarea
                          id="acceptance-criteria-new"
                          vSize="lg"
                          fullWidth
                          value={acceptanceCriteriaNew}
                          onChange={(e) =>
                            setAcceptanceCriteriaNew(e.target.value)
                          }
                        />
                      </InputLabel>
                      <InputLabel
                        label="Work Impact"
                        htmlFor="product-work-impact"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Select the level of impact this request will have on
                          your work or your team's work.
                        </SubText>
                        <Select
                          inputId="product-work-impact"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={WorkImpactOptions}
                          onChange={(selectedOption) => {
                            if (!selectedOption) {
                              reset();
                            }
                            setWorkImpact(selectedOption?.value);
                          }}
                          value={WorkImpactOptions.find(
                            (o) => o.value === workImpact
                          )}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Key Stakeholders"
                        htmlFor="key-stakeholders"
                      >
                        <SubText>
                          List the people who will be most affected by this
                          request or who need to be involved in the
                          decision-making process. This could include team
                          members, managers, or other departments.
                        </SubText>
                        <Input
                          id="key-stakeholders"
                          fullWidth
                          value={keyStakeholders}
                          onChange={(e) => setKeyStakeholders(e.target.value)}
                        />
                      </InputLabel>
                    </>
                  )}
                  {requestCategory === RequestCategory.ExistingProduct && (
                    <>
                      <InputLabel
                        label="Select a Product"
                        htmlFor="select-product"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Please choose the product that is relevant to your
                          request or feedback.
                        </SubText>
                        <Select
                          inputId="select-product"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={ProductOptions}
                          onChange={(selectedOption) => {
                            if (!selectedOption) {
                              reset();
                            }
                            setProduct(selectedOption?.value);
                          }}
                          value={ProductOptions.find(
                            (o) => o.value === product
                          )}
                        />
                      </InputLabel>
                      {product === Products.GPT && (
                        <InputLabel label="Chat Version" htmlFor="chat-version">
                          <SubText>Please select the Chat version.</SubText>
                          <Select
                            inputId="chat-version"
                            isClearable
                            isDisabled={false}
                            isSearchable
                            fluid
                            options={GPTChatVersionOptions}
                            onChange={(selectedOption) => {
                              if (!selectedOption) {
                                reset();
                              }
                              setGptChatVersion(selectedOption?.value);
                            }}
                            value={GPTChatVersionOptions.find(
                              (o) => o.value === gptChatVersion
                            )}
                          />
                        </InputLabel>
                      )}
                      <InputLabel
                        label="Context/Challenge"
                        htmlFor="context-challenge"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Describe the context or challenge that led to this
                          request. This could include any problems you're
                          facing, limitations of the current system, or
                          opportunities for improvement that you've identified.
                        </SubText>
                        <Textarea
                          id="context-challenge"
                          vSize="xl"
                          fullWidth
                          value={context}
                          onChange={(e) => setContext(e.target.value)}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Acceptance Criteria"
                        htmlFor="acceptance-criteria-existing"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Specify the conditions that must be met for your
                          request to be accepted. These could be functional
                          requirements, performance standards, or other criteria
                          that will determine whether the solution meets your
                          needs.
                        </SubText>
                        <Textarea
                          id="acceptance-criteria-existing"
                          vSize="lg"
                          fullWidth
                          value={acceptanceCriteriaExisting}
                          onChange={(e) =>
                            setAcceptanceCriteriaExisting(e.target.value)
                          }
                        />
                      </InputLabel>
                    </>
                  )}
                  {describeRequest === DescribeRequest.WorkflowAutomation && (
                    <>
                      <InputLabel
                        label="Request Type"
                        htmlFor="automation-request-type"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Please select the type of request you're submitting.
                        </SubText>
                        <Select
                          inputId="automation-request-type"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={AutomationRequestTypeOptions}
                          onChange={(selectedOption) => {
                            if (!selectedOption) {
                              reset();
                            }
                            setAutomationRequestType(selectedOption?.value);
                          }}
                          value={AutomationRequestTypeOptions.find(
                            (o) => o.value === automationRequestType
                          )}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Request Summary"
                        htmlFor="request-summary"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Provide a brief summary of your request. This should
                          include the main points of what you're asking for and
                          why it's important.
                        </SubText>
                        <Textarea
                          id="request-summary"
                          vSize="xl"
                          fullWidth
                          maxLength={500}
                          value={requestSummary}
                          onChange={(e) => setRequestSummary(e.target.value)}
                        />
                      </InputLabel>

                      <InputLabel
                        label="Work Impact"
                        htmlFor="automation-work-impact"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Select the level of impact this request will have on
                          your work or your team's work.
                        </SubText>
                        <Select
                          inputId="automation-work-impact"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={WorkImpactOptions}
                          onChange={(selectedOption) => {
                            if (!selectedOption) {
                              reset();
                            }
                            setWorkImpact(selectedOption?.value);
                          }}
                          value={WorkImpactOptions.find(
                            (o) => o.value === workImpact
                          )}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Priority within your Department"
                        htmlFor="automation-priority"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Please indicate how urgent this request is within your
                          department. This will help us prioritize our work and
                          respond to the most urgent needs first.
                        </SubText>
                        <Select
                          inputId="automation-priority"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={AutomationPriorityOptions}
                          onChange={(selectedOption) => {
                            if (!selectedOption) {
                              reset();
                            }
                            setAutomationPriority(selectedOption?.value);
                          }}
                          value={AutomationPriorityOptions.find(
                            (o) => o.value === automationPriority
                          )}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Key Stakeholders"
                        htmlFor="key-stakeholders"
                      >
                        <SubText>
                          List the people who will be most affected by this
                          request or who need to be involved in the
                          decision-making process. This could include team
                          members, managers, or other departments.
                        </SubText>
                        <Input
                          id="key-stakeholders"
                          fullWidth
                          value={keyStakeholders}
                          onChange={(e) => setKeyStakeholders(e.target.value)}
                        />
                      </InputLabel>
                    </>
                  )}
                  {describeRequest === DescribeRequest.BugReport && (
                    <Flex direction="column">
                      <InputLabel
                        label="Select a Product"
                        htmlFor="select-product"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Please choose the product that is relevant to your
                          request or feedback.
                        </SubText>
                        <Select
                          inputId="select-product"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={ProductOptions}
                          onChange={(selectedOption) => {
                            if (!selectedOption) {
                              reset();
                            }
                            setProduct(selectedOption?.value);
                          }}
                          value={ProductOptions.find(
                            (o) => o.value === product
                          )}
                        />
                      </InputLabel>
                      {product === Products.GPT && (
                        <InputLabel label="Chat Version" htmlFor="chat-version">
                          <SubText>Please select the Chat version.</SubText>
                          <Select
                            inputId="chat-version"
                            isClearable
                            isDisabled={false}
                            isSearchable
                            fluid
                            options={GPTChatVersionOptions}
                            onChange={(selectedOption) => {
                              if (!selectedOption) {
                                reset();
                              }
                              setGptChatVersion(selectedOption?.value);
                            }}
                            value={GPTChatVersionOptions.find(
                              (o) => o.value === gptChatVersion
                            )}
                          />
                        </InputLabel>
                      )}
                      <InputLabel
                        label="Bug Summary"
                        htmlFor="bug-summary"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Please include detailed summary and steps to reproduce
                          the bug.
                        </SubText>
                        <Textarea
                          id="bug-summary"
                          vSize="xl"
                          fullWidth
                          maxLength={500}
                          value={bugSummary}
                          onChange={(e) => setBugSummary(e.target.value)}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Acceptance Criteria"
                        htmlFor="acceptance-criteria-bug"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Specify the conditions that must be met for your
                          request to be accepted.
                        </SubText>
                        <Textarea
                          id="acceptance-criteria-bug"
                          vSize="lg"
                          fullWidth
                          value={acceptanceCriteriaBug}
                          onChange={(e) =>
                            setAcceptanceCriteriaBug(e.target.value)
                          }
                        />
                      </InputLabel>
                      <InputLabel
                        label="Bug Priority"
                        htmlFor="bug-priority"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Indicate the severity of the bug you're reporting.
                          This could range from a minor issue that doesn't
                          significantly affect functionality, to a major problem
                          that prevents the system from working properly. This
                          helps us to prioritize bug fixes.
                        </SubText>
                        <Select
                          inputId="bug-priority"
                          menuShouldScrollIntoView
                          isClearable
                          isDisabled={false}
                          fluid
                          maxMenuHeight={200}
                          isSearchable
                          size="lg"
                          options={BugPriorityOptions}
                          onChange={(selectedOption) => {
                            if (!selectedOption) {
                              reset();
                            }
                            setBugPriority(selectedOption?.value);
                          }}
                          value={BugPriorityOptions.find(
                            (o) => o.value === bugPriority
                          )}
                        />
                      </InputLabel>
                    </Flex>
                  )}

                  {!!describeRequest && (
                    <Flex direction="column">
                      <SubmitButton
                        variant="primary"
                        intent="info"
                        disabled={disabledSubmitButton}
                        onClick={onSubmit}
                      >
                        Submit Request
                      </SubmitButton>
                    </Flex>
                  )}
                </FormDetails>
              </TabContent>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && createJiraIssueRes.value && (
            <ThankYou
              textVariation="GE"
              ticketKey={createJiraIssueRes.value.key}
            />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
