import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";

export const usePut = <T, BodyDataType>(): [
  (url: string, data: BodyDataType) => Promise<T | null>,
  {
    value?: T;
    loading: boolean;
    called: boolean;
    error?: string;
  }
] => {
  const { authState } = useOktaAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [called, setCalled] = useState<boolean>(false);
  const [value, setValue] = useState<T>();
  const [error, setError] = useState<string>();
  const fetchCall = async (
    url: string,
    data: BodyDataType
  ): Promise<T | null> => {
    setCalled(true);
    setLoading(true);
    try {
      const res = await fetch(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          AUTHORIZATION: authState?.idToken?.idToken || "",
        },
        method: "PUT",
        body: JSON.stringify(data),
      });
      const result = await res.json();
      setValue(result);
      return result;
    } catch (e) {
      setError("API Error Occurred!");
      return null;
    } finally {
      setLoading(false);
    }
  };

  return [
    fetchCall,
    {
      value,
      loading,
      error,
      called,
    },
  ];
};
