import { API_BASE_URL } from "src/config";
import { CompanyResult } from "./types";
import { useFetch } from "./useFetch";

export const useGetAccountsWithType = (
  companyFetchDisabled: boolean,
  accountTypes: string[],
  companyPrefix?: string
) => {
  const params = companyPrefix
    ? {
        name: companyPrefix,
        accountTypes: accountTypes
          ? JSON.stringify(accountTypes)
          : JSON.stringify([]),
      }
    : undefined;

  return useFetch<CompanyResult[]>({
    url: `${API_BASE_URL}/api/company/accounts`,
    lazyload: companyFetchDisabled,
    ...(companyPrefix && {
      ...params,
    }),
  });
};
