import { useApi } from "./useApi/useApi";

type IndustryResult = {
  INDUSTRY: string;
};

export const useIndustries = (enabled: boolean) =>
  useApi<IndustryResult[]>(["industries"], "company/industries", {
    enabled: enabled,
  });
