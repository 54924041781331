import { dropDownSort } from "src/utils";

export enum ProductArea {
  CoreObjects = "Core Objects",
  Reporting = "Reporting",
  EmailCompo = "Email Composition",
  EmailInfra = "Email Infrastructure",
  SMS = "SMS",
  MA = "Messaging & Automation",
  CoreMessaging = "Core Messaging",
  Channels = "Channels",
  SDK = "SDKs",
  Partnerships = "Partnerships",
  IAM = "IAM",
  CustomerLife = "CustomerLifecycle",
  CLIP = "Identity & Permissions(CLIP)",
  Intelligence = "Intelligence",
  APPI = "Application Infrastructure",
  PLATI = "Platform Infrastructure (PLATI)",
  QI = "Quality Infrastructure (QI)",
  MDEP = "Monolith Deployments(MDEP)",
  GE = "Growth Engineering",
  FIE = "Frontend Infrastructure & Experience",
  Currents = "Currents",
  DataLake = "Data Lake",
}

export const ProductAreaOptions = dropDownSort([
  {
    value: ProductArea.APPI,
    label: ProductArea.APPI,
  },
  {
    value: ProductArea.CLIP,
    label: ProductArea.CLIP,
  },
  {
    value: ProductArea.Channels,
    label: ProductArea.Channels,
  },
  {
    value: ProductArea.CoreMessaging,
    label: ProductArea.CoreMessaging,
  },
  {
    value: ProductArea.CoreObjects,
    label: ProductArea.CoreObjects,
  },
  {
    value: ProductArea.Currents,
    label: ProductArea.Currents,
  },
  {
    value: ProductArea.CustomerLife,
    label: ProductArea.CustomerLife,
  },
  {
    value: ProductArea.DataLake,
    label: ProductArea.DataLake,
  },
  {
    value: ProductArea.EmailCompo,
    label: ProductArea.EmailCompo,
  },
  {
    value: ProductArea.EmailInfra,
    label: ProductArea.EmailInfra,
  },
  {
    value: ProductArea.FIE,
    label: ProductArea.FIE,
  },
  {
    value: ProductArea.GE,
    label: ProductArea.GE,
  },
  {
    value: ProductArea.IAM,
    label: ProductArea.IAM,
  },
  {
    value: ProductArea.Intelligence,
    label: ProductArea.Intelligence,
  },
  {
    value: ProductArea.MA,
    label: ProductArea.MA,
  },
  {
    value: ProductArea.MDEP,
    label: ProductArea.MDEP,
  },
  {
    value: ProductArea.PLATI,
    label: ProductArea.PLATI,
  },
  {
    value: ProductArea.Partnerships,
    label: ProductArea.Partnerships,
  },

  {
    value: ProductArea.QI,
    label: ProductArea.QI,
  },

  {
    value: ProductArea.Reporting,
    label: ProductArea.Reporting,
  },
  {
    value: ProductArea.SDK,
    label: ProductArea.SDK,
  },
  {
    value: ProductArea.SMS,
    label: ProductArea.SMS,
  },
]);

export enum ProductDivision {
  Base = "Base",
  PC = "P&C",
  Spied = "SPIED",
  DataProducts = "Data Products",
  Design = "Design",
  UXR = "UXR",
}

export const ProductDivisionOptions = [
  {
    value: ProductDivision.Base,
    label: ProductDivision.Base,
  },
  {
    value: ProductDivision.DataProducts,
    label: ProductDivision.DataProducts,
  },
  {
    value: ProductDivision.Design,
    label: ProductDivision.Design,
  },
  {
    value: ProductDivision.PC,
    label: ProductDivision.PC,
  },
  {
    value: ProductDivision.Spied,
    label: ProductDivision.Spied,
  },
  {
    value: ProductDivision.UXR,
    label: ProductDivision.UXR,
  },
];

export enum ProductPriority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export const ProductPriorityOptions = [
  {
    value: ProductPriority.Low,
    label: ProductPriority.Low,
  },
  {
    value: ProductPriority.Medium,
    label: ProductPriority.Medium,
  },
  {
    value: ProductPriority.High,
    label: ProductPriority.High,
  },
];
