export enum DescribeRequest {
  ProductRequestFeedback = "Product Requests/Feedback",
  WorkflowAutomation = "Workflow Automation",
  BugReport = "Bug Report",
}

export enum RequestCategory {
  NewProduct = "New Product Request",
  ExistingProduct = "Existing Product Request",
}

export enum Products {
  Doppelgänger = "Doppelgänger",
  GPT = "GPT at Braze",
  Growlithe = "Growlithe",
  Docs = "Docs",
  Masquerade = "Masquerade",
  Continuum = "Continuum Scorecard",
  Salt = "Solcon Asset Lister",
  Pilot = "Braze Pilot",
  Slidebot = "Slidebot",
  Tray = "Tray.io",
  Other = "Other",
}

export enum GPTChatVersion {
  GeneralChat = "General Chat",
  Knowledge = "Knowledge",
  CompetitiveInstance = "Competitive Instance",
}

export enum AutomationRequestType {
  Alerting = "Alerting Request",
  DataIntegration = "Data Integration",
  Slidebot = "Slidebot",
  Other = "Other",
}

export enum WorkImpact {
  MyWork = "This Only Impacts My Work",
  MyTeam = "This Impacts My Team",
  MultipleTeams = "This Impacts Multiple Teams",
}

export enum AutomationPriority {
  P0 = "P0 - Needed to solve significantly hampered business operations",
  P1 = "P1 - Automation will make a causal impact to department efficiency and aligns with department OKRs",
  P2 = "P2 - Nice-to-have solution to reduce manual work",
}

export enum BugPriority {
  P0 = "P0 - Drop everything, client-facing product/automation is breaking or causing negative consequences",
  P1 = "P1 - Important internal-facing product/automation is breaking or causing negative consequences",
  P2 = "P2 - Client-facing product/automation is working, but issues exist",
  P3 = "P3 - Internal-facing product/automation is working, but issues exist",
}

export type TestProp = {
  testForm?: string;
};

export const DescribeRequestOptions = [
  {
    value: DescribeRequest.ProductRequestFeedback,
    label: DescribeRequest.ProductRequestFeedback,
  },
  {
    value: DescribeRequest.WorkflowAutomation,
    label: DescribeRequest.WorkflowAutomation,
  },
  {
    value: DescribeRequest.BugReport,
    label: DescribeRequest.BugReport,
  },
];

export const RequestCategoryOptions = [
  { value: RequestCategory.NewProduct, label: RequestCategory.NewProduct },
  {
    value: RequestCategory.ExistingProduct,
    label: RequestCategory.ExistingProduct,
  },
];

export const ProductOptions = [
  { value: Products.Continuum, label: Products.Continuum },
  { value: Products.Doppelgänger, label: Products.Doppelgänger },
  { value: Products.Docs, label: Products.Docs },
  { value: Products.GPT, label: Products.GPT },
  { value: Products.Growlithe, label: Products.Growlithe },
  { value: Products.Masquerade, label: Products.Masquerade },
  { value: Products.Pilot, label: Products.Pilot },
  { value: Products.Salt, label: Products.Salt },
  { value: Products.Slidebot, label: Products.Slidebot },
  { value: Products.Tray, label: Products.Tray },
  { value: Products.Other, label: Products.Other },
];

export const GPTChatVersionOptions = [
  {
    value: GPTChatVersion.CompetitiveInstance,
    label: GPTChatVersion.CompetitiveInstance,
  },
  { value: GPTChatVersion.GeneralChat, label: GPTChatVersion.GeneralChat },
  { value: GPTChatVersion.Knowledge, label: GPTChatVersion.Knowledge },
];

export const AutomationRequestTypeOptions = [
  {
    value: AutomationRequestType.Alerting,
    label: AutomationRequestType.Alerting,
  },
  {
    value: AutomationRequestType.DataIntegration,
    label: AutomationRequestType.DataIntegration,
  },
  {
    value: AutomationRequestType.Slidebot,
    label: AutomationRequestType.Slidebot,
  },
  { value: AutomationRequestType.Other, label: AutomationRequestType.Other },
];

export const AutomationPriorityOptions = [
  { value: AutomationPriority.P0, label: AutomationPriority.P0, jiraId: "1" },
  { value: AutomationPriority.P1, label: AutomationPriority.P1, jiraId: "2" },
  { value: AutomationPriority.P2, label: AutomationPriority.P2, jiraId: "3" },
];

export const BugPriorityOptions = [
  { value: BugPriority.P0, label: BugPriority.P0, jiraId: "1" },
  { value: BugPriority.P1, label: BugPriority.P1, jiraId: "2" },
  { value: BugPriority.P2, label: BugPriority.P2, jiraId: "3" },
  { value: BugPriority.P3, label: BugPriority.P3, jiraId: "4" },
];

export const WorkImpactOptions = [
  { value: WorkImpact.MyWork, label: WorkImpact.MyWork },
  { value: WorkImpact.MyTeam, label: WorkImpact.MyTeam },
  { value: WorkImpact.MultipleTeams, label: WorkImpact.MultipleTeams },
];
