import styled from 'styled-components';
import {Box, Button, FieldLabel, Heading, ModalFooter, TableCell} from "@braze/beacon-core";

export const CustomAttributesContainer = styled(Box)`
  flex-direction: column;
`;

export const CustomAttributesHeading = styled(Heading)`
  margin-bottom: 25px;
`;

export const CustomAttributesEmptyTextContainer = styled(Box)`
  flex-direction: column;
  margin-bottom: 25px;
`;

export const CustomAttributesButton = styled(Button)`
  margin-top: 25px;
`;

export const CustomAttributesTableActionsTableCell = styled(TableCell)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const CustomAttributesTableActionsContainer = styled(Box)`
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 1px;
`;

// There is some weird gap, I don't know why it is happening but having this -10px matches the bottom field label.
export const CustomAttributesModalTopFieldLabel = styled(FieldLabel)`
  margin-top: 30px;
  margin-bottom: -10px;
`;

export const CustomAttributesModalFieldLabel = styled(FieldLabel)`
  margin-top: 30px;
  margin-bottom: 5px;
`;

export const CustomAttributesModalFooter = styled(ModalFooter)`
  padding-top: 10px;
`;
