import { Button, Flex, Label, PanelBody } from "@braze/beacon-core";
import { useNavigate } from "react-router-dom";

type Props = {
  ticketKey?: string;
  textVariation?: "GE" | "Slidebot";
};

export const ThankYou = ({ ticketKey, textVariation }: Props) => {
  const history = useNavigate();
  const homePage = () => history("/");

  if ((textVariation && textVariation === "GE") && !ticketKey) {
    return null;
  }
  return (
    <>
      <PanelBody
        style={{
          border: "none",
          boxShadow: "0px 4px 10px rgba(69, 84, 94, 0.1)",
          padding: "2rem 4rem",
          borderTop: "1px solid #8080802b",
          maxWidth: "600px",
        }}
      >
        <Flex
          direction="column"
          alignItems="center"
          style={{ margin: "1rem 0" }}
        >
          <Label
            style={{
              fontSize: "24px",
              color: "#0DAFC5",
              marginBottom: "1.5rem",
            }}
          >
            Thank you for submitting your request.
          </Label>
          {textVariation === "GE" && (
            <Flex
              direction="column"
              alignItems="center"
              style={{ margin: "1rem 0" }}
            >
              <Label>
                Growth will review your request and complete the following:
              </Label>

              <Label>
                <ol>
                  <li>
                    Initial Communication - Within 5 business days of receiving
                    the request, the relevant Growth team will follow up with
                    any clarifying questions over Jira.
                  </li>

                  <li>
                    Commitment Decision - Within 10 business days of receiving
                    the request, the relevant Growth team will either commit to
                    or reject the request based on feasibility, resources and
                    impact.
                  </li>
                </ol>
              </Label>
              <Label>
                Bug Request Response Times:
                <ul>
                  <li>P0 - Same Day</li>
                  <li>P1 - Same Day</li>
                  <li>P2 - 5 business days</li>
                  <li>P3 - 7 business days</li>
                </ul>
              </Label>
            </Flex>
          )}
          {textVariation === "Slidebot" && (
            <Flex
              direction="column"
              alignItems="center"
              style={{ margin: "1rem 0" }}
            >
              You should receive a slack notification regarding your slides!
            </Flex>
          )}
          {textVariation !== "Slidebot" && (
            <Label>
              <a
                style={{ color: "#008294" }}
                target="blank"
                href={`https://jira.braze.com/browse/${ticketKey}`}
              >
                Click here to review your request.
              </a>
            </Label>
          )}
          <img
            src="../../../public/img/torchie.svg"
            alt="torchie-icon"
            style={{ height: "225px", width: "114px" }}
          />
          <Button
            variant="primary"
            intent="info"
            style={{
              marginTop: "1rem",
              width: "300px",
              justifyContent: "center",
            }}
            onClick={homePage}
          >
            Start a New Request
          </Button>
        </Flex>
      </PanelBody>
    </>
  );
};
