import { dropDownSort } from "src/utils";

export enum CompanyCategory {
  SMB = "SMB",
  Scale = "Scale",
  Enterprise = "Enterprise",
  GSA = "GSA",
}

export const CompanyCategoryOptions = [
  {
    value: CompanyCategory.SMB,
    label: CompanyCategory.SMB,
  },
  {
    value: CompanyCategory.Scale,
    label: CompanyCategory.Scale,
  },
  {
    value: CompanyCategory.Enterprise,
    label: CompanyCategory.Enterprise,
  },
  {
    value: CompanyCategory.GSA,
    label: CompanyCategory.GSA,
  },
];

export enum MAU {
  One = ">1mm",
  Two = "1mm-2mm",
  Five = "2mm-5mm",
  Ten = "5mm-10mm",
  Twenty = "10mm-25mm",
  TwentyFive = "25mm+",
}

export const MAUCategoryOptions = [
  {
    value: MAU.One,
    label: MAU.One,
  },
  {
    value: MAU.Two,
    label: MAU.Two,
  },
  {
    value: MAU.Five,
    label: MAU.Five,
  },
  {
    value: MAU.Ten,
    label: MAU.Ten,
  },
  {
    value: MAU.Twenty,
    label: MAU.Twenty,
  },
  {
    value: MAU.TwentyFive,
    label: MAU.TwentyFive,
  },
];

export enum RequestCategory {
  Analytics = "Analytics",
  DataEnhancements = "Data Enhancements",
  DataExport = "Data Export",
  DataStrategy = "Data Strategy",
  Innovations = "Innovations",
}

export const RequestCategoryOptions = [
  {
    value: RequestCategory.Analytics,
    label: RequestCategory.Analytics,
  },
  {
    value: RequestCategory.DataEnhancements,
    label: RequestCategory.DataEnhancements,
  },
  {
    value: RequestCategory.DataExport,
    label: RequestCategory.DataExport,
  },
  {
    value: RequestCategory.DataStrategy,
    label: RequestCategory.DataStrategy,
  },
  {
    value: RequestCategory.Innovations,
    label: RequestCategory.Innovations,
  },
];

export enum DataExportCapability {
  Currents = "Currents",
  DataSharing = "Data Sharing",
  ReaderAccount = "Reader Account",
  API = "API",
}

export const DataExportCapabilityOption = [
  {
    value: DataExportCapability.Currents,
    label: DataExportCapability.Currents,
  },
  {
    value: DataExportCapability.DataSharing,
    label: DataExportCapability.DataSharing,
  },
  {
    value: DataExportCapability.ReaderAccount,
    label: DataExportCapability.ReaderAccount,
  },
  {
    value: DataExportCapability.API,
    label: DataExportCapability.API,
  },
];

export enum DataEnhancement {
  CampaignCanvas = "Campaign/Canvas Details",
  UserDetails = "User Details",
  SegmentDetails = "Segment Details",
  Other = "Other",
}

export const DataEnhancementOptions = [
  {
    value: DataEnhancement.CampaignCanvas,
    label: DataEnhancement.CampaignCanvas,
  },
  {
    value: DataEnhancement.UserDetails,
    label: DataEnhancement.UserDetails,
  },
  {
    value: DataEnhancement.SegmentDetails,
    label: DataEnhancement.SegmentDetails,
  },
  {
    value: DataEnhancement.Other,
    label: DataEnhancement.Other,
  },
];

export enum AnalyticsCapability {
  BasicBrazeMetrics = "Basic Braze Metrics",
  Retention = "Retention",
  Attribution = "Attribution",
  MarketingFrequency = "Marketing Frequency",
  RFM = "RFM",
  Other = "Other",
}

export const AnalyticsCapabilityOptions = [
  {
    value: AnalyticsCapability.BasicBrazeMetrics,
    label: AnalyticsCapability.BasicBrazeMetrics,
  },
  {
    value: AnalyticsCapability.Retention,
    label: AnalyticsCapability.Retention,
  },
  {
    value: AnalyticsCapability.Attribution,
    label: AnalyticsCapability.Attribution,
  },
  {
    value: AnalyticsCapability.MarketingFrequency,
    label: AnalyticsCapability.MarketingFrequency,
  },
  {
    value: AnalyticsCapability.RFM,
    label: AnalyticsCapability.RFM,
  },
  {
    value: AnalyticsCapability.Other,
    label: AnalyticsCapability.Other,
  },
];

export enum Need {
  UpcomingRenewal = "Upcoming Renewal",
  CustomerSentiment = "Customer Sentiment",
  EBR = "EBR",
  Other = "Other",
}

export const NeedOptions = [
  {
    value: Need.CustomerSentiment,
    label: Need.CustomerSentiment,
  },
  {
    value: Need.EBR,
    label: Need.EBR,
  },
  {
    value: Need.UpcomingRenewal,
    label: Need.UpcomingRenewal,
  },
  {
    value: Need.Other,
    label: Need.Other,
  },
];

export enum Team {
  Succes = "Succes",
  Sales = "Sales",
  StrategicConsulting = "Strategic Consulting",
  Product = "Product",
  Other = "Other",
}

export const TeamOptions = [
  {
    value: Team.Product,
    label: Team.Product,
  },
  {
    value: Team.Sales,
    label: Team.Sales,
  },
  {
    value: Team.StrategicConsulting,
    label: Team.StrategicConsulting,
  },
  {
    value: Team.Succes,
    label: Team.Succes,
  },
  {
    value: Team.Other,
    label: Team.Other,
  },
];

export enum Urgency {
  Week = "Within 7 Days",
  TwoWeeks = "Within 14 Days",
  Month = "Within 30 Days",
  Quarter = "Within the Quarter",
}

export const UrgencyOptions = [
  {
    value: Urgency.Week,
    label: Urgency.Week,
  },
  {
    value: Urgency.TwoWeeks,
    label: Urgency.TwoWeeks,
  },
  {
    value: Urgency.Month,
    label: Urgency.Month,
  },
  {
    value: Urgency.Quarter,
    label: Urgency.Quarter,
  },
];

export enum GsaCustomer {
  Disney = "Disney",
  WarnerMedia = "WarnerMedia",
  Viacom = "Viacom",
  Comcast = "Comcast/NBC",
  Fox = "Fox",
  Sony = "Sony",
  Walmart = "Walmart",
  CVS = "CVS",
  Sephora = "Sephora/LVMH",
  EsteeLauder = "Estee Lauder",
  Procter = "Procter & Gamble",
  AB = "AB InBev/BEES",
  Yum = "Yum!",
  RBI = "RBI",
  McDonald = "McDonald's",
  Paypal = "Paypal/Venmo",
  Intuit = "Intuit",
  Samsung = "Samsung",
  Amazon = "Amazon",
  ActivisionBlizzard = "Activision/Blizzard",
  GSAProspect = "GSA Prospect",
}

export const GsaCustomerOptions = dropDownSort([
  {
    value: GsaCustomer.AB,
    label: GsaCustomer.AB,
  },
  {
    value: GsaCustomer.Amazon,
    label: GsaCustomer.Amazon,
  },
  {
    value: GsaCustomer.CVS,
    label: GsaCustomer.CVS,
  },
  {
    value: GsaCustomer.Comcast,
    label: GsaCustomer.Comcast,
  },
  {
    value: GsaCustomer.Disney,
    label: GsaCustomer.Disney,
  },
  {
    value: GsaCustomer.EsteeLauder,
    label: GsaCustomer.EsteeLauder,
  },
  {
    value: GsaCustomer.Fox,
    label: GsaCustomer.Fox,
  },
  {
    value: GsaCustomer.Intuit,
    label: GsaCustomer.Intuit,
  },
  {
    value: GsaCustomer.McDonald,
    label: GsaCustomer.McDonald,
  },
  {
    value: GsaCustomer.Paypal,
    label: GsaCustomer.Paypal,
  },
  {
    value: GsaCustomer.Procter,
    label: GsaCustomer.Procter,
  },
  {
    value: GsaCustomer.RBI,
    label: GsaCustomer.RBI,
  },
  {
    value: GsaCustomer.Samsung,
    label: GsaCustomer.Samsung,
  },
  {
    value: GsaCustomer.Sephora,
    label: GsaCustomer.Sephora,
  },
  {
    value: GsaCustomer.Sony,
    label: GsaCustomer.Sony,
  },
  {
    value: GsaCustomer.Viacom,
    label: GsaCustomer.Viacom,
  },
  {
    value: GsaCustomer.Walmart,
    label: GsaCustomer.Walmart,
  },
  {
    value: GsaCustomer.WarnerMedia,
    label: GsaCustomer.WarnerMedia,
  },
  {
    value: GsaCustomer.Yum,
    label: GsaCustomer.Yum,
  },
  {
    value: GsaCustomer.ActivisionBlizzard,
    label: GsaCustomer.ActivisionBlizzard,
  },
  {
    value: GsaCustomer.GSAProspect,
    label: GsaCustomer.GSAProspect,
  },
]);
