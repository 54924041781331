/* eslint-disable camelcase */
import { Select } from "@braze/beacon-lab-select";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import {
  FormHeading,
  FormPanel,
  FormPanelBody,
  RequestForm,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import { useState } from "react";
import { requiredLabel } from "src/components/required";
import {
  ClassificationOptions,
  CompetitorOptions,
  DifferentiatorOptions,
  DriverOptions,
  EsgLink,
  EsgValue,
  FileNamePostfix,
  IndustryOptions,
  ProductsOptions,
  RegionOptions,
  SlidebotPitchDeckFormDefaults,
  Templates,
} from "./constants";
import { useForm } from "src/hooks/useForm";
import { FormField, MultiSelectOptions } from "./types";
import { AccountResult, CompanyResult } from "src/hooks/types";
import { Checkbox, FieldLabel, Flex, StatusText } from "@braze/beacon-core";
import { OpportunitySearchSelect } from "src/components/OpportunitySearchSelect";
import { getUserInformation } from "src/utils";
import { CheckBoxFieldLabel, SelectedInputStyle } from "./styles";

import {
  PitchDeckSlideBotRequest,
  useGenerateSlidebot,
} from "src/hooks/useGenerateSlidebot";
import { ThankYou } from "./../thank-you";
import { CompanySearchSelect } from "src/components/CompanySearchSelect";

export const SlidebotPitchDeckForm = () => {
  const { formState, setFormFieldValue } = useForm<FormField>(
    SlidebotPitchDeckFormDefaults
  );
  const [pitchdeckSlidebot, pitchdeckSlidebotResponse] =
    useGenerateSlidebot<PitchDeckSlideBotRequest>("pitchdeck");
  const { opportunity, account, template } = formState;
  const userInfo = getUserInformation();

  const submitDisabled = !account || !opportunity || !template;

  const [esg, setEsg] = useState<boolean>(false);

  const mapFormState = (property: keyof FormField) => {
    const propValue = formState?.[property as keyof FormField];
    return Array.isArray(propValue) ? propValue.map((item) => item.value) : [];
  };

  const onSubmit = async () => {
    const pitchdeckTemplate = template!.value;
    const fileNamePostfix =
      FileNamePostfix[pitchdeckTemplate as keyof typeof FileNamePostfix];
    const accountId = account!.ACCOUNT_ID;
    const opportunityId = opportunity!.value;
    const industries = mapFormState("industry");
    const drivers = mapFormState("driver");
    const differentiators = mapFormState("differentiator");
    const products = mapFormState("products");
    const regions = mapFormState("region");
    const classifications = mapFormState("classification");
    const competitors = mapFormState("competitor");

    if (esg) {
      differentiators.push(EsgValue);
    }

    await pitchdeckSlidebot({
      submitter_email: userInfo!.email!,
      submitter_name: userInfo!.name!,
      template_name: pitchdeckTemplate,
      opportunity_id: opportunityId,
      account_name: account!.ACCOUNT_NAME,
      account_id: accountId,
      file_name: `${account?.ACCOUNT_NAME} - ${fileNamePostfix} `,
      config_option: {
        industry: industries,
        driver: drivers,
        differentiator: differentiators,
        product: products,
        region: regions,
        micro_classification: classifications,
        competitor: competitors,
        slack_notify_user_email: userInfo?.email,
      },
    });
  };

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "80px" }}>
        <FormPanel>
          {pitchdeckSlidebotResponse.called &&
            pitchdeckSlidebotResponse.value ? (
            <>
              <ThankYou textVariation="Slidebot" />
            </>
          ) : (
            <FormPanelBody>
              <>
                <FormHeading>Pitch Deck Request Form</FormHeading>
                <SelectedInputStyle
                  label="Language"
                  htmlFor="template"
                  formatLabel={requiredLabel}
                >
                  <Select
                    defaultValue={Templates[0]}
                    inputId="template"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={Templates}
                    onChange={(template) => {
                      setFormFieldValue("template", template);
                    }}
                    placeholder="Select a Template..."
                  />
                </SelectedInputStyle>
                <SelectedInputStyle>
                  <Flex>
                    <Checkbox
                      checked={esg}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(e: any) => {
                        setEsg(e?.target?.checked);
                      }}
                      id="esg"
                      indeterminate={false}
                      disabled={false}
                    />
                    <CheckBoxFieldLabel htmlFor="esg">
                      Include Social Impact and Environmental, Social, Governance (ESG) slide and <a href={`${EsgLink}`} target={`_${EsgValue}`}>ESG Report</a>
                    </CheckBoxFieldLabel>
                  </Flex>
                </SelectedInputStyle>
                <SelectedInputStyle
                  label="Account"
                  htmlFor="slidebot-account"
                  formatLabel={requiredLabel}
                >
                  <CompanySearchSelect
                    onSelectCompany={(account?: CompanyResult) => {
                      setFormFieldValue("account", account);
                    }}
                  />
                </SelectedInputStyle>
                <FieldLabel label="Opportunity" formatLabel={requiredLabel}>
                  <SubText>
                    Select the Opportunity associated with the account.
                  </SubText>
                  <OpportunitySearchSelect
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onSelectOpportunity={(opportunities: any) => {
                      setFormFieldValue("opportunity", opportunities[0]);
                    }}
                    includeStageName
                    isMulti={false}
                    returnFullOption
                    selectedAccount={
                      account
                        ? ({
                          ACCOUNT_ID: account?.ACCOUNT_ID,
                        } as AccountResult)
                        : undefined
                    }
                    noOpportunityOption
                  />
                </FieldLabel>
                <SelectedInputStyle label="Industry" htmlFor="industry">
                  <Select
                    inputId="industry"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    isMulti
                    options={IndustryOptions}
                    onChange={(industry: MultiSelectOptions) => {
                      setFormFieldValue("industry", industry);
                    }}
                    placeholder="Select a Industry template..."
                  />
                </SelectedInputStyle>
                <SelectedInputStyle
                  label="Classification"
                  htmlFor="classification"
                >
                  <Select
                    inputId="classification"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    isMulti
                    options={ClassificationOptions}
                    onChange={(classification: MultiSelectOptions) => {
                      setFormFieldValue("classification", classification);
                    }}
                    placeholder="Select a Region..."
                  />
                </SelectedInputStyle>
                <SelectedInputStyle label="Region" htmlFor="region">
                  <Select
                    inputId="region"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    isMulti
                    options={RegionOptions}
                    onChange={(region: MultiSelectOptions) => {
                      setFormFieldValue("region", region);
                    }}
                    placeholder="Select a Region..."
                  />
                </SelectedInputStyle>
                <SelectedInputStyle label="Products" htmlFor="products">
                  <Select
                    inputId="products"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    isMulti
                    options={ProductsOptions}
                    onChange={(products: MultiSelectOptions) => {
                      setFormFieldValue("products", products);
                    }}
                    placeholder="Select a Product..."
                  />
                </SelectedInputStyle>
                <SelectedInputStyle label="Driver" htmlFor="driver">
                  <Select
                    inputId="driver"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    isMulti
                    options={DriverOptions}
                    onChange={(driver: MultiSelectOptions) => {
                      setFormFieldValue("driver", driver);
                    }}
                    placeholder="Select a Driver..."
                  />
                </SelectedInputStyle>
                <SelectedInputStyle
                  label="Differentiator"
                  htmlFor="differentiator"
                >
                  <Select
                    inputId="differentiator"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    isMulti
                    options={DifferentiatorOptions}
                    onChange={(differentiator: MultiSelectOptions) => {
                      setFormFieldValue("differentiator", differentiator);
                    }}
                    placeholder="Select a Differentiator..."
                  />
                </SelectedInputStyle>
                <SelectedInputStyle label="Competitor" htmlFor="competitor">
                  <Select
                    inputId="competitor"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    isMulti
                    options={CompetitorOptions}
                    onChange={(competitor: MultiSelectOptions) => {
                      setFormFieldValue("competitor", competitor);
                    }}
                    placeholder="Select a Competitor template..."
                  />
                </SelectedInputStyle>

                <SubmitButton
                  variant="primary"
                  intent="info"
                  disabled={submitDisabled}
                  onClick={onSubmit}
                >
                  Submit Request
                </SubmitButton>
                {pitchdeckSlidebotResponse.called &&
                  pitchdeckSlidebotResponse.error ? (
                  <StatusText displayIcon status="danger">
                    {" "}
                    Failed to submit request. Please try again.
                  </StatusText>
                ) : null}
              </>
            </FormPanelBody>
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
