/* eslint-disable camelcase */
import { useEffect, useState } from "react";

import { Input } from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";


import {
  FormHeading,
  FormPanel,
  FormPanelBody,
  InputLabel,
  RequestForm,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { requiredLabel } from "src/components/required";
import { AccountResult, AppGroupResult } from "src/hooks/types";
import { JiraIssueRequest } from "src/hooks/useCreateIssue";
import { SlidebotRequest, useGenerateSlidebot } from "src/hooks/useGenerateSlidebot";
import { getUserInformation } from "src/utils";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { ThankYou } from "src/pages/thank-you";
import { DurationOptions, RegionOptions, SlidebotTemplateOptions, SlidebotTemplates } from "./constants";
import { AccountSearchSelect } from "src/components/AccountSearchSelect";
import { AppGroupSearchSelect } from "src/components/AppGroupSearchSelect";

export default function SlidebotForm() {
  const userInfo = getUserInformation();
  const [template, setTemplate] = useState<string>(SlidebotTemplates.EBR);
  const [selectedAccount, setSelectedAccount] = useState<AccountResult>();
  const [appGroup, setAppGroup] = useState<string[]>([]);
  const [industry, setIndustry] = useState<string>("");
  const [region, setRegion] = useState<string>("All");
  const [duration, setDuration] = useState<string>("");
  const [trendDuration, setTrendDuration] = useState<string>("");
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  // const [createJiraIssue, createJiraIssueRes] = useCreateIssue();
  const [generateSlidebot, generateSlidebotResponse] = useGenerateSlidebot<SlidebotRequest>('generate');

  useEffect(() => {
    const requiredFieldsFilled =
      !!template &&
      !!selectedAccount &&
      !!industry &&
      !!region &&
      !!duration &&
      !!trendDuration;

    setDisableSubmit(!requiredFieldsFilled);
  }, [
    template,
    selectedAccount,
    appGroup,
    industry,
    region,
    duration,
    trendDuration,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const slidebotIndustryChange = (e: any) => {
    setIndustry(e.target.value);
  };

  const onSelectAccount = (account?: AccountResult) => {
    setSelectedAccount(account);
    setAppGroup([]);
    setIndustry(!!account && !!account.INDUSTRY ? account.INDUSTRY : "");
  };

  const onAppGroupSelect = (appGroup: AppGroupResult[]) => {
    const appGroupNames = appGroup.map((app) => app.APP_GROUP_NAME);
    setAppGroup(appGroupNames);
  };

  const onSubmit = async () => {
    const slidebotTitle = `[Slide Request] ${selectedAccount?.ACCOUNT_NAME} ${appGroup.length > 0 ? "(" + appGroup[0] + ") " : ""
      }- Trend: ${trendDuration} | Duration: ${duration} ${region ? "| Region: " + region : ""
      }`;

    const issueData = `*Reporter*: ${userInfo?.email}
        *Request Type*: Workflow Automation
        *Title*: ${slidebotTitle}
        *Template*: ${template}
        *Company Name*: ${selectedAccount?.ACCOUNT_NAME}${appGroup.length > 0 ? `\n*App group*: ${appGroup?.[0] ?? ""}` : ""
      }
        *Industry*: ${industry}
        *Industry Region*: ${region}
        *Duration*: ${duration}
        *Trend Duration*: ${trendDuration}`;

    const jiraConfig = getJiraConfig("Slidebot", {});
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config.!");
      return; // Required
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const jiraIssueRequest: JiraIssueRequest = {
      fields: {
        project: {
          key: jiraConfig.jiraProjectKey,
        },
        description: issueData,
        summary: slidebotTitle,
        issuetype: {
          name: "Task",
        },
        customfield_10000: jiraConfig.epicLink,
        labels: jiraConfig.labels,
      },
    };

    // const jiraResponse = await createJiraIssue(jiraIssueRequest);

    try {
      await generateSlidebot({
        submitter_email: userInfo?.email,
        submitter_name: userInfo?.name,
        template_name: template,
        company_name: selectedAccount?.ACCOUNT_NAME,
        file_name: `${selectedAccount?.ACCOUNT_NAME} ${appGroup?.[0] ? "- " + appGroup?.[0] : ""
          } - Duration: ${duration} - Trend: ${trendDuration} `,
        config_option: {
          app_group: appGroup[0],
          ebr_duration: duration,
          trend_duration: trendDuration,
          industry: industry,
          ebr_duration_ago: duration,
          trend_duration_ago: trendDuration,
          slack_notify_user_email: userInfo?.email,
          // jira_ticket_id: jiraResponse.key,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "80px" }}>
        <FormPanel>
          {generateSlidebotResponse.called && generateSlidebotResponse.value ? (
            <>
              <ThankYou textVariation="Slidebot" />
            </>
          ) : (
            <>
              <FormPanelBody>
                <FormHeading level={2} style={{ margin: "12px 0 26px" }}>
                  Slide Generation Request
                </FormHeading>
                <InputLabel
                  label="Template"
                  htmlFor="slidebot-template"
                  formatLabel={requiredLabel}
                  style={{ marginTop: "26px" }}
                >
                  <Select
                    inputId="slidebot-template"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={SlidebotTemplateOptions}
                    onChange={(e) => setTemplate(e?.value ?? "")}
                    defaultValue={SlidebotTemplateOptions[0]}
                    placeholder="Select a Slidebot template..."
                  />
                </InputLabel>
                <InputLabel
                  label="Company"
                  htmlFor="slidebot-company"
                  formatLabel={requiredLabel}
                  style={{ marginTop: "1.6rem" }}
                >
                  <AccountSearchSelect onSelectAccount={onSelectAccount} />
                </InputLabel>
                <InputLabel
                  label="App Group (Optional)"
                  htmlFor="slidebot-appgroup"
                  style={{ marginTop: "26px" }}
                >
                  <AppGroupSearchSelect
                    onAppGroupSelect={onAppGroupSelect}
                    selectedAccount={selectedAccount}
                    isMulti={false}
                  />
                </InputLabel>
                <InputLabel
                  label="Industry"
                  htmlFor="slidebot-industry"
                  formatLabel={requiredLabel}
                  style={{ marginTop: "26px" }}
                >
                  <Input
                    id="slidebot-industry"
                    fullWidth
                    value={industry}
                    onChange={slidebotIndustryChange}
                    placeholder="Enter an industry..."
                  />
                </InputLabel>
                <InputLabel
                  label="Industry Region"
                  htmlFor="slidebot-industry-region"
                  formatLabel={requiredLabel}
                  style={{ marginTop: "26px" }}
                >
                  <Select
                    inputId="slidebot-industry-region"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={RegionOptions}
                    onChange={(e) => setRegion(e?.value ?? "")}
                    defaultValue={RegionOptions[0]}
                    placeholder="Select a region..."
                  />
                </InputLabel>
                <InputLabel
                  label="Duration"
                  htmlFor="slidebot-duration"
                  formatLabel={requiredLabel}
                  style={{ marginTop: "26px" }}
                >
                  <Select
                    inputId="slidebot-duration"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={DurationOptions}
                    onChange={(e) => setDuration(e?.value ?? "")}
                    placeholder="Select a duration..."
                  />
                </InputLabel>
                <InputLabel
                  label="Trend Duration"
                  htmlFor="slidebot-trend-duration"
                  formatLabel={requiredLabel}
                  style={{ marginTop: "26px" }}
                >
                  <Select
                    inputId="slidebot-trend-duration"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={DurationOptions}
                    onChange={(e) => setTrendDuration(e?.value ?? "")}
                    placeholder="Select a trend duration..."
                  />
                </InputLabel>
                <SubmitButton
                  onClick={onSubmit}
                  disabled={disableSubmit}
                  style={{ marginTop: "60px", width: "100%" }}
                >
                  Submit Slidebot Request
                </SubmitButton>
              </FormPanelBody>
            </>
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
}
