import { IconButton } from "@braze/beacon-core";
import { IconContainer } from "./ActionPanel.styles";

type Props = {
  onEditClick: () => void;
  onUserClick: () => void;
};

export const ActionPanel = ({ onEditClick, onUserClick }: Props) => (
    <IconContainer>
      <IconButton name="edit" onClick={onEditClick}>
        Edit
      </IconButton>
      <IconButton name="user-cog" onClick={onUserClick}>
        Provision Users
      </IconButton>
    </IconContainer>
  );
