import styled from "styled-components";

export const RequestSummaryTextArea = styled.textarea`
  min-height: 30rem;
  width: 95%;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  resize: vertical;
`;
