import { dropDownSort } from "src/utils";

export enum PlgMotion {
  Learn = "Learn",
  Upsell = "Upsell",
  FreeTrial = "Free Trial",
}

export const PlgMotionOptions = dropDownSort([
  {
    label: PlgMotion.Learn,
    value: PlgMotion.Learn,
  },
  {
    value: PlgMotion.Upsell,
    label: PlgMotion.Upsell,
  },
  {
    value: PlgMotion.FreeTrial,
    label: PlgMotion.FreeTrial,
  },
]);
