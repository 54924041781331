import { Column } from "@braze/beacon-labs-data-table";
import styled from "styled-components";
import { getColor, getSpacing } from "@braze/beacon-styling";
import { FlexBox } from "@braze/beacon-core";

export const StyledDataTable = styled(FlexBox)`
  justify-content: center;
  margin: ${getSpacing("xxl")} 0;
  padding: 0 ${getSpacing("lg")};
  border: 1px solid ${getColor("gray", 100)};
`;

export const StyledColumn = styled(Column)`
  text-align: left;
`;

export const IdLabel = styled(Column)`
  color: ${getColor("lightBlue", 500)};
  cursor: pointer;
`;
