import { DefaultOptionType } from "@braze/beacon-lab-select";
import React, { useCallback, useMemo, useState } from 'react';
import { BasicDetails } from "src/components/QRCodeGenerator/BasicDetails";
import { Divider } from "src/components/QRCodeGenerator/Divider";
import {
    DashboardCompanyDetails
} from "src/components/QRCodeGenerator/DashboardCompanyDetails";
import { GenerateSection } from "src/components/QRCodeGenerator/GenerateSection";
import { QRCodeModal } from "src/components/QRCodeGenerator/QRCodeModal";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { FormPanelBodyFull, RequestForm } from "src/components/commonStyles";
import { QRCodeGeneratorBody, QRCodeGeneratorView } from "./styles";
import { Box, Heading } from "@braze/beacon-core";
import { CreateUserSection } from "src/components/QRCodeGenerator/CreateUserSection";
import { Attribute, CustomAttributes } from "src/components/QRCodeGenerator/CustomAttributes";
import { useEncryptedAPIKey } from "src/hooks/useEncryptedAPIKey";

type ValidationResult = {
    isValid: boolean,
    invalidInputs: string
}

export const QRCodeGenerator = () => {
    const [currentUrl, setCurrentUrl] = useState('')
    const [demoName, setDemoName] = useState('')
    const [demoStartPoint, setDemoStartPoint] = useState<DefaultOptionType | null>(null)
    const [endpoint, setEndpoint] = useState<DefaultOptionType | null>(null)
    const [androidApiKey, setAndroidApiKey] = useState('')
    const [iOSApiKey, setIOSApiKey] = useState('')
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false)
    const [isCreateUser, setIsCreateUser] = useState(false)
    const [isGenerateRandomUser, setIsGenerateRandomUser] = useState(false)
    const [externalIdRoot, setExternalIdRoot] = useState('')
    const [customAttributes, setCustomAttributes] = useState<Attribute[]>([])
    const [isEncrypting, setIsEncrypting] = useState(false)

    const [encryptKeys] = useEncryptedAPIKey()

    const onSetDemoStart = useCallback((demoOption: DefaultOptionType) => {
        setDemoStartPoint(demoOption)
    }, [])

    const attributesJson = useMemo(() => (
        customAttributes.reduce((acc, attribute) => {
            acc[attribute.name] = attribute.value;
            return acc;
        }, {} as { [key: string]: string })
    ), [customAttributes])

    const validateInputs = useMemo((): ValidationResult => {
        const invalidFields = [];
        if (!demoName) invalidFields.push("Demo Name");
        if (!demoStartPoint) invalidFields.push("Demo Start Point")
        if (!endpoint) invalidFields.push("SDK Endpoint");
        if (!androidApiKey) invalidFields.push("Android API Key");
        if (!iOSApiKey) invalidFields.push("iOS API Key");

        return {
            isValid: invalidFields.length === 0,
            invalidInputs: invalidFields.join(", ")
        };
    }, [demoName, endpoint, demoStartPoint, androidApiKey, iOSApiKey]);

    const onGenerateButtonPressed = useCallback(async () => {
        if (!validateInputs.isValid) {
            alert(`Please fill in the following required fields before generating the QR code: ${validateInputs.invalidInputs}`);
            return;
        }
        setIsEncrypting(true);        
        //Encrypt API Keys before generating the URL
        const encryptedData = await encryptKeys(iOSApiKey, androidApiKey);
        if (!encryptedData) {
            setIsEncrypting(false);
            alert("An error occurred while encrypting the API keys. Please try again.")
            return;
        }
        // Construct URL with form state
        let url = `braze-pilot://sdkauth?name=${encodeURIComponent(demoName)}&ios_api_key=${encodeURIComponent(encryptedData.ios)}&android_api_key=${encodeURIComponent(encryptedData.android)}`;

        if (endpoint) {
            url += `&sdk_endpoint=${encodeURIComponent(endpoint.value)}`
        }
        if (demoStartPoint) {
            url += `&redirect_location=${encodeURIComponent(demoStartPoint.value)}`
        }
        // Create user
        url += `&create_user=${isCreateUser}`;
        url += `&generate_random_user=${isGenerateRandomUser}`;
        if (isCreateUser && externalIdRoot) {
            url += `&external_id_root=${encodeURIComponent(externalIdRoot)}`;
        }

        // Custom Attributes
        if (customAttributes.length > 0) {
            url += `&attributes=${encodeURIComponent(JSON.stringify(attributesJson))}`;
        }

        setIsEncrypting(false);
        setCurrentUrl(url)
        setIsQRCodeModalOpen(true)
    }, [demoName, iOSApiKey, androidApiKey, endpoint, demoStartPoint, isCreateUser, isGenerateRandomUser, externalIdRoot, customAttributes, attributesJson])
    const onResetButtonPressed = () => {
        setCurrentUrl('')
        setDemoName('')
        setDemoStartPoint(null)
        setEndpoint(null)
        setAndroidApiKey('')
        setIOSApiKey('')
        setIsCreateUser(false)
        setIsGenerateRandomUser(false)
        setExternalIdRoot('')
        setCustomAttributes([])
    }

    const onQRCodeModalToggle = () => {
        setIsQRCodeModalOpen(false)
    }

    return (
        <Box>
            <NavigateBackButton />
            <RequestForm>
                <FormPanelBodyFull>
                    <QRCodeGeneratorView>
                        <Heading level={2}>Generate Braze Pilot Deeplink</Heading>
                        <QRCodeGeneratorBody>
                            <BasicDetails
                                demoName={demoName}
                                demoStartPoint={demoStartPoint}
                                onDemoNameChanged={setDemoName}
                                onDemoStartPointChanged={onSetDemoStart}
                            />
                            <Divider />
                            <DashboardCompanyDetails
                                endpoint={endpoint}
                                onEndpointChanged={setEndpoint}
                                androidApiKey={androidApiKey}
                                iosApiKey={iOSApiKey}
                                onAndroidApiKeyChanged={setAndroidApiKey}
                                oniOSApiKeyChanged={setIOSApiKey}
                            />
                            <Divider />
                            <CreateUserSection
                                isCreateUser={isCreateUser}
                                setIsCreateUser={setIsCreateUser}
                                isGenerateRandomUser={isGenerateRandomUser}
                                setIsGenerateRandomUser={setIsGenerateRandomUser}
                                externalIdRoot={externalIdRoot}
                                setExternalIdRoot={setExternalIdRoot}
                            />
                            <Divider />
                            <CustomAttributes attributes={customAttributes} setAttributes={setCustomAttributes} />
                            <Divider />
                            <GenerateSection
                                onGenerateButtonPressed={onGenerateButtonPressed}
                                onResetButtonPressed={onResetButtonPressed}
                                loading={isEncrypting}
                            />
                            <QRCodeModal url={currentUrl} isOpen={isQRCodeModalOpen} toggle={onQRCodeModalToggle} />
                        </QRCodeGeneratorBody>
                    </QRCodeGeneratorView>
                </FormPanelBodyFull>
            </RequestForm>
        </Box>
    );
}
