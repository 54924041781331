import { dropDownSort } from "src/utils";

export enum DataTypes {
  NewDataSource = "New Data Source",
  NewDataSet = "New Data Set",
}

export enum WorkImpact {
  MyWork = "This Only Impacts My Work",
  MyTeam = "This Impacts My Team",
  MultipleTeams = "This Impacts Multiple Teams",
}

export const DataTypeOptions = dropDownSort([
  {
    value: DataTypes.NewDataSource,
    label: DataTypes.NewDataSource,
  },
  {
    value: DataTypes.NewDataSet,
    label: DataTypes.NewDataSet,
  },
]);

export const WorkImpactOptions = dropDownSort([
  {
    value: WorkImpact.MyWork,
    label: WorkImpact.MyWork,
  },
  {
    value: WorkImpact.MyTeam,
    label: WorkImpact.MyTeam,
  },
  {
    value: WorkImpact.MultipleTeams,
    label: WorkImpact.MultipleTeams,
  },
]);
