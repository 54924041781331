import { Toaster } from "@braze/beacon-core";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { AdminTable } from "./AdminTable/AdminTable";
import { AdminTableWrapper } from "./styles";

export const SandboxAdmin = () => (
  <>
    <Toaster autoClose={3000} id="toaster-admin-table" />
    <NavigateBackButton />
    <AdminTableWrapper>
      <AdminTable />
    </AdminTableWrapper>
  </>
);
