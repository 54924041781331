import { IconButton } from "@braze/beacon-core";
import { useNavigate } from "react-router-dom";
import { StyledHomeButton } from "src/components/commonStyles";

/**
 * Navigate back IconButton, displayed with a left carrot icon that replaces
 *  current URL with the home url (/)
 */
export const NavigateBackButton = () => {
  const history = useNavigate();

  const homePage = () => history("/");

  return (
    <StyledHomeButton>
      <IconButton
        aria-label="Navigate Back"
        name="angle-left"
        onClick={homePage}
      />
    </StyledHomeButton>
  );
};
