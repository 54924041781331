import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";
import { useOktaAuth } from "@okta/okta-react";
import { fetcher } from "./fetcher";
import { RequestParams } from "./types";

export const useApi = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  route: string,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey"
  > & { params?: RequestParams }
): UseQueryResult<TData, TError> => {
  const { authState } = useOktaAuth();

  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      fetcher<TQueryFnData>(route, authState?.idToken?.idToken, {
        method: "GET",
        params: options?.params,
      }),
    ...options,
  });
};
