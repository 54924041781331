export enum SlidebotTemplates {
  EBR = "EBR_TEMPLATE_SLIDEBOT",
  ADVANCE_EBR = "ADVANCE_EBR_TEMPLATE_SLIDEBOT",
  EBR_JP = "EBR_KK_PROD",
}

export enum IndustryRegions {
  All = "All",
  EMEA = "EMEA",
  APAC = "APAC",
}

export enum Durations {
  Month_3 = "3 months",
  Month_6 = "6 months",
  Month_9 = "9 months",
  Month_12 = "12 months",
}

export const RegionOptions = Object.values(IndustryRegions).map((r) => ({
  label: r,
  value: r,
}));

export const DurationOptions = Object.values(Durations).map((d) => ({
  label: d,
  value: d,
}));

export const SlidebotTemplateOptions = [
  { label: "EBR Standard", value: SlidebotTemplates.EBR },
  {
    label: "EBR for Advanced Entitlement",
    value: SlidebotTemplates.ADVANCE_EBR,
  },
  { label: "EBR Standard Japan", value: SlidebotTemplates.EBR_JP },
];
