import { useState } from "react";
import _ from "lodash";

export const useForm = <T>(defaults: T) => {
  const [formState, setFormState] = useState<T>(defaults);
  const setFormFieldValue = (field: keyof T, value: T[typeof field]) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };
  const isTouched = !_.isEqual(defaults, formState);
  return {
    formState,
    setFormFieldValue,
    isTouched,
  };
};
