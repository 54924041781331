import { usePost } from "./usePost";
import { API_BASE_URL } from "src/config";

type Priority = {
  id: string;
};

export type ProjectKey =
  | "BI"
  | "DE"
  | "DS"
  | "GE"
  | "GTR"
  | "FDDS"
  | "ZTP"
  | "L10N";
export type IssueType = "Story" | "Bug" | "Epic" | "Task";
export type JiraIssueRequest = {
  fields: {
    project: {
      key: ProjectKey;
    };
    summary: string;
    description: string;
    issuetype: {
      name: IssueType;
    };
    customfield_10002?: string;
    customfield_10000?: string;
    labels?: string[];
    priority?: Priority;
  };
};

type JiraIssueResponse = {
  id: string;
  key: string;
  self: string;
};

type UseCreateIssueReturn = [
  (data: JiraIssueRequest) => Promise<JiraIssueResponse | null>,
  {
    value?: JiraIssueResponse;
    loading: boolean;
    called: boolean;
    error?: string;
    isSuccess: boolean;
  }
];

export const useCreateIssue = (): UseCreateIssueReturn => {
  const [post, postState] = usePost<JiraIssueResponse, JiraIssueRequest>({
    url: `${API_BASE_URL}/api/issue`,
  });

  const createIssue = async (
    data: JiraIssueRequest
  ): Promise<JiraIssueResponse | null> => {
    const result = await post(data);
    return result;
  };

  // Return the createIssue function and the entire postState object
  return [createIssue, postState];
};
