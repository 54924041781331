import React, { useState } from 'react';
import {
    BodyText,
    Box,
    Button,
    IconButton,
    Modal,
    ModalBody,
    ModalHeading,
    Table,
    TableBody,
    TableCell,
    TableHeaderCell,
    TableRow, Textarea
} from "@braze/beacon-core";
import {
    CustomAttributesButton,
    CustomAttributesContainer,
    CustomAttributesEmptyTextContainer,
    CustomAttributesHeading,
    CustomAttributesModalFieldLabel,
    CustomAttributesModalFooter,
    CustomAttributesModalTopFieldLabel,
    CustomAttributesTableActionsContainer,
    CustomAttributesTableActionsTableCell
} from "./styles";

export type Attribute = {
    name: string;
    value: string;
}

type CustomAttributesProps = {
    attributes: Attribute[],
    setAttributes: (attributes: Attribute[]) => void;
}

export const CustomAttributes = ({attributes, setAttributes}: CustomAttributesProps) => {
    const [showModal, setShowModal] = useState(false);
    const [currentAttribute, setCurrentAttribute] = useState<Attribute>({name: '', value: ''});
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);

    const handleOpenModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentAttribute({name: '', value: ''});
        setIsEditing(false);
        setEditIndex(null);
    };

    const handleSaveAttribute = () => {
        if (isEditing && editIndex !== null) {
            const updatedAttributes = attributes.map((attr, index) =>
                index === editIndex ? currentAttribute : attr
            );
            setAttributes(updatedAttributes);
        } else {
            setAttributes([...attributes, currentAttribute]);
        }
        handleCloseModal();
    };

    const handleDeleteAttribute = (index: number) => {
        if (window.confirm("Are you sure you want to delete this attribute?")) {
            setAttributes(attributes.filter((_, i) => i !== index));
        }
    };

    const handleEditAttribute = (index: number) => {
        setCurrentAttribute(attributes[index]);
        setIsEditing(true);
        setEditIndex(index);
        setShowModal(true);
    };

    return (
        <CustomAttributesContainer>
            <CustomAttributesHeading level={3}>Custom Attributes</CustomAttributesHeading>
            {attributes.length === 0 ? (
                <CustomAttributesEmptyTextContainer>
                    <Box>
                        <BodyText><strong>You don’t have any custom attributes yet</strong></BodyText>
                    </Box>
                    <Box>
                        <BodyText>Add an attribute to log it when the user is created.</BodyText>
                    </Box>
                </CustomAttributesEmptyTextContainer>
            ) : (
                <Table fullWidth>
                    <TableRow>
                        <TableHeaderCell>Attribute Name</TableHeaderCell>
                        <TableHeaderCell>Attribute Value</TableHeaderCell>
                    </TableRow>
                    <TableBody>
                    {attributes.map((attr, index) => (
                        <TableRow key={index}>
                            <TableCell>{attr.name}</TableCell>
                            <TableCell>{attr.value}</TableCell>
                            <CustomAttributesTableActionsTableCell>
                                <CustomAttributesTableActionsContainer>
                                    <IconButton name="pencil" aria-label="Edit" onClick={() => handleEditAttribute(index)}/>
                                    <IconButton name="trash" aria-label="Delete"onClick={() => handleDeleteAttribute(index)}/>
                                </CustomAttributesTableActionsContainer>
                            </CustomAttributesTableActionsTableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            )}
            <CustomAttributesButton onClick={handleOpenModal}>
                <span>+</span> Add Attribute
            </CustomAttributesButton>
                <Modal size="md" isOpen={showModal} >
                    <ModalHeading>{isEditing ? 'Edit Attribute' : 'Add Attribute'}</ModalHeading>
                    <ModalBody>
                       <BodyText>
                           {isEditing ? 'Adjust the name and value for this custom attribute.'
                               :
                               'Set a name and value for a new custom attribute.'
                           }
                       </BodyText>
                       <CustomAttributesModalTopFieldLabel label="Attribute Name" enforce="required"/>
                       <Textarea
                           vSize="md"
                           placeholder="favorite colors"
                           fullWidth
                           value={currentAttribute.name}
                           onChange={(e) =>
                               setCurrentAttribute({ ...currentAttribute, name: e.target.value })
                           }
                       />
                       <CustomAttributesModalFieldLabel label="Attribute Value" enforce="required"/>
                       <Textarea
                           vSize="xl"
                           placeholder="['blue', 'green']"
                           fullWidth
                           value={currentAttribute.value}
                           onChange={(e) =>
                               setCurrentAttribute({ ...currentAttribute, value: e.target.value })
                           }
                       />
                    </ModalBody>
                    <CustomAttributesModalFooter aligner side="right">
                        <Button onClick={handleCloseModal}>Cancel</Button>
                        <Button onClick={handleSaveAttribute}>Save</Button>
                    </CustomAttributesModalFooter>
                </Modal>
        </CustomAttributesContainer>
    );
};
