import { FlexBox } from "@braze/beacon-core";
import styled from "styled-components";

export const Background = styled(FlexBox)`
  background: url("../../public/img/braze-gradient.svg");
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 104.5px;
  right: 0;
  bottom: 32.5px;
  left: 0;
  overflow: scroll;
  flex-direction: column;
`;

export const TileContainer = styled(FlexBox)`
  /* min-height: calc(100vh - 104.5px - 32.5px); */
  padding: 2rem 4rem;
  /* height: 100%; */
  align-items: space-evenly;
  //justify-content: center;
  display: flex;
  flex-direction: column;
  background: #ffffff6a;
  overflow: scroll;
  z-index: 100;
`;
