import {
  AnalyticsCapability,
  AnalyticsCapabilityOptions,
  CompanyCategory,
  CompanyCategoryOptions,
  DataEnhancement,
  DataEnhancementOptions,
  DataExportCapabilityOption,
  GsaCustomerOptions,
  MAUCategoryOptions,
  Need,
  NeedOptions,
  RequestCategory,
  RequestCategoryOptions,
  Team,
  TeamOptions,
  UrgencyOptions,
} from "./data";
import { FieldLabel, Input, Textarea, makeToast } from "@braze/beacon-core";
import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  RequestForm,
  StyledBodyText,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import { Select } from "@braze/beacon-lab-select";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { requiredLabel } from "src/components/required";
import { useCreateIssue } from "src/hooks/useCreateIssue";
import {
  JiraEpicLinks,
  JiraLabel,
  JiraProjectKeys,
  getJiraConfig,
} from "src/pages/growth/jiraRouting";
import { ThankYou } from "src/pages/thank-you";
import { useForm } from "src/hooks/useForm";
import { createJiraIssueReq } from "./utils/jiraConfig";
import { DataServicesFormFieldType } from "./types";
import { OtherInfoDiv } from "./styles";

export const DataServices = () => {
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();
  const { formState, setFormFieldValue } = useForm<DataServicesFormFieldType>({
    customerName: "",
    appGroupId: "",
    analyticsOther: "",
    dataEnhancementOther: "",
    requestTitle: "",
    requestSummary: "",
    needOther: "",
    teamOther: "",
  });
  //required fields
  const {
    companyCategory,
    mauCategory,
    customerName,
    gsaCustomer,
    appGroupId,
    requestCategory,
    analyticsCapability,
    analyticsOther,
    dataEnhancement,
    dataEnhancementOther,
    dataExport,
    requestTitle,
    requestSummary,
    need,
    team,
    needOther,
    urgency,
    teamOther,
  } = formState;

  // Disable submit button if following aren't populated
  const companyCategoryGsa =
    companyCategory === CompanyCategory.GSA && !!urgency && !!gsaCustomer;
  const needOtherInput = need === Need.Other ? !!needOther : true;
  const companyCategoryNonGsa =
    companyCategory !== CompanyCategory.GSA &&
    !!customerName &&
    !!need &&
    needOtherInput;

  const requestCatagoryInput = () => {
    if (requestCategory === RequestCategory.Analytics) {
      return !!analyticsCapability &&
        analyticsCapability === AnalyticsCapability.Other
        ? !!analyticsOther
        : true;
    } else if (requestCategory === RequestCategory.DataEnhancements) {
      return !!dataEnhancement && dataEnhancement === DataEnhancement.Other
        ? !!dataEnhancementOther
        : true;
    } else if (requestCategory === RequestCategory.DataExport) {
      return !!dataExport;
    } else {
      return !!requestCategory;
    }
  };
  const teamInput = team === Team.Other ? !!teamOther : true;

  const submitDisabled =
    !companyCategory ||
    !mauCategory ||
    !appGroupId ||
    !requestCategory ||
    !requestTitle ||
    !requestSummary ||
    !team ||
    !(companyCategoryGsa || companyCategoryNonGsa) ||
    !requestCatagoryInput() ||
    !teamInput ||
    createJiraIssueRes.loading;

  const onSubmit = async () => {
    let jiraConfig = getJiraConfig("Data Services", formState);
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!", formState);
      if (gsaCustomer) {
        jiraConfig = {
          labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
          epicLink: JiraEpicLinks.FDDS.FDDS674,
          jiraProjectKey: JiraProjectKeys.FDDS,
        };
      } else {
        return; // Required
      }
    }
    const jiraIssueRequest = createJiraIssueReq(jiraConfig, formState);
    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };

  return (
    <>
      <NavigateBackButton />
      <RequestForm>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>Data Services Request</FormHeading>
              <FormDetails>
                <StyledBodyText>
                  Disclaimer: Data Services Team bandwidth is limited and
                  requests for help may not be serviceable due to the size of
                  our team. We will connect either way to inform you on the
                  status of your request.
                </StyledBodyText>
                <FieldLabel
                  label="Company Classification"
                  htmlFor="company-category"
                  formatLabel={requiredLabel}
                >
                  <Select
                    inputId="company-category"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={CompanyCategoryOptions}
                    onChange={(selectedOption) => {
                      setFormFieldValue(
                        "companyCategory",
                        selectedOption?.value
                      );
                    }}
                    value={CompanyCategoryOptions.find(
                      (o) => o.value === companyCategory
                    )}
                  />
                </FieldLabel>
                {companyCategory && (
                  <>
                    <FieldLabel
                      label="MAU"
                      htmlFor="mau-category"
                      formatLabel={requiredLabel}
                    >
                      <Select
                        inputId="mau-category"
                        isClearable
                        isDisabled={false}
                        isSearchable
                        fluid
                        options={MAUCategoryOptions}
                        onChange={(selectedOption) => {
                          setFormFieldValue(
                            "mauCategory",
                            selectedOption?.value
                          );
                        }}
                        value={MAUCategoryOptions.find(
                          (o) => o.value === mauCategory
                        )}
                      />
                    </FieldLabel>
                    {companyCategory !== CompanyCategory.GSA && (
                      <FieldLabel
                        label="Customer Name"
                        htmlFor="customer-name"
                        formatLabel={requiredLabel}
                      >
                        <Input
                          id="customer-name"
                          placeholder=""
                          required
                          fullWidth
                          value={customerName}
                          onChange={(e) =>
                            setFormFieldValue("customerName", e.target.value)
                          }
                        />
                      </FieldLabel>
                    )}
                    {companyCategory === CompanyCategory.GSA && (
                      <FieldLabel
                        label="GSA Customer"
                        htmlFor="gsa-customer"
                        formatLabel={requiredLabel}
                      >
                        <Select
                          inputId="gsa-customer"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={GsaCustomerOptions}
                          onChange={(selectedOption) => {
                            setFormFieldValue(
                              "gsaCustomer",
                              selectedOption?.value
                            );
                          }}
                          value={GsaCustomerOptions.find(
                            (o) => o.value === gsaCustomer
                          )}
                        />
                      </FieldLabel>
                    )}
                    <FieldLabel
                      label="App Group ID"
                      htmlFor="app-group-id"
                      formatLabel={requiredLabel}
                    >
                      <Input
                        id="app-group-id"
                        placeholder=""
                        required
                        fullWidth
                        value={appGroupId}
                        onChange={(e) =>
                          setFormFieldValue("appGroupId", e.target.value)
                        }
                      />
                    </FieldLabel>
                    <FieldLabel
                      label="Request Category"
                      htmlFor="request-category"
                      formatLabel={requiredLabel}
                    >
                      <SubText>
                        What sort of questions are the customers asking of you?
                      </SubText>
                      <Select
                        inputId="request-category"
                        isClearable
                        isDisabled={false}
                        isSearchable
                        fluid
                        options={RequestCategoryOptions}
                        onChange={(selectedOption) => {
                          setFormFieldValue(
                            "requestCategory",
                            selectedOption?.value
                          );
                        }}
                        value={RequestCategoryOptions.find(
                          (o) => o.value === requestCategory
                        )}
                      />
                    </FieldLabel>
                    {requestCategory === RequestCategory.Analytics && (
                      <>
                        <FieldLabel
                          label="What type of Analytics Capabilities is the customer looking for?"
                          htmlFor="analytics"
                          formatLabel={requiredLabel}
                        >
                          <Select
                            inputId="analytics"
                            isClearable
                            isDisabled={false}
                            isSearchable
                            fluid
                            options={AnalyticsCapabilityOptions}
                            onChange={(selectedOption) => {
                              setFormFieldValue(
                                "analyticsCapability",
                                selectedOption?.value
                              );
                            }}
                            value={AnalyticsCapabilityOptions.find(
                              (o) => o.value === analyticsCapability
                            )}
                          />
                        </FieldLabel>
                        {analyticsCapability === AnalyticsCapability.Other && (
                          <FieldLabel
                            label="Other Analytics Capability"
                            htmlFor="other-analytics"
                            formatLabel={requiredLabel}
                          >
                            <Input
                              id="analytics-other"
                              placeholder=""
                              required
                              fullWidth
                              value={analyticsOther}
                              onChange={(e) =>
                                setFormFieldValue(
                                  "analyticsOther",
                                  e.target.value
                                )
                              }
                            />
                          </FieldLabel>
                        )}
                      </>
                    )}
                    {requestCategory === RequestCategory.DataEnhancements && (
                      <>
                        <FieldLabel
                          label="What Data Enhancement Needs?"
                          htmlFor="data-enhancement"
                          formatLabel={requiredLabel}
                        >
                          <Select
                            inputId="data-enhancement"
                            isClearable
                            isDisabled={false}
                            isSearchable
                            fluid
                            options={DataEnhancementOptions}
                            onChange={(selectedOption) => {
                              setFormFieldValue(
                                "dataEnhancement",
                                selectedOption?.value
                              );
                            }}
                            value={DataEnhancementOptions.find(
                              (o) => o.value === dataEnhancement
                            )}
                          />
                        </FieldLabel>
                        {dataEnhancement === DataEnhancement.Other && (
                          <FieldLabel
                            label="Other Data Enhancement"
                            htmlFor="other-enhancement"
                            formatLabel={requiredLabel}
                          >
                            <Input
                              id="data-enhancement-other"
                              placeholder=""
                              required
                              fullWidth
                              value={dataEnhancementOther}
                              onChange={(e) =>
                                setFormFieldValue(
                                  "dataEnhancementOther",
                                  e.target.value
                                )
                              }
                            />
                          </FieldLabel>
                        )}
                      </>
                    )}
                    {requestCategory === RequestCategory.DataExport && (
                      <FieldLabel
                        label="Which Data Export Capability?"
                        htmlFor="data-export"
                        formatLabel={requiredLabel}
                      >
                        <Select
                          inputId="data-export"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={DataExportCapabilityOption}
                          onChange={(selectedOption) => {
                            setFormFieldValue(
                              "dataExport",
                              selectedOption?.value
                            );
                          }}
                          value={DataExportCapabilityOption.find(
                            (o) => o.value === dataExport
                          )}
                        />
                      </FieldLabel>
                    )}
                    <FieldLabel
                      label="Request Title"
                      htmlFor="request-title"
                      formatLabel={requiredLabel}
                    >
                      <SubText>
                        This will be the title of the request shown in our
                        request board, please keep titles short
                      </SubText>
                      <Input
                        id="request-title"
                        placeholder=""
                        required
                        fullWidth
                        maxLength={200}
                        value={requestTitle}
                        onChange={(e) =>
                          setFormFieldValue("requestTitle", e.target.value)
                        }
                      />
                    </FieldLabel>
                    <FieldLabel
                      label="Request Summary"
                      htmlFor="request-summary"
                      formatLabel={requiredLabel}
                    >
                      <SubText>
                        What do you need Data Services help on? Please include
                        links to all related documents for review related to
                        your request.
                      </SubText>
                      <Textarea
                        id="request-summary"
                        placeholder=""
                        fullWidth
                        value={requestSummary}
                        vSize="lg"
                        onChange={(e) =>
                          setFormFieldValue("requestSummary", e.target.value)
                        }
                      />
                    </FieldLabel>
                    {companyCategory === CompanyCategory.GSA && (
                      <FieldLabel
                        label="Urgency"
                        htmlFor="urgency"
                        formatLabel={requiredLabel}
                      >
                        <Select
                          inputId="urgency"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={UrgencyOptions}
                          onChange={(selectedOption) => {
                            setFormFieldValue("urgency", selectedOption?.value);
                          }}
                          value={UrgencyOptions.find(
                            (o) => o.value === urgency
                          )}
                        />
                      </FieldLabel>
                    )}
                    {companyCategory !== CompanyCategory.GSA && (
                      <FieldLabel
                        label="Need"
                        htmlFor="need"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Indicate Reason for Data Services Request
                        </SubText>
                        <Select
                          inputId="need"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={NeedOptions}
                          onChange={(selectedOption) => {
                            setFormFieldValue("need", selectedOption?.value);
                          }}
                          value={NeedOptions.find((o) => o.value === need)}
                        />
                        {need === Need.Other && (
                          <OtherInfoDiv>
                            <FieldLabel
                              label="Other Need"
                              htmlFor="other-need"
                              formatLabel={requiredLabel}
                            >
                              <Input
                                id="need-other"
                                placeholder=""
                                required
                                fullWidth
                                value={needOther}
                                onChange={(e) =>
                                  setFormFieldValue("needOther", e.target.value)
                                }
                              />
                            </FieldLabel>
                          </OtherInfoDiv>
                        )}
                      </FieldLabel>
                    )}
                    <FieldLabel
                      label="Team"
                      htmlFor="team"
                      formatLabel={requiredLabel}
                    >
                      <Select
                        inputId="team"
                        isClearable
                        isDisabled={false}
                        isSearchable
                        fluid
                        options={TeamOptions}
                        onChange={(selectedOption) => {
                          setFormFieldValue("team", selectedOption?.value);
                        }}
                        value={TeamOptions.find((o) => o.value === team)}
                      />
                      {team === Team.Other && (
                        <OtherInfoDiv>
                          <FieldLabel
                            label="Other Team"
                            htmlFor="other-team"
                            formatLabel={requiredLabel}
                          >
                            <Input
                              id="team-other"
                              placeholder=""
                              required
                              fullWidth
                              maxLength={50}
                              value={teamOther}
                              onChange={(e) =>
                                setFormFieldValue("teamOther", e.target.value)
                              }
                            />
                          </FieldLabel>
                        </OtherInfoDiv>
                      )}
                    </FieldLabel>
                    <SubmitButton
                      variant="primary"
                      intent="info"
                      disabled={submitDisabled}
                      onClick={onSubmit}
                    >
                      Submit Request
                    </SubmitButton>
                  </>
                )}
              </FormDetails>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && !!createJiraIssueRes.value && (
            <ThankYou ticketKey={createJiraIssueRes.value.key} />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
