import { Flex, FlexBox, Label } from "@braze/beacon-core";
import styled from "styled-components";

export const EmailListBox = styled(FlexBox)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  color: #333;
  margin-bottom: 2rem;
  max-height: 350px;
  overflow: scroll;
  &:hover {
    cursor: not-allowed;
  }
`;

export const SuccessLabel = styled(Label)`
  color: green;
`;

export const FailureLabel = styled(Label)`
  color: red;
`;

interface EmailRowProps {
  last?: boolean;
}

export const EmailRow = styled(Flex)<EmailRowProps>`
  margin-bottom: ${(props) => (props.last ? "0px" : "10px")};
  padding: 5px;
  justify-content: space-between;
  border-bottom: ${(props) => (props.last ? "none" : "1px solid #d3d3d3")};
`;
