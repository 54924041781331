import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Button,
  Flex,
  FlexBox,
  Heading,
  Icon,
  Label,
} from "@braze/beacon-core";

import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { FeedbackSubmissionModal } from "src/components/FeedbackSubmission";
import {
  Content,
  Footer,
  GrowlitheImgBlock,
  GrowlitheTitle,
  Header,
  LogoBlock,
  LogoText,
  NavMenu,
  SignIn,
  SignInBackground,
  SignInBlock,
} from "./styles";

export const isProductionUrl =
  !window.location.hostname.includes("localhost") &&
  !window.location.hostname.includes("staging");
console.log("isProduction:", isProductionUrl);

if (!isProductionUrl) {
  console.log("Tracking stopped ...");
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const MainLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims>();

  const LoadingSpinner = lazy(() =>
    import("@braze/beacon-labs-spinner").then((module) => ({
      default: module.LoadingSpinner,
    }))
  );

  oktaAuth.getAccessToken();
  const login = async () => oktaAuth.signInWithRedirect({});
  const logout = async () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: "/",
    });
    document.location.reload();
  };
  const history = useNavigate();
  const homePage = () => history("/");

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      setUserInfo(undefined);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        localStorage.setItem("user", JSON.stringify(info));
      });
    }
  }, [oktaAuth, authState]);

  if (window.location.hash) {
    return <LoginCallback />;
  }

  if (!authState) {
    return (
      <>
        <FlexBox justifyContent="center">
          <Suspense fallback={<div>Loading...</div>}>
            <LoadingSpinner />
          </Suspense>
        </FlexBox>
      </>
    );
  }

  if (!authState.isAuthenticated) {
    return (
      <>
        <SignInBackground>
          <SignIn />
          <SignInBlock>
            <GrowlitheImgBlock>
              <img
                src="../../public/img/login-growlithe.png"
                alt="growlithe-polygon-img"
              />
            </GrowlitheImgBlock>
            <LogoBlock direction="column">
              <img
                src="../../public/img/braze-logo-black.svg"
                height="60"
                alt="braze-logo"
              />
              <GrowlitheTitle color="primary">Growlithe</GrowlitheTitle>
              <Label style={{ fontSize: "18px" }}>
                A resource hub for all Growth projects.
              </Label>
              {!isProductionUrl && (
                <Heading level={5}>(You&apos;ll need the VPN connectivity)</Heading>
              )}
              <Button onClick={login}>Sign In with Okta</Button>
            </LogoBlock>
          </SignInBlock>
        </SignInBackground>
      </>
    );
  }

  return (
    <>
      <Header justifyContent="space-between" alignItems="center">
        <NavMenu onClick={homePage} style={{ cursor: "pointer", width: "80%" }}>
          <img
            src="../../public/img/growlithe-header.png"
            alt="growlithe-logo"
          />
          <LogoText alignItems="center" direction="column">
            <Label>Growlithe</Label>
          </LogoText>
        </NavMenu>
        {/* <img
          src="../../public/img/braze-logo-black.svg"
          alt="braze-logo"
          height="50"
        /> */}
        <Flex
          style={{
            marginRight: "2rem",
            padding: "1rem 0",
            alignContent: "center",
          }}
          justifyContent="flex-end"
          direction="column"
        >
          <Label style={{ fontSize: "14px", fontWeight: "normal" }}>
            {userInfo?.name}
          </Label>
          <Label style={{ fontSize: "14px", fontWeight: "normal" }}>
            {userInfo?.email}
          </Label>
          <Button
            variant="quaternary"
            style={{ justifyContent: "left", color: "#2E3C47" }}
            onClick={logout}
          >
            <span>Log Out</span>
            <Icon name="sign-out-alt" />
          </Button>
        </Flex>
      </Header>
      {/* <Flex justifyContent="flex-end" style={{ marginRight: "5rem" }}>
        <Button
          variant="quaternary"
          onClick={toggle}
          style={{ margin: "1rem", zIndex: 1000 }}
        >
          Help
        </Button>
      </Flex> */}
      <Content>{children}</Content>
      <Footer>&copy; {dayjs().get("year")} Braze | All Rights Reserved</Footer>
      {userInfo?.email && (
        <FeedbackSubmissionModal
          productName="Growlithe"
          email={userInfo?.email}
        />
      )}
      {/* <HelpModal isOpen={isOpen} toggle={toggle} /> */}
    </>
  );
};
