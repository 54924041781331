import React from "react";
import { DepartmentLabel, RequestTile } from "./styles";

type DepartmentProps = { departmentName: string; children: React.ReactNode };

export const Department = ({ departmentName, children }: DepartmentProps) => {
  if (
    !React.Children.toArray(children).filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (child: any) => child?.props?.shown
    ).length
  ) {
    return null;
  }
  return (
    <>
      <DepartmentLabel>{departmentName}</DepartmentLabel>
      <RequestTile direction="row" flex-wrap="wrap">
        {children}
      </RequestTile>
    </>
  );
};
