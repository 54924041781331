import { useEffect, useState } from "react";
import { Select } from "@braze/beacon-lab-select";

import { AccountResult, IndustryResult } from "src/hooks/types";
import { OptionsLabelValue } from "src/components/types";
import { useIndustries } from "src/hooks/useGetIndustries";

type IndustrySelectProps = {
  onIndustrySelect: (industries: string[]) => void;
  selectedAccount?: AccountResult;
  isMulti?: boolean;
};

export const IndustrySelect = ({
  onIndustrySelect,
  selectedAccount,
  isMulti,
}: IndustrySelectProps) => {
  const [industryOptions, setIndustryOptions] = useState<OptionsLabelValue[]>(
    []
  );
  const [selectedOptions, setSelectedOptions] = useState<OptionsLabelValue[]>(
    []
  );

  const { data: value, isFetching: loading } = useIndustries(!!selectedAccount);

  useEffect(() => {
    if (value) {
      const res = (value || []).map((ind: IndustryResult) => ({
        label: ind.INDUSTRY,
        value: ind.INDUSTRY,
      }));
      setIndustryOptions(res);
    }
  }, [value]);

  useEffect(() => {
    setSelectedOptions([]);
    onIndustrySelect([]);
    if (selectedAccount === undefined) {
      setIndustryOptions([]);
    } else {
      // refetch({ id: selectedAccount.EXTERNAL_ID });
      if (selectedAccount.INDUSTRY) {
        setSelectedOptions([
          {
            label: selectedAccount.INDUSTRY,
            value: selectedAccount.INDUSTRY,
          },
          ...selectedOptions,
        ]);
      }
    }
  }, [selectedAccount]);

  const isMultiple = isMulti === undefined ? true : isMulti;

  useEffect(() => {
    if (!isMultiple) {
      onIndustrySelect(selectedOptions ? [selectedOptions[0].label] : []);
    } else {
      const options: string[] = (selectedOptions || []).map(
        (selectedOption: OptionsLabelValue) => selectedOption.label
      );
      onIndustrySelect(options);
    }
  }, [isMultiple, selectedOptions]);

  return (
    <Select
      inputId="industry"
      isMulti={isMultiple}
      isClearable
      isSearchable
      fluid
      options={industryOptions}
      menuPortalTarget={document.body}
      value={selectedOptions}
      isLoading={loading}
      placeholder="Select an industry..."
      onChange={(selectedOptions) => {
        setSelectedOptions(selectedOptions);
      }}
    />
  );
};
