import { FlexBox } from "@braze/beacon-core";
import { getSpacing } from "@braze/beacon-styling";
import styled from "styled-components";

export const IconContainer = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${getSpacing("md")};
`;
