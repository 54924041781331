import { dropDownSort } from "src/utils";

export enum RequestType {
  AccessPermissions = "Access & Permissions (Looker)",
  Bug = "Report a Bug",
  FeatureRequest = "Feature Request (Dashboard level change)",
}

export const RequestTypeOptions = dropDownSort([
  {
    value: RequestType.Bug,
    label: RequestType.Bug,
  },
  {
    value: RequestType.AccessPermissions,
    label: RequestType.AccessPermissions,
  },
  {
    value: RequestType.FeatureRequest,
    label: RequestType.FeatureRequest,
  },
]);

export enum WorkImpact {
  MyWork = "P2 - This Only Impacts My Work",
  MyTeam = "P1 - This Impacts My Team",
  MultipleTeams = "P0 - This Impacts Multiple Teams",
}

export const WorkImpactOptions = [
  { value: WorkImpact.MyWork, label: WorkImpact.MyWork },
  { value: WorkImpact.MyTeam, label: WorkImpact.MyTeam },
  { value: WorkImpact.MultipleTeams, label: WorkImpact.MultipleTeams },
];

export type FormField = {
  requestType: RequestType;
  requestSummary: {
    summary: string;
    dashboardLink?: string;
  };
  workImpact?: WorkImpact;
  keyStakeholders: string;
  mainTitle: string;
  isConfirmed: boolean;
};
