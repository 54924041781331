import { FieldLabel, Label } from "@braze/beacon-core";

import styled from "styled-components";

export const CheckBoxFieldLabel = styled(Label)`
  margin-left: 0.5rem;
`;

export const SelectedInputStyle = styled(FieldLabel)`
  margin-top: 1rem;
`;
