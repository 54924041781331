// add contacts in sandbox environment
import { useApiMutation } from "src/hooks/useApi";
import { Box, FieldLabel, StatusText, makeToast } from "@braze/beacon-core";
import {
  Contact,
  ContactSelect,
} from "src/components/ContactSelect";
import {
  FormDetails,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import {
  ContactProvisionResult,
  SandBoxFreeTrialResult,
} from "src/hooks/types";
import { useApi } from "src/hooks/useApi";
import { useCallback, useMemo, useState } from "react";
import { LoadingSpinner } from "@braze/beacon-labs-spinner";
import { getUserInformation } from "src/utils";

type AddSandboxContactsProps = {
  accountId?: string;
  sandboxContacts?: Contact[];
  submissionId?: string;
  toggle: () => void;
  handleOnSuccess?: () => void;
};

type SandBoxFreeTrialContactMutation = {
  message: string;
  data: {
    verifiedContacts: ContactProvisionResult[];
    unverifiedContacts: ContactProvisionResult[];
    provisionedUsers: ContactProvisionResult[];
    unprovisionedUsers: ContactProvisionResult[];
    errors: number;
  };
};

export const AddSandboxContacts = ({
  submissionId,
  toggle,
  handleOnSuccess,
}: AddSandboxContactsProps) => {
  const userInfo = getUserInformation();
  const [error, setError] = useState("");
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

  const { data, isError, isLoading } = useApi<SandBoxFreeTrialResult>(
    ["submissionData", submissionId],
    `sandbox/submission/${submissionId}`
  );

  const {
    mutateAsync,
    isLoading: isLoadingSubmission,
    isError: isMutationError,
  } = useApiMutation<SandBoxFreeTrialContactMutation>(
    "POST",
    `/sandbox/contacts/${submissionId}`
  );

  const filteredContactEmails = useMemo(
    () => data?.CONTACTS.map((c) => c.email),
    [data?.CONTACTS]
  );

  const onContactSelect = useCallback(
    (contacts: Contact[]) => {
      setSelectedContacts(contacts);
    },
    [setSelectedContacts]
  );

  const onSubmit = useCallback(async () => {
    setError("");
    const result = await mutateAsync({
      REQUESTER: userInfo?.email,
      SOURCE: "admin_page",
      CONTACTS: selectedContacts,
    });

    if (result.data.errors > 0) {
      const unprovisionedUsers = result.data.unprovisionedUsers
        .map((user) => user.userName)
        .join(", ")
        .trim();
      setError(`${result.message}: ${unprovisionedUsers}`);
    } else {
      makeToast("success", `${result.message}`, {
        autoClose: 3000,
        containerId: "toaster-admin-table",
      });
      handleOnSuccess && handleOnSuccess();
      toggle();
    }
  }, [mutateAsync, selectedContacts, toggle, userInfo?.email, handleOnSuccess]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <StatusText displayIcon status="danger">
        There was an error fetching the submission. Please try again.
      </StatusText>
    );
  }

  return (
    <FormDetails>
      <FieldLabel label="Contacts">
        <SubText>
          Select the contacts to add them to the Sandbox Dashbaord
        </SubText>
        <Box style={{ position: "relative" }}>
          <ContactSelect
            onContactSelect={onContactSelect}
            selectedCompany={{
              ACCOUNT_ID: data.ACCOUNT_ID,
              ACCOUNT_NAME: "",
              TYPE: "",
            }}
            filteredContactEmails={filteredContactEmails}
            inModal
          />
        </Box>
        <SubmitButton
          variant="primary"
          intent="info"
          disabled={selectedContacts?.length === 0 || isLoadingSubmission}
          onClick={onSubmit}
          loadingState={isLoadingSubmission ? "loading" : "idle"}
        >
          Add Contacts
        </SubmitButton>
      </FieldLabel>
      {isMutationError && (
        <StatusText
          displayIcon
          status="danger"
          style={{ textAlign: "center", marginTop: 16 }}
        >
          There was an error adding contacts to the dashboard. Please try again.
        </StatusText>
      )}
      {error && (
        <StatusText
          displayIcon
          status="danger"
          style={{ textAlign: "center", marginTop: 16 }}
        >
          {error}
        </StatusText>
      )}
    </FormDetails>
  );
};
