import { useState } from "react";
import {
  Box,
  FieldLabel,
  Input,
  TabContent,
  makeToast,
} from "@braze/beacon-core";
import { DatePickerSingle } from "@braze/beacon-lab-datepicker";
import { Select } from "@braze/beacon-lab-select";
import { JiraIssueRequest, useCreateIssue } from "src/hooks/useCreateIssue";
import { getUserInformation } from "src/utils";
import { ThankYou } from "src/pages/thank-you";
import { requiredLabel } from "src/components/required";
import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  Hyperlink,
  InputLabel,
  RequestForm,
  StyledBodyText,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { AccountSearchSelect } from "src/components/AccountSearchSelect";
import { AppGroupSearchSelect } from "src/components/AppGroupSearchSelect";
import { AccountResult, AppGroupResult } from "src/hooks/types";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { StringParam, useQueryParam } from "use-query-params";
import { IndustrySelect } from "src/components/IndustrySearchSelect";
import {
  Links,
  RequestReasonOptions,
  TypeOfData,
  TypeOfDataOptions,
} from "./constants";

export const DataExtract = () => {
  const userInfo = getUserInformation();
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState(1);
  const [typeOfData, setTypeOfData] = useQueryParam(
    "type_of_data",
    StringParam
  );
  const [selectedAccount, setSelectedAccount] = useState<AccountResult>();
  const [appGroup, setAppGroup] = useState<AppGroupResult[]>([]);
  const [industry, setIndustry] = useState<string[]>([]);
  const [requestReason, setRequestReason] = useState<string>("");

  const [mauRequested, setMauRequested] = useState<string>("");
  const [ebrVersion, setEbrVersion] = useState<string>("v1.0");
  const today = new Date();
  // set a default date 3 weeks from today
  const futureDate = new Date(today.getTime() + 21 * 24 * 60 * 60 * 1000);

  const [projectedDate, setProjectedDate] = useState<Date>(futureDate);
  const [availableDate, setAvailableDate] = useState<Date>(futureDate);

  const onSubmit = async () => {
    let issueData = `
        *Reporter*: ${userInfo?.email}
        *Type of Extract*: ${typeOfData}
        *Account Name*: ${selectedAccount?.ACCOUNT_NAME}
        *Account ID*: ${selectedAccount?.ACCOUNT_ID}
        *Dashboard Name*: ${selectedAccount?.DASHBOARD_NAME}
        *Dashboard ID*: ${selectedAccount?.EXTERNAL_ID}
        *App Group Names*: ${appGroup
          .map((app) => app.APP_GROUP_NAME)
          .join(", ")}
        *App Group IDs*: ${appGroup.map((app) => app.APP_GROUP_ID).join(", ")}`;
    if (
      typeOfData === TypeOfData.AnonUsers ||
      typeOfData === TypeOfData.CustomerEngReview ||
      typeOfData === TypeOfData.SmsOptIns
    ) {
      issueData += ` 
        *Industry*: ${industry}`;
    }
    if (typeOfData !== TypeOfData.Ebr2Data) {
      issueData += `
        *Request Reason*: ${requestReason}`;
    }
    if (typeOfData === TypeOfData.Ebr2Data) {
      issueData += `
        *MAU Requested*: ${mauRequested}
        *EBR Version*: ${ebrVersion}
        *Projected Date*: ${projectedDate.toISOString().slice(0, 10)}
        *Available Date*: ${availableDate.toISOString().slice(0, 10)}
        `;
    }

    const jiraConfig = getJiraConfig("Data Extract Request", {
      typeOfData,
    });
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!", typeOfData);
      return; // Required
    }
    const jiraIssueRequest: JiraIssueRequest = {
      fields: {
        project: {
          key: jiraConfig.jiraProjectKey,
        },
        description: issueData,
        summary: `${typeOfData} Request - ${selectedAccount?.ACCOUNT_NAME}`,
        issuetype: {
          name: "Task",
        },
        // eslint-disable-next-line camelcase
        customfield_10000: jiraConfig.epicLink,
        labels: jiraConfig.labels,
      },
    };
    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };

  const resetForm = () => {
    setSelectedAccount(undefined);
    setAppGroup([]);
    setRequestReason("");
  };

  const onSelectAccount = (account?: AccountResult) => {
    setSelectedAccount(account);
  };
  const onAppGroupSelect = (appGroup: AppGroupResult[]) => {
    setAppGroup(appGroup);
  };
  const onIndustrySelect = (industry: string[]) => {
    setIndustry(industry);
  };

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "12.5rem" }}>
        <FormPanel>
          {!(createJiraIssueRes.called && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>Data Extraction</FormHeading>

              {/* <Box>
                <Tabs onChange={(tabId) => setActiveTab(+tabId)}>
                  <Tab tabId={1} active={activeTab === 1}>
                    Request Form
                  </Tab>
                </Tabs>
              </Box> */}
              <TabContent active={activeTab === 1}>
                <FormDetails>
                  <FieldLabel
                    label="Which Extract are you interested in?"
                    htmlFor="data-type"
                    formatLabel={requiredLabel}
                  >
                    <SubText>
                      If you don’t see what you’re looking for, then check out
                      this list of
                      <Hyperlink
                        href={Links.AvailableDataReportsLink}
                        target="_blank"
                      >
                        &nbsp;Available Client Data Reports&nbsp;
                      </Hyperlink>
                      &nbsp;or&nbsp;
                      <Hyperlink
                        href="/tool-support?request_type=Feature Request&type_of_tool=Data Extract"
                        target="_blank"
                      >
                        suggest a new Data Extract/Report.
                      </Hyperlink>
                    </SubText>
                    <Select
                      inputId="data-type"
                      isClearable
                      isDisabled={false}
                      isSearchable
                      fluid
                      options={TypeOfDataOptions}
                      onChange={(selectedOption) => {
                        if (!selectedOption) {
                          resetForm();
                        }
                        setTypeOfData(selectedOption?.value);
                      }}
                      value={TypeOfDataOptions.find(
                        (o) => o.value === typeOfData
                      )}
                    />
                  </FieldLabel>
                  {typeOfData && (
                    <>
                      {typeOfData === TypeOfData.MessagingFrequency && (
                        <StyledBodyText
                          size="md"
                          variant="hint"
                          hidden={false}
                          style={{}}
                        >
                          <Hyperlink
                            href={Links.MessagingFrequencyLink}
                            target="_blank"
                          >
                            Messaging frequency&nbsp;
                          </Hyperlink>
                          plots the number of messages a user received over a
                          given time period, and what the corresponding open
                          rates would be at that level. This extract is used by
                          customers to optimize sending frequency, to see how
                          much is &quot;too much&quot; when it comes to
                          messaging users. This extract pulls the total messages
                          sent over the time period, as well as weekly and
                          monthly frequency data.
                        </StyledBodyText>
                      )}
                      {typeOfData === TypeOfData.OptInRates && (
                        <StyledBodyText size="md" variant="hint">
                          An
                          <Hyperlink
                            href={Links.OptInRatesLink}
                            target="_blank"
                          >
                            &nbsp;opt-in rate&nbsp;
                          </Hyperlink>
                          is a percentage of a device or user base that has
                          allowed a sender to send them messages (&quot;opted
                          in&quot;). Often this is done via an explicit prompt
                          from the sender, but different types of opt-in will
                          have different evaluation criteria. Opt-in rates will
                          be delivered for a trailing 12-month period of time,
                          using a user&apos;s first session month date for the
                          time dimension.
                        </StyledBodyText>
                      )}
                      {typeOfData === TypeOfData.Ebr2Data && (
                        <StyledBodyText size="md">
                          While the new EBR template automation is in V1, you
                          must use this form to{" "}
                          <b>
                            submit an EBR template automation request at least
                            THREE WEEKS before your EBR.
                          </b>
                          <p>
                            <b>Step 1:</b> Data Analytics will load your
                            customer data into the automation
                          </p>
                          <p>
                            <b>Step 2:</b> Data Analytics will message you on
                            Slack once your customer data is loaded and the
                            template is ready to be generated (~1 week later)
                          </p>
                          <p>
                            <b>Step 3:</b> Go into Growlithe and complete the
                            template generation request in "EBR 2.0 Request"
                          </p>
                        </StyledBodyText>
                      )}
                      <FieldLabel
                        label="Account Name"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Select the Account Name as it appears in Salesforce.
                        </SubText>
                        <AccountSearchSelect
                          onSelectAccount={onSelectAccount}
                        />
                      </FieldLabel>
                      <FieldLabel label="App Group" formatLabel={requiredLabel}>
                        <SubText>
                          Select the App Group/s for this request.
                        </SubText>
                        <AppGroupSearchSelect
                          onAppGroupSelect={onAppGroupSelect}
                          selectedAccount={selectedAccount}
                        />
                      </FieldLabel>
                      {(typeOfData === TypeOfData.AnonUsers ||
                        typeOfData === TypeOfData.CustomerEngReview ||
                        typeOfData === TypeOfData.SmsOptIns) && (
                        <FieldLabel
                          label="Industry"
                          formatLabel={requiredLabel}
                        >
                          <SubText>
                            Select the Industry for this request.
                          </SubText>
                          <IndustrySelect
                            onIndustrySelect={onIndustrySelect}
                            selectedAccount={selectedAccount}
                          />
                        </FieldLabel>
                      )}
                      {typeOfData !== TypeOfData.Ebr2Data && (
                        <FieldLabel
                          label="Request Reason"
                          htmlFor="request-reason"
                          formatLabel={requiredLabel}
                        >
                          <Select
                            inputId="request-reason"
                            isClearable
                            isDisabled={false}
                            isSearchable
                            fluid
                            options={RequestReasonOptions}
                            onChange={(selectedOption) => {
                              if (!selectedOption) {
                                resetForm();
                              }
                              setRequestReason(selectedOption?.value);
                            }}
                            value={RequestReasonOptions.find(
                              (o) => o.value === requestReason
                            )}
                          />
                        </FieldLabel>
                      )}
                      {typeOfData === TypeOfData.Ebr2Data && (
                        <>
                          <InputLabel
                            label="Approximate total MAUs of request"
                            htmlFor="mau-requested"
                            formatLabel={requiredLabel}
                          >
                            <Input
                              id="mau-requested"
                              placeholder=""
                              required
                              fullWidth
                              value={mauRequested}
                              onChange={(e) => setMauRequested(e.target.value)}
                            />
                          </InputLabel>

                          <InputLabel
                            label="EBR Version Number (for now please select only v1.0 below)"
                            htmlFor="ebrversion"
                            formatLabel={requiredLabel}
                          >
                            <Box>
                              <input
                                name="ebrversion"
                                onChange={(e) => {
                                  setEbrVersion(e.target.value);
                                }}
                                type="radio"
                                value={ebrVersion}
                                checked
                              />{" "}
                              {ebrVersion}
                            </Box>
                          </InputLabel>
                          <InputLabel
                            label="Projected date of EBR"
                            htmlFor="projected-date"
                            formatLabel={requiredLabel}
                          >
                            <StyledBodyText size="sm" variant="warning">
                              <b>
                                MUST BE AT LEAST 3 WEEKS IN THE FUTURE DATE OF
                                THIS REQUEST
                              </b>
                            </StyledBodyText>
                            <DatePickerSingle
                              onChange={(e) => {
                                setProjectedDate(e ?? futureDate);
                              }}
                              date={projectedDate}
                            />
                          </InputLabel>
                          <InputLabel
                            label="When Does Data Need to be Available by to Validate with your Customer?"
                            htmlFor="available-date"
                            formatLabel={requiredLabel}
                          >
                            <DatePickerSingle
                              onChange={(e) => {
                                setAvailableDate(e ?? futureDate);
                              }}
                              date={availableDate}
                            />
                          </InputLabel>
                        </>
                      )}
                      <InputLabel>
                        At this time, it takes 5 business days to complete a
                        request.
                      </InputLabel>
                      <SubmitButton
                        variant="primary"
                        intent="info"
                        disabled={
                          !(
                            !!typeOfData &&
                            !!selectedAccount &&
                            !!appGroup?.length &&
                            (!!(
                              typeOfData !== TypeOfData.Ebr2Data &&
                              requestReason
                            ) ||
                              !!(
                                typeOfData === TypeOfData.Ebr2Data &&
                                mauRequested &&
                                ebrVersion &&
                                projectedDate &&
                                availableDate
                              ))
                          ) ||
                          createJiraIssueRes.loading ||
                          (createJiraIssueRes.called &&
                            !createJiraIssueRes.error)
                        }
                        onClick={onSubmit}
                      >
                        Submit Request
                      </SubmitButton>
                    </>
                  )}
                </FormDetails>
              </TabContent>
            </FormPanelBody>
          )}
          {createJiraIssueRes.called && !!createJiraIssueRes.value && (
            <ThankYou ticketKey={createJiraIssueRes.value.key} />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
