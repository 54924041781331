export enum EbrSlidebotTemplates {
  EBR2 = "EBR_2.0_TEMPLATE_SLIDEBOT",
}

export const EbrReportType = [
  { label: "Year Over Year", value: "YoY" },
  { label: "Quarter Over Quarter", value: "QoQ" },
];

export const EbrAttributionWindow = [
  { label: "1 Day", value: "1D" },
  { label: "3 Days", value: "3D" },
  { label: "7 Days", value: "7D" },
  { label: "30 Days", value: "30D" },
];

export const EbrExecutiveSummary = [
  { label: "Attributed Revenue", value: "Attributed Revenue" },
  { label: "Sessions", value: "Sessions" },
];

export const EbrValueDrivers = [
  "Maximize Revenue & Profit",
  "Build Brand Equity with Every Interaction",
];

export const EbrMetrics = {
  "Maximize Revenue & Profit": [
    "Total Last Touch Revenue",
    "Revenue by Schedule Type - API Triggered",
    "Revenue by Schedule Type - Action Based",
    "Revenue by Schedule Type - Scheduled",
    "Total Purchase Conversions",
    "Total Purchase Conversions by Schedule Type - API Triggered",
    "Total Purchase Conversions by Schedule Type - Action Triggered",
    "Total Purchase Conversions by Schedule Type - Scheduled",
    "Average Days to Purchase",
  ],
  "Build Brand Equity with Every Interaction": [
    "Quarter/YTD Total Active Users",
    "iOS Users",
    "Android Users",
    "Web Users",
    "Total Number of Sessions",
    "Total Sessions Completed",
    "Total Number of Active Users",
    "Avg. Session Duration per Active User",
    "Avg. No. of Sessions per Active User",
    "Total Push Sent",
    "Total Push Open Rate",
    "Direct Push Open Rate",
    "Influenced Push Open Rate",
    "Total Email Sent",
    "Email Click Rate",
    "Email Clickthrough Rate",
    "Total Content Card Sent",
    "Content Card Click Rate",
    "Total IAM Impressions",
    "Total IAM Click Rate",
  ],
};
