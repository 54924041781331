import { usePut } from "./usePut";
import { API_BASE_URL } from "src/config";
import { useCallback, useState } from "react";
import { Contact, SandBoxFreeTrialStatusType } from "./types";

// eslint-disable-next-line @typescript-eslint/ban-types
export type UpdateSandboxSubmissionResponse = {};

export type UpdateSandBoxSubmissionStatus = {
  SANDBOX_COMPANY_ID?: string;
  SANDBOX_API_KEY?: string;
  JIRA_ID?: string;
  CONTACTS?: Contact[];
  STATUS?: SandBoxFreeTrialStatusType;
  REQUESTER: string;
  SANDBOX_SERVICE_ORIGIN?: string;
  PAYLOAD: string | object | null;
};

export const useUpdateSandboxSubmission = () => {
  const [put, state] = usePut<
    UpdateSandboxSubmissionResponse,
    UpdateSandBoxSubmissionStatus
  >();
  const [response, setResponse] =
    useState<UpdateSandboxSubmissionResponse | null>(null);

  const updateSandboxSubmission = useCallback(
    async (id: string, inputData: UpdateSandBoxSubmissionStatus) => {
      const res = await put(
        `${API_BASE_URL}/api/sandbox/submission/${id}`,
        inputData
      );
      setResponse(res);
    },
    [put]
  );

  return { ...state, updateSandboxSubmission, response };
};
