// API expects data in snake_case, but TYPES uses camelCase

import { mapKeys, snakeCase } from "lodash";

/**
 * Converts the keys of an object from camelCase to snake_case.
 * @param {object} obj - The object to convert.
 * @returns {object} The converted object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertKeysToSnakeCase = (obj: object): any =>
  mapKeys(obj, (value, key) => snakeCase(key).toUpperCase());
