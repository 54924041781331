import { SandboxFreeTrialEnvironmentType } from "src/hooks/types";

const environmentTypes: SandboxFreeTrialEnvironmentType[] = [
  "07",
  "09",
  "sweeney",
  "olaf",
];

export const EnvironmentOptions = environmentTypes.map((envType) => ({
  label: envType,
  value: envType,
}));
