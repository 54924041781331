import {FieldLabel} from "@braze/beacon-core";
import {DefaultOptionType, Select} from "@braze/beacon-lab-select";
import React from "react";
import {SDKEndpointOptions} from "src/components/QRCodeGenerator/constants";
import {QRCodeGeneratorInput, QRCodeGeneratorSection} from "src/components/QRCodeGenerator/styles";

type SDKEndpointSelectProps = {
    endpoint: DefaultOptionType | null
    onEndpointChanged: (endpoint: DefaultOptionType) => void;
}
export const SDKEndpointSelect = ({endpoint, onEndpointChanged}: SDKEndpointSelectProps) => (
        <QRCodeGeneratorSection>
            <QRCodeGeneratorInput>
                <FieldLabel  label="SDK Endpoint" htmlFor="endpoint-select" enforce="required">
                    <Select inputId="endpoint-select"
                            value={endpoint}
                            options={SDKEndpointOptions}
                            onChange={(newValue: DefaultOptionType) => {
                        onEndpointChanged(newValue)
                            }
                    }
                    />
                </FieldLabel>
            </QRCodeGeneratorInput>
        </QRCodeGeneratorSection>
    )
