declare global {
  interface Window {
    REACT_APP_OKTA_CLIENT_ID: string;
    REACT_APP_API_BASE_URL?: string;
  }
}
export const API_BASE_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "";
