import { FormField } from "..";
import { JiraIssueRequest } from "src/hooks/useCreateIssue";
import { getUserInformation } from "src/utils";
import { JiraConfig } from "src/pages/growth/jiraRouting";

export const createJiraDetails = (formState: FormField): string => {
  const userInfo = getUserInformation();
  const {
    productFeature,
    plgMotion,
    requiresSalesforceOpp,
    customEvent,
    typeOfInfo,
    slackChannelId,
    draftCopy,
    callToAction,
  } = formState;

  // Issue description
  const issueData = `
      *Reporter*: ${userInfo?.email}
      * *What is the relevant product feature you're running the PLG experience on?:*
      ** ${productFeature} 
      * *What type of PLG motion are you doing: Learning, Upselling, Free Trialing:*
      ** ${plgMotion} 
      * *Do you believe your PLG experience requires the automatic creation of a Salesforce opportunity once the user has converted?:*
      ** ${requiresSalesforceOpp} 
      * *Specify the Braze custom event and associated properties will you fire when a user completes the conversion event.:*
      ** ${customEvent} 
      * *Upon converting, will the dashboard user be inputting any type of information or message? If yes, ensure the input is passed along in the plg custom event with a specific property like "input".:*
      ** ${typeOfInfo}
      * *Slack Channel ID for your PLG Feature:*
      ** ${slackChannelId} 
      * *Please share draft of the copy you would like to message the client after they have converted from the PLG dashboard experience as a google doc.:*
      ** ${draftCopy} 
      * *Do you have a specific call-to-action for the Account Owner?:*
      ** ${callToAction} 
    `;
  return issueData;
};

// Create jira issue
export const createJiraIssueReq = (
  jiraConfig: JiraConfig,
  formState: FormField
): JiraIssueRequest => {
  const issueData = createJiraDetails(formState);
  const jiraIssueRequest: JiraIssueRequest = {
    fields: {
      project: {
        key: jiraConfig.jiraProjectKey,
      },
      description: issueData,
      summary: `${formState.productFeature}`,
      issuetype: {
        name: "Task",
      },
      // eslint-disable-next-line camelcase
      customfield_10000: jiraConfig.epicLink,
      labels: jiraConfig.labels,
    },
  };
  return jiraIssueRequest;
};
