import { useState } from "react";
import styled from "styled-components";

const GeSupportSlackChannelLink =
  "https://app.slack.com/client/T02520TEB/C0193EHHD2R";

const FeedbackButton = styled.button`
  position: fixed;
  bottom: 10%;
  right: -1.8rem;
  background: #000;
  color: #fff;
  border: none;
  font-weight: 500;
  letter-spacing: 0.2;
  cursor: pointer;
  padding: 8px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  &:focus-visible {
    border: none;
  }
  z-index: 100;
`;

const Backdrop = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const FeedbackModal = styled.div<{ open: boolean }>`
  position: fixed;
  bottom: 7%;
  right: 2%;
  width: 400px;
  padding: 10px 30px 30px 30px;
  background-color: #fff;
  border-radius: 8px;
  display: ${(props) => (props.open ? "block" : "none")};
  z-index: 10000;
`;

const FeedbackForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;
const CloseBtnDiv = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseBtn = styled.button`
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  border: none;
  background-color: transparent;
  margin-bottom: 10px;
  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    border: none;
  }
`;
const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  left: -8px;
  margin-bottom: 10px;
`;

const FeedbackLabel = styled.label`
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 5px;
  letter-spacing: 0.2px;
`;

const RadioInput = styled.input.attrs({
  type: "radio",
})`
  appearance: none;
  -webkit-appearance: none;
  &:checked + label {
    background-color: #000;
    color: #fff;
  }
`;

const RadioBtnLabel = styled.label`
  border: 1px solid #c4cfd9;
  display: flex;
  padding: 5px;
  height: 24px;
  border-radius: 4px;
  font-size: 16px;
  width: 60px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:active {
    font-weight: 600;
  }
`;

const SubmitBtn = styled.button`
  height: 45px;
  width: 100%;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
  &:after {
    content: "Send Feedback";
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #c7c7c7;
  }
`;

const Loader = styled.div`
  width: 25px;
  height: 25px;
  border: 5px solid #2a2a2a;
  border-top-color: #000;
  border-radius: 50%;
  margin-left: 7px;
  margin-top: 10px;
  animation: rotate 1s 0.25s infinite;
  &:after {
    content: "";
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const FeedbackMsg = styled.textarea`
  width: 390px;
  height: 100px;
  border: 1px solid #c4cfd9;
  font-family: sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  padding: 7px 0 0 7px;
  border-radius: 4px;
  letter-spacing: 0.1px;
`;

const FeedbackSubmissionMessage = styled.span`
  display: block;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.2px;
  margin-top: 10px;
`;

const Link = styled.a`
  color: #000;
  font-weight: 600;
`;

type PayloadInfo = {
  email: string;
  productName: string;
};

export const FeedbackSubmissionModal = ({
  email,
  productName,
}: PayloadInfo) => {
  // modal states
  const [feedbackType, setFeedbackType] = useState<"Bug" | "Idea">("Bug");
  const [feedbackMessage, setFeedbackMessage] = useState<string>();
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState<
    "submitted" | "notSubmitted" | "pending" | "failed"
  >("notSubmitted");
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const resetForm = () => {
    setFeedbackType("Bug");
    setFeedbackMessage("");
    setIsFeedbackSubmitted("notSubmitted");
  };
  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => {
    resetForm();
    setIsFeedbackModalOpen(false);
  };

  // Submit feedback form
  const submitFeedback = async () => {
    try {
      // Invoke server-side api and trigger Tray webhook
      const payload = {
        email,
        productType: feedbackType,
        productName,
        currentUrl: window.location.href,
        feedbackMessage,
      };
      setIsFeedbackSubmitted("pending");
      await fetch("https://53055afc-c0ab-437a-8d8d-00a9c0487781.trayapp.io", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        redirect: "follow",
      });
      setIsFeedbackSubmitted("submitted");
    } catch (e) {
      setIsFeedbackSubmitted("failed");
      console.error("Feedback submission failed", e);
    }
  };

  return (
    <>
      <FeedbackButton onClick={openFeedbackModal}>
        <span>Feedback</span>
      </FeedbackButton>
      <Backdrop open={isFeedbackModalOpen} onClick={closeFeedbackModal} />
      <FeedbackModal open={isFeedbackModalOpen}>
        <FeedbackForm>
          <CloseBtnDiv>
            <h3>Growth Engineering Feedback</h3>
            <CloseBtn type="reset" onClick={closeFeedbackModal}>
              &times;
            </CloseBtn>
          </CloseBtnDiv>
          <InputDiv>
            <FeedbackLabel>Feedback Type *</FeedbackLabel>
            <RadioButtonDiv>
              <RadioInput
                type="radio"
                name="feedbackType"
                checked={feedbackType === "Bug"}
              />
              <RadioBtnLabel onClick={() => setFeedbackType("Bug")}>
                Bug
              </RadioBtnLabel>
              <RadioInput
                type="radio"
                name="feedbackType"
                checked={feedbackType === "Idea"}
              />
              <RadioBtnLabel onClick={() => setFeedbackType("Idea")}>
                Idea
              </RadioBtnLabel>
            </RadioButtonDiv>
          </InputDiv>
          <InputDiv>
            <FeedbackLabel>Feedback Message *</FeedbackLabel>
            <FeedbackMsg
              name="feedbackMessage"
              value={feedbackMessage}
              placeholder="Feedback goes here..."
              required
              onChange={(event) => setFeedbackMessage(event.target.value)}
            />
          </InputDiv>
          {isFeedbackSubmitted === "notSubmitted" && (
            <SubmitBtn
              disabled={!feedbackMessage}
              type="button"
              onClick={submitFeedback}
            />
          )}
          {isFeedbackSubmitted === "pending" && <Loader />}
          {isFeedbackSubmitted === "submitted" && (
            <FeedbackSubmissionMessage>
              Feedback Submitted! &#x2705;
            </FeedbackSubmissionMessage>
          )}
          {isFeedbackSubmitted === "failed" && (
            <FeedbackSubmissionMessage>
              Something went wrong! 😿 Please slack{" "}
              <Link href={GeSupportSlackChannelLink} target="_blank">
                #growth-engineering-support
              </Link>
              .
            </FeedbackSubmissionMessage>
          )}
        </FeedbackForm>
      </FeedbackModal>
    </>
  );
};
