/* eslint-disable camelcase */
import { FormField } from "..";
import { JiraIssueRequest } from "src/hooks/useCreateIssue";
import { getUserInformation } from "src/utils";
import { JiraConfig } from "src/pages/growth/jiraRouting";

export const createJiraDetails = (formState: FormField): string => {
  const userInfo = getUserInformation();
  const { title, dataType, requestSummary, workImpact, stakeholders } =
    formState;

  // Issue description
  const issueData = `
    *Reporter*: ${userInfo?.email}
    *Title*: ${title}
    *Summary*: ${requestSummary}
    *Data Type*: ${dataType}
    *Work Impact*: ${workImpact}
    *Key Stakeholders*: ${stakeholders}
  `;
  return issueData;
};

// Create jira issue
export const createJiraIssueReq = (
  jiraConfig: JiraConfig,
  formState: FormField
): JiraIssueRequest => {
  const issueData = createJiraDetails(formState);
  const jiraIssueRequest: JiraIssueRequest = {
    fields: {
      project: {
        key: jiraConfig.jiraProjectKey,
      },
      description: issueData,
      summary: `${formState.title}`,
      issuetype: {
        name: "Task",
      },
      customfield_10000: jiraConfig.epicLink,
      labels: jiraConfig.labels,
    },
  };
  return jiraIssueRequest;
};
