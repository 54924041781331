import { Select } from "@braze/beacon-lab-select";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@braze/beacon-core";

import {
  FormDetails,
  FormHeading,
  FormPanelBodyFull,
  Hyperlink,
  InputLabel,
  RequestForm,
  SubText,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { FindCustomerData } from "./FindCusomerData";
import { getColor } from "@braze/beacon-styling";
import { CSDataGuide, UseCaseOptions } from "./constants";
import { TableCellDescription } from "./styles";

export const CustomerData = () => {
  const [useCase, setUseCase] = useState<string>("");

  const reset = () => { };
  const findCustomerDataFiltered = FindCustomerData.filter((u) =>
    u["UseCase"].includes(useCase)
  );
  return (
    <>
      <NavigateBackButton />
      <RequestForm>
        <FormPanelBodyFull>
          <FormHeading level={2}>Find Customer Data</FormHeading>
          <FormDetails>
            <InputLabel
              label="What is Your Client Having Difficulty With?"
              htmlFor="describe-rquest"
            >
              <SubText>
                Select the use case that best matches your problem, or refer to
                the&nbsp;
                <Hyperlink href={CSDataGuide} target="_blank">
                  CS Data Guide
                </Hyperlink>
                .
              </SubText>
              <Select
                inputId="describe-rquest"
                isClearable
                isDisabled={false}
                isSearchable
                options={UseCaseOptions}
                fluid
                onChange={(selectedOption) => {
                  if (!selectedOption) {
                    reset();
                  }
                  setUseCase(selectedOption?.value);
                }}
                value={UseCaseOptions.find((o) => o.value === useCase)}
              />
            </InputLabel>
            {useCase && (
              <Table verticalRule>
                <TableHeader style={{ color: `${getColor("lightBlue", 500)}` }}>
                  <TableRow>
                    <TableHeaderCell>Asset Name</TableHeaderCell>
                    <TableHeaderCell>Data Source</TableHeaderCell>
                    <TableHeaderCell>Data Type</TableHeaderCell>
                    <TableHeaderCell>Link</TableHeaderCell>
                    <TableHeaderCell width="40%">Description</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                {findCustomerDataFiltered.map((d, index) => (
                  <TableBody key={index}>
                    <TableRow>
                      <TableCell>{d.AssetName}</TableCell>
                      <TableCell>{d.DataSource}</TableCell>
                      <TableCell>{d.DataType}</TableCell>
                      <TableCell>
                        <Hyperlink href={d.Link}>{d.LinkTitle}</Hyperlink>
                      </TableCell>
                      <TableCellDescription width="40%">
                        {d.Description}
                      </TableCellDescription>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            )}
          </FormDetails>
        </FormPanelBodyFull>
      </RequestForm>
    </>
  );
};
