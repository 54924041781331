/* eslint-disable camelcase */
import { FieldLabel, Textarea, makeToast } from "@braze/beacon-core";
import { useCreateIssue } from "src/hooks/useCreateIssue";
import { getUserInformation } from "src/utils";
import { ThankYou } from "src/pages/thank-you";
import { requiredLabel } from "src/components/required";
import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  InputLabel,
  RequestForm,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import {
  AccountResult,
  CompanyResult,
  SandboxFreeTrialEnvironmentType,
  SolconResult,
} from "src/hooks/types";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { CompanySearchSelect } from "src/components/CompanySearchSelect";
import { OpportunitySearchSelect } from "src/components/OpportunitySearchSelect";
import { Contact, ContactSelect } from "src/components/ContactSelect";
import { SolconDetailsSearchSelect } from "src/components/SolconDetailsSearchSelect";
import { useForm } from "src/hooks/useForm";
import { createJiraIssueReq } from "./utils/jiraUtils";
import { useInsertSandboxSubmission } from "src/hooks/useInsertSandboxSubmission";
import { useUpdateSandboxSubmission } from "src/hooks/useUpdateSandboxSubmission";
import { OptionsLabelValue } from "src/components/types";
import { IndustrySelect } from "./utils/IndustrySelect";
import { EnvironmentSelect } from "./utils/EnvironmentSelect";
import { useState } from "react";
import { SandboxTypeSelect } from "./utils/SandboxTypeSelect";

export type FormField = {
  sandboxType: string;
  selectedCompany?: CompanyResult;
  opportunity_id: string;
  contacts: Contact[];
  solconDetail?: SolconResult;
  motivation: string;
  industry: string;
  environment?: SandboxFreeTrialEnvironmentType;
};

export const SandboxDashboard = () => {
  const userInfo = getUserInformation();
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();
  // Provide defaults for required values
  const { formState, setFormFieldValue } = useForm<FormField>({
    sandboxType: "",
    opportunity_id: "",
    contacts: [],
    motivation: "",
    industry: "other",
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    sandboxType,
    selectedCompany,
    opportunity_id,
    contacts,
    solconDetail,
    motivation,
    industry,
    environment,
  } = formState;
  const { insertSandboxSubmission } = useInsertSandboxSubmission();
  const { updateSandboxSubmission } = useUpdateSandboxSubmission();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const jiraConfig = getJiraConfig("Sandbox Dashboard", {});
      if (!jiraConfig) {
        console.error("Missing Jira Routing Config!");
        return; // Required
      }
      const jiraIssueRequest = createJiraIssueReq(jiraConfig, formState);
      let sandboxSubmission;
      if (solconDetail && userInfo?.email && selectedCompany?.ACCOUNT_ID) {
        sandboxSubmission = await insertSandboxSubmission({
          SANDBOX_TYPE: sandboxType,
          ACCOUNT_ID: selectedCompany.ACCOUNT_ID,
          ACCOUNT_NAME: selectedCompany.ACCOUNT_NAME,
          OPPORTUNITY_ID: opportunity_id,
          SOLCON_ID: solconDetail.SOLCON_ID,
          SOLCON_EMAIL: solconDetail.SOLCON_EMAIL,
          SOLCON_NAME: solconDetail.SOLCON_NAME,
          SOLCON_TITLE: solconDetail.SOLCON_TITLE,
          REQUESTER: userInfo.email,
          CONTACTS: contacts,
          MOTIVATION: motivation,
          INDUSTRY: industry,
          STATUS: "requested",
          ENVIRONMENT: environment,
        });
      } else {
        console.error("Missing required fields for snowflake entry.");
      }

      const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
      if (!jiraIssueResponse && createJiraIssueRes.error) {
        makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
      }
      if (
        sandboxSubmission &&
        sandboxSubmission.id &&
        jiraIssueResponse?.key &&
        userInfo?.email
      ) {
        await updateSandboxSubmission(sandboxSubmission.id, {
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          JIRA_ID: jiraIssueResponse.key,
          REQUESTER: userInfo.email,
          PAYLOAD: null as string | object | null,
        });
      } else {
        console.error("Missing sandbox submission / id or email");
      }
    } catch (error) {
      console.error("Error creating Jira issue:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSandboxTypeSelect = (sandboxType: OptionsLabelValue | null) => {
    setFormFieldValue("sandboxType", sandboxType?.value);
  };

  const onSelectCompany = (company?: CompanyResult) => {
    setFormFieldValue("selectedCompany", company);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onOpportunitySelect = (opportunities: any[]) => {
    setFormFieldValue("opportunity_id", opportunities[0]?.value);
  };
  const onContactSelect = (contacts: Contact[]) => {
    setFormFieldValue("contacts", contacts);
  };
  const onSolconDetailsSelect = (solconDetail: SolconResult) => {
    setFormFieldValue("solconDetail", solconDetail);
  };
  const onMotivation = (motivation: string) => {
    setFormFieldValue("motivation", motivation);
  };
  const onIndustrySelect = (industry: OptionsLabelValue | null) => {
    setFormFieldValue("industry", industry?.value);
  };

  const onEnvironmentSelect = (environment: OptionsLabelValue | null) => {
    setFormFieldValue("environment", environment?.value);
  };

  const submitDisabled =
    isLoading ||
    !(
      selectedCompany &&
      opportunity_id &&
      contacts.length &&
      solconDetail &&
      motivation &&
      industry &&
      environment &&
      sandboxType
    ) ||
    createJiraIssueRes.loading;

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "12.5rem" }}>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>Sandbox Dashboard Request</FormHeading>
              <FormDetails>
                <FieldLabel
                  label="Sandbox Type"
                  htmlFor="sandboxType"
                  formatLabel={requiredLabel}
                >
                  <SandboxTypeSelect
                    onSandboxTypeSelect={onSandboxTypeSelect}
                  />
                </FieldLabel>
                <FieldLabel label="Account Name" formatLabel={requiredLabel}>
                  <SubText>
                    Select the Account Name as it appears in Salesforce.
                  </SubText>
                  <CompanySearchSelect
                    onSelectCompany={onSelectCompany}
                    accountTypes={["Target Account"]}
                  />
                </FieldLabel>
                <FieldLabel label="Opportunity" formatLabel={requiredLabel}>
                  <SubText>
                    Select the Opportunity associated with the account.
                  </SubText>
                  <OpportunitySearchSelect
                    onSelectOpportunity={onOpportunitySelect}
                    includeStageName
                    isMulti={false}
                    returnFullOption
                    selectedAccount={
                      selectedCompany
                        ? ({
                            ACCOUNT_ID: selectedCompany?.ACCOUNT_ID,
                          } as AccountResult)
                        : undefined
                    }
                  />
                </FieldLabel>
                <FieldLabel label="Contact" formatLabel={requiredLabel}>
                  <SubText>Select the Contact for this request.</SubText>
                  <ContactSelect
                    onContactSelect={onContactSelect}
                    selectedCompany={selectedCompany}
                  />
                </FieldLabel>
                <FieldLabel
                  label="Solutions Consultant Details"
                  htmlFor="solcon-email"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    Select the details of the solutions consultant that approved
                    the sandbox dashboard access.
                  </SubText>
                  <SolconDetailsSearchSelect
                    onSelectSolconDetail={onSolconDetailsSelect}
                  />
                </FieldLabel>
                <FieldLabel
                  label="Industry"
                  htmlFor="industry"
                  helpText="If other, please use the feedback button to submit other usecases. Will default to ecommerce."
                  formatLabel={requiredLabel}
                >
                  <IndustrySelect onIndustrySelect={onIndustrySelect} />
                </FieldLabel>
                <FieldLabel
                  label="Environment"
                  htmlFor="environment-select"
                  helpText="Please select where your sandbox will be deployed"
                  formatLabel={requiredLabel}
                >
                  <EnvironmentSelect
                    onEnvironmentSelect={onEnvironmentSelect}
                  />
                </FieldLabel>
                <FieldLabel
                  label="Request Summary"
                  htmlFor="request-summary"
                  formatLabel={requiredLabel}
                >
                  <Textarea
                    id="request-summary"
                    vSize="xl"
                    placeholder="Reason for requested sandbox"
                    fullWidth
                    maxLength={500}
                    onChange={(e: { target: { value: string } }) =>
                      onMotivation(e.target.value)
                    }
                  />
                </FieldLabel>
                <InputLabel>
                  At this time, it takes 3-5 business days to complete a
                  request.
                </InputLabel>
                <SubmitButton
                  variant="primary"
                  intent="info"
                  disabled={submitDisabled}
                  onClick={onSubmit}
                >
                  Submit Request
                </SubmitButton>
              </FormDetails>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && !!createJiraIssueRes.value && (
            <ThankYou ticketKey={createJiraIssueRes.value.key} />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
