import { useState } from "react";

export type ModalState<T> = {
  openModal: (data: T) => void;
  isOpen: boolean;
  toggle: () => void;
  data?: T;
  onSubmit?: (data: T) => void;
  onClose?: () => void;
};

export const useModalState = <T>(): ModalState<T> => {
  const [data, setData] = useState<T>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return {
    openModal: (data: T) => {
      setData(data);
      setIsOpen(true);
    },
    isOpen,
    data,
    toggle: () => setIsOpen(!isOpen),
  };
};
