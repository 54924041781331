import React from "react";
import {BodyText, Box, FieldLabel, Heading, HelpText, Input, Switch} from "@braze/beacon-core";
import {CreateUserSectionBox, CreateUserSectionItem, CreateUserSectionItemSwitchBox} from "./styles";
import {QRCodeGeneratorInput} from "src/components/QRCodeGenerator/styles";

type CreateUserSectionProps = {
    isCreateUser: boolean,
    setIsCreateUser: (createUser: boolean) => void;
    isGenerateRandomUser: boolean,
    setIsGenerateRandomUser: (createUser: boolean) => void;
    externalIdRoot: string;
    setExternalIdRoot: (createUser: string) => void;
}

export const CreateUserSection = (
    {   isCreateUser,
        setIsCreateUser,
        isGenerateRandomUser,
        setIsGenerateRandomUser,
        externalIdRoot,
        setExternalIdRoot,
    }: CreateUserSectionProps) => (
    <Box>
        <Heading level={3}>User Creation</Heading>
        <CreateUserSectionBox>
            <CreateUserSectionItem>
                <CreateUserSectionItemSwitchBox>
                    <Switch checked={isCreateUser} onChange={()=> {setIsCreateUser(!isCreateUser)}} />
                    <BodyText size="md" >
                        Create identified user
                    </BodyText>
                </CreateUserSectionItemSwitchBox>
                <HelpText>Pilot will create an identified user after initializing the SDK. Leave disabled for an anonymous user.</HelpText>
            </CreateUserSectionItem>
            <CreateUserSectionItem>
                <CreateUserSectionItemSwitchBox>
                    <Switch checked={isGenerateRandomUser} onChange={()=> {setIsGenerateRandomUser(!isGenerateRandomUser)}} />
                    <BodyText size="md" >
                        Set random attributes
                    </BodyText>
                </CreateUserSectionItemSwitchBox>
                <HelpText>Pilot will set a random value for the user’s name, birthday, and phone number.</HelpText>
            </CreateUserSectionItem>
        </CreateUserSectionBox>
        <CreateUserSectionBox>
            {isCreateUser && (
                <QRCodeGeneratorInput>
                    <FieldLabel  label="External ID Root" enforce="required">
                        <Input placeholder="Type in a value" value={externalIdRoot} onChange={(e) => {setExternalIdRoot(e.target.value)}} />
                        <HelpText className="basicDetailsStatusText">Pilot will set the new user’s external_id to this value, with a random ID appended to it.</HelpText>
                    </FieldLabel>
                </QRCodeGeneratorInput>
            )}
        </CreateUserSectionBox>

    </Box>

)
