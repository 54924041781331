import {Button, Icon} from "@braze/beacon-core";
import React from "react";

type ResetButtonProps = {
    onResetButtonPressed: () => void;
}

export const ResetButton = ({onResetButtonPressed}: ResetButtonProps)=> (
    <Button variant="quaternary" intent="info" size="lg" onClick={onResetButtonPressed}>
        <span>Reset</span>
        <Icon name="sync"/>
    </Button>
)
