import { FieldLabel, Input, Textarea, makeToast } from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";
import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  InputLabel,
  RequestForm,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { requiredLabel } from "src/components/required";
import { useCreateIssue } from "src/hooks/useCreateIssue";
import { useForm } from "src/hooks/useForm";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { ThankYou } from "src/pages/thank-you";
import {
  ProductArea,
  ProductAreaOptions,
  ProductDivision,
  ProductDivisionOptions,
  ProductPriority,
  ProductPriorityOptions,
} from "./data";
import { createJiraIssueReq } from "./utils/jiraUtils";

export type FormField = {
  title: string;
  productArea?: ProductArea;
  summary: string;
  documentLink: string;
  productDivision?: ProductDivision;
  productPriority?: ProductPriority;
  stakeholders: string;
  priorityReason: string;
  additionalInfo: string;
};

export const ProductAnalytics = () => {
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();
  // Provide defaults for required values
  const { formState, setFormFieldValue } = useForm<FormField>({
    title: "",
    summary: "",
    documentLink: "",
    stakeholders: "",
    priorityReason: "",
    additionalInfo: "",
  });

  const {
    title,
    productArea,
    productDivision,
    summary,
    documentLink,
    productPriority,
    priorityReason,
    additionalInfo,
    stakeholders,
  } = formState;

  // Disable submit button if following aren't populated
  const submitDisabled =
    !(
      !!title &&
      !!summary &&
      !!documentLink &&
      !!productDivision &&
      !!productPriority &&
      !!stakeholders
    ) || createJiraIssueRes.loading;

  const onSubmit = async () => {
    const jiraConfig = getJiraConfig("Product Analytics", formState);
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!", formState);
      return; // Required
    }
    const jiraIssueRequest = createJiraIssueReq(jiraConfig, formState);
    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "18rem" }}>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>Product Analytics Request</FormHeading>
              <FormDetails>
                <InputLabel
                  label="Title"
                  htmlFor="title"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    In just a few words, please tell us what this project
                    involves.
                  </SubText>
                  <Input
                    id="title"
                    placeholder=""
                    required
                    fullWidth
                    value={title}
                    onChange={(e) => setFormFieldValue("title", e.target.value)}
                  />
                </InputLabel>
                <FieldLabel
                  label="Summary"
                  htmlFor="summary"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    In 2-3 sentences, please describe the problem you are trying
                    to solve or what you need help with.
                  </SubText>
                  <Textarea
                    id="summary"
                    placeholder=""
                    fullWidth
                    value={summary}
                    vSize="lg"
                    onChange={(e) =>
                      setFormFieldValue("summary", e.target.value)
                    }
                  />
                </FieldLabel>
                <FieldLabel
                  label="Product Division"
                  htmlFor="product-division"
                  formatLabel={requiredLabel}
                >
                  <Select
                    inputId="product-division"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={ProductDivisionOptions}
                    onChange={(selectedOption) => {
                      setFormFieldValue(
                        "productDivision",
                        selectedOption?.value
                      );
                    }}
                    value={ProductDivisionOptions.find(
                      (o) => o.value === productDivision
                    )}
                  />
                </FieldLabel>
                <FieldLabel label="Product Area" htmlFor="product-area">
                  <SubText>Which product area is this impacting?</SubText>
                  <Select
                    inputId="product-area"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={ProductAreaOptions}
                    onChange={(selectedOption) => {
                      setFormFieldValue("productArea", selectedOption?.value);
                    }}
                    value={ProductAreaOptions.find(
                      (o) => o.value === productArea
                    )}
                  />
                </FieldLabel>
                <InputLabel
                  label="Confluence / Google Documentation Link"
                  htmlFor="document-link"
                  formatLabel={requiredLabel}
                >
                  <Input
                    id="document-link"
                    placeholder=""
                    required
                    fullWidth
                    value={documentLink}
                    onChange={(e) =>
                      setFormFieldValue("documentLink", e.target.value)
                    }
                  />
                </InputLabel>

                <FieldLabel
                  label="Product Priority"
                  htmlFor="product-priority"
                  formatLabel={requiredLabel}
                >
                  <Select
                    inputId="product-priority"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={ProductPriorityOptions}
                    onChange={(selectedOption) => {
                      setFormFieldValue(
                        "productPriority",
                        selectedOption?.value
                      );
                    }}
                    value={ProductPriorityOptions.find(
                      (o) => o.value === productPriority
                    )}
                  />
                </FieldLabel>
                {productPriority === ProductPriority.High && (
                  <FieldLabel
                    label="Reason For Priority"
                    htmlFor="priority-reason"
                  >
                    <Textarea
                      id="priority-reason"
                      placeholder=""
                      fullWidth
                      value={priorityReason}
                      vSize="lg"
                      onChange={(e) =>
                        setFormFieldValue("priorityReason", e.target.value)
                      }
                    />
                  </FieldLabel>
                )}
                <InputLabel
                  label="Key Stakeholders"
                  htmlFor="key-stakeholders"
                  formatLabel={requiredLabel}
                >
                  <Input
                    id="key-stakeholders"
                    placeholder=""
                    required
                    fullWidth
                    value={stakeholders}
                    onChange={(e) =>
                      setFormFieldValue("stakeholders", e.target.value)
                    }
                  />
                </InputLabel>
                <FieldLabel label="Additional Info" htmlFor="additional-info">
                  <Textarea
                    id="additional-info"
                    placeholder=""
                    fullWidth
                    value={additionalInfo}
                    vSize="lg"
                    onChange={(e) =>
                      setFormFieldValue("additionalInfo", e.target.value)
                    }
                  />
                </FieldLabel>
                <SubmitButton
                  variant="primary"
                  intent="info"
                  disabled={submitDisabled}
                  onClick={onSubmit}
                >
                  Submit Request
                </SubmitButton>
              </FormDetails>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && !!createJiraIssueRes.value && (
            <ThankYou ticketKey={createJiraIssueRes.value.key} />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
