/* eslint-disable react/no-unstable-nested-components */
import { FlexBox, Heading, Label, StatusText } from "@braze/beacon-core";
import { LoadingSpinner } from "@braze/beacon-labs-spinner";
import { Tag } from "@braze/beacon-lab-tag";
import { DataTable } from "@braze/beacon-labs-data-table";
import { StyledColumn, StyledDataTable } from "./AdminTable.styles";
import { ActionPanel } from "./ActionPanel/ActionPanel";
import { EditModal } from "./EditModal";
import { UserProvisionModal } from "./UserModal";
import { useModalState } from "src/hooks/useModalState";
import { useApi } from "src/hooks/useApi";
import { SandBoxFreeTrialResult } from "src/hooks/types";
import { UserModalFormFieldType } from "./UserModal/types";
import { AllDataModal } from "./AllDataModal";
import { EditModalDataType } from "./EditModal/types";
import { ModalStateWithData } from "./EditModal/EditModal";
import { useState } from "react";
import { Pagination } from "@braze/beacon-lab-pagination";
import { Select } from "@braze/beacon-lab-select";
import { SandboxTypeOptions } from "src/pages/sandbox-dashboard/utils/SandboxTypeSelect";

export type SandBoxSubmissionResponse = {
  submissions: SandBoxFreeTrialResult[];
  totalCount: number;
};

const ITEMS_PER_PAGE = 10;

export const AdminTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const editModalState = useModalState<EditModalDataType>();
  const userModalState = useModalState<UserModalFormFieldType>();
  const allDataModalState = useModalState<SandBoxFreeTrialResult>();

  const { data, isError, isFetching, refetch } =
    useApi<SandBoxSubmissionResponse>(
      [
        "submissions",
        {
          limit: ITEMS_PER_PAGE,
          offset: (currentPage - 1) * ITEMS_PER_PAGE,
          ...(filterType?.value && { type: filterType.value }),
        },
      ],
      `/sandbox/submissions?limit=${ITEMS_PER_PAGE}&offset=${
        (currentPage - 1) * ITEMS_PER_PAGE
      }${filterType ? `&type=${filterType.value}` : ""}`
    );

  const handleEditOnSubmit = () => {
    refetch();
  };

  const handleFilterChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilterType(selectedOption);
  };

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <StatusText displayIcon status="danger">
        There was an error fetching submissions. Please reload the page.
      </StatusText>
    );
  }

  return (
    <>
      <FlexBox justifyContent="space-between">
        <Heading level={2}>Sandbox Admin Dashboard</Heading>

        <Select
          value={filterType || ""}
          onChange={(selectedOption) => {
            handleFilterChange(selectedOption);
          }}
          isClearable
          backspaceRemovesValue
          options={SandboxTypeOptions}
          placeholder="Filter by Sandbox Type"
        />
      </FlexBox>

      <StyledDataTable>
        <DataTable
          style={{ width: "80vw" }}
          columns={[
            {
              childComponent: ({ datum }) => (
                <Label
                  style={{ color: "#008294", cursor: "pointer" }}
                  onClick={() => allDataModalState.openModal(datum)}
                >
                  {datum.ACCOUNT_NAME}
                </Label>
              ),
              id: "account",
            },
            {
              childComponent: ({ datum: { INDUSTRY: industry } }) => (
                <Label>{industry}</Label>
              ),
              id: "industry",
            },
            {
              childComponent: ({ datum: { SANDBOX_TYPE: sandboxType } }) => (
                <Label>{sandboxType}</Label>
              ),
              id: "sandboxType",
            },
            {
              childComponent: ({ datum: { REQUESTER: requester } }) => (
                <Label>{requester}</Label>
              ),
              id: "requester",
            },
            {
              childComponent: ({ datum: { STATUS: sandboxStatus } }) => (
                <Tag>{sandboxStatus}</Tag>
              ),
              id: "sandboxStatus",
            },
            {
              childComponent: ({ datum: { SANDBOX_API_KEY: apiKey } }) => (
                <Label>{apiKey}</Label>
              ),
              id: "apiKey",
            },
            {
              childComponent: ({ datum: { ENVIRONMENT: environment } }) => (
                <Label>{environment}</Label>
              ),
              id: "environment",
            },
            {
              id: "menu",
              childComponent: ({
                datum: {
                  ID: submissionId,
                  STATUS: sandboxStatus,
                  CONTACTS: contacts,
                  SANDBOX_COMPANY_ID: sandboxCompanyId,
                  SANDBOX_API_KEY: sandboxApiKey,
                  ENVIRONMENT: environment,
                  SANDBOX_SERVICE_ORIGIN: sandboxServiceOrigin,
                  ID: id,
                },
              }) => (
                <ActionPanel
                  onEditClick={() =>
                    editModalState.openModal({
                      sandboxCompanyId,
                      sandboxApiKey,
                      environment,
                      sandboxServiceOrigin,
                      id,
                    })
                  }
                  onUserClick={() =>
                    userModalState.openModal({
                      submissionId,
                      contacts,
                      sandboxStatus,
                    })
                  }
                />
              ),
            },
          ]}
          data={data?.submissions || []}
          hideWhenEmpty
        >
          <>
            <StyledColumn columnId="account" width="15%">
              <Heading level={5}>Account</Heading>
            </StyledColumn>
            <StyledColumn columnId="industry" textAlign="left" width="15%">
              <Heading level={5}>Industry</Heading>
            </StyledColumn>
            <StyledColumn columnId="sandboxType" textAlign="left" width="15%">
              <Heading level={5}>Sandbox Type</Heading>
            </StyledColumn>
            <StyledColumn columnId="requester" textAlign="left" width="15%">
              <Heading level={5}>Requester</Heading>
            </StyledColumn>
            <StyledColumn
              columnId="sandboxStatus"
              textAlign="center"
              width="10%"
            >
              <Heading level={5}>Status</Heading>
            </StyledColumn>
            <StyledColumn columnId="apiKey" textAlign="left" width="15%">
              <Heading level={5}>API Key</Heading>
            </StyledColumn>
            <StyledColumn columnId="environment" textAlign="left" width="10%">
              <Heading level={5}>Environment</Heading>
            </StyledColumn>
          </>
        </DataTable>
      </StyledDataTable>
      <FlexBox justifyContent="flex-end">
        {data?.submissions && data?.totalCount && (
          <Pagination
            pages={Math.ceil(data.totalCount / ITEMS_PER_PAGE)}
            currentPageNumber={currentPage}
            onNavigateToPage={(page) => setCurrentPage(page)}
          />
        )}
      </FlexBox>

      {editModalState.isOpen && editModalState.data && (
        <EditModal
          {...(editModalState as ModalStateWithData<EditModalDataType>)}
          onSubmit={handleEditOnSubmit}
        />
      )}
      {userModalState.isOpen && (
        <UserProvisionModal {...userModalState} onClose={refetch} />
      )}
      {allDataModalState.isOpen && <AllDataModal {...allDataModalState} />}
    </>
  );
};
