import { FlexBox, Label } from "@braze/beacon-core";
import { getColor } from "@braze/beacon-styling";
import styled from "styled-components";

export const RequestTile = styled(FlexBox)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const DepartmentLabel = styled(Label)`
  text-transform: uppercase;
  font-size: 18px;
  color: ${getColor("lightBlue", 500)};
  letter-spacing: 0.5px;
  margin: 2rem 2rem 0.5rem 2rem;
  border-bottom: 1px solid #596d7b4a;
`;
