import { dropDownSort } from "src/utils";

export const ConfluenceGrowLink = "https://confluence.braze.com/display/GROW/";

export const Links = {
  AvailableDataReportsLink: `${ConfluenceGrowLink}Customer+Success+Data+Guide`,
  MessagingFrequencyLink: `${ConfluenceGrowLink}Message+Frequency+Extract+Guide`,
  OptInRatesLink: `${ConfluenceGrowLink}Opt-ins+Extract+Guide`,
};

export enum RequestReason {
  EBR = "EBR",
  NewBusiness = "New Business",
  Renewal = "Renewal (at risk)",
  RenewalNot = "Renewal (not at risk)",
  Upsell = "Upsell",
  Other = "Other",
}
export const RequestReasonOptions = dropDownSort([
  {
    value: RequestReason.EBR,
    label: RequestReason.EBR,
  },
  {
    value: RequestReason.NewBusiness,
    label: RequestReason.NewBusiness,
  },
  {
    value: RequestReason.Other,
    label: RequestReason.Other,
  },
  {
    value: RequestReason.Renewal,
    label: RequestReason.Renewal,
  },
  {
    value: RequestReason.RenewalNot,
    label: RequestReason.RenewalNot,
  },
  {
    value: RequestReason.Upsell,
    label: RequestReason.Upsell,
  },
]);

export enum TypeOfData {
  MessagingFrequency = "Messaging Frequency",
  OptInRates = "Email and Push Opt-In Rates",
  AnonUsers = "Anonymous Users",
  CustomerEngReview = "Customer Engagement Review",
  SmsOptIns = "SMS Opt-In Rates",
  Ebr2Data = "EBR 2.0 Request Form",
}

export const TypeOfDataOptions = dropDownSort([
  {
    value: TypeOfData.MessagingFrequency,
    label: TypeOfData.MessagingFrequency,
  },
  {
    value: TypeOfData.OptInRates,
    label: TypeOfData.OptInRates,
  },
  {
    value: TypeOfData.AnonUsers,
    label: TypeOfData.AnonUsers,
  },
  {
    value: TypeOfData.CustomerEngReview,
    label: TypeOfData.CustomerEngReview,
  },
  {
    value: TypeOfData.SmsOptIns,
    label: TypeOfData.SmsOptIns,
  },
  {
    value: TypeOfData.Ebr2Data,
    label: TypeOfData.Ebr2Data,
  },
]);
