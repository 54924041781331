import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@braze/beacon-core";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataValueType = string | number | Array<any> | object;

// Component to render the data value based on its type
const DataValue = ({ value }: { value: DataValueType }) => {
  if (Array.isArray(value)) {
    return (
      <div>
        {value.map((item, index) => (
          <div key={index}>{JSON.stringify(item, null, 2)}</div>
        ))}
      </div>
    );
  } else if (typeof value === "object" && value !== null) {
    return <span>{JSON.stringify(value, null, 2)}</span>;
  } else {
    return <span>{value}</span>;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, react/no-multi-comp
export const AllDataModal = ({ isOpen, toggle, data }: any) => (
  <Modal isOpen={isOpen} toggle={toggle} size="lg">
    <ModalHeading>Submission Data</ModalHeading>
    <ModalBody>
      <Table fullWidth>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Key</TableHeaderCell>
            <TableHeaderCell>Value</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data &&
            Object.entries(data).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  <strong>{key}</strong>
                </TableCell>
                <TableCell>
                  <DataValue value={value as DataValueType} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </ModalBody>
    <ModalFooter aligner side="right">
      <Button onClick={toggle}>Close</Button>
    </ModalFooter>
  </Modal>
);
