import { DateSplitPanel, InputLabel } from "src/components/commonStyles";

import styled from "styled-components";

export const SelectedInputStyle = styled(InputLabel)`
  margintop: "26px";
`;

export const SelectedDateInputStyle = styled(DateSplitPanel)`
  gap: 1rem;
`;
