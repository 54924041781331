// Constants to represent the display name and redirect names

import { DefaultOptionType } from "@braze/beacon-lab-select";

export const redirectLocations: DefaultOptionType[] = [
  {
    value: "projects",
    label: "Apps Screen",
  },
  {
    value: "moviecannon/moviedetails",
    label: "Movie Details",
  },
  {
    value: "moviecannon/splash",
    label: "MovieCannon",
  },
  {
    value: "moviecannon/mcgregor",
    label: "McGregor Movie",
  },
  {
    value: "pantslabyrinth/splash",
    label: "PantsLabyrinth",
  },
  {
    value: "pantslabyrinth/cart",
    label: "PantsLabyrinth Cart",
  },
  {
    value: "pantslabyrinth/wishlist",
    label: "PantsLabyrinth Wishlist",
  },
  {
    value: "setup",
    label: "Setup",
  },
  {
    value: "steppington/goals",
    label: "Steppington Goals",
  },
  {
    value: "steppington/splash",
    label: "Steppington",
  },
  {
    value: "steppington/plus",
    label: "Steppington Plus",
  },
  {
    value: "moviecannon/spider",
    label: "Spider Movie",
  },
  {
    value: "logdata",
    label: "User Profile",
  },
];

export const SDKEndpointOptions: DefaultOptionType[] = [
  {
    value: "pilot-sdk.braze.com",
    label: "pilot-sdk.braze.com",
  },
  {
    value: "sdk.iad-01.braze.com",
    label: "sdk.iad-01.braze.com",
  },
  {
    value: "sdk.iad-02.braze.com",
    label: "sdk.iad-02.braze.com",
  },
  {
    value: "sdk.iad-03.braze.com",
    label: "sdk.iad-03.braze.com",
  },
  {
    value: "sdk.iad-04.braze.com",
    label: "sdk.iad-04.braze.com",
  },
  {
    value: "sdk.iad-05.braze.com",
    label: "sdk.iad-05.braze.com",
  },
  {
    value: "sdk.iad-06.braze.com",
    label: "sdk.iad-06.braze.com",
  },
  {
    value: "sdk.iad-08.braze.com",
    label: "sdk.iad-08.braze.com",
  },
  {
    value: "sdk.fra-01.braze.eu",
    label: "sdk.fra-01.braze.eu",
  },
  {
    value: "sdk.fra-02.braze.eu",
    label: "sdk.fra-02.braze.eu",
  },
];
