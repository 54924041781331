import {
  Box,
  Modal,
  ModalBody,
  ModalHeading,
  Tab,
  TabContent,
  Tabs,
} from "@braze/beacon-core";
import { ModalState } from "src/hooks/useModalState";
import { useCallback, useState } from "react";
import { ProvisionSandboxContacts } from "./ProvisionSandboxContacts";
import { AddSandboxContacts } from "./AddSandboxContacts";
import { UserModalFormFieldType } from "./types";

export const UserProvisionModal = ({
  isOpen,
  toggle,
  data,
  onClose,
}: ModalState<UserModalFormFieldType>) => {
  const isProvisioned = data?.sandboxStatus === "provisioned";
  const [activeTab, setActiveTab] = useState(isProvisioned ? 2 : 1);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleOnSuccess = () => {
    // If any nested API call is successful, set apiCalled to true
    setIsSuccessful(true);
  };

  const handleToggle = useCallback(() => {
    if (isOpen && isSuccessful) {
      onClose && onClose();
      setIsSuccessful(false);
    }
    toggle();
  }, [isOpen, isSuccessful, onClose, toggle]);

  return (
    <Modal className="dialog-base" isOpen={isOpen} toggle={handleToggle}>
      <ModalHeading>User Provisioning Management</ModalHeading>
      <ModalBody>
        <Box>
          <Tabs aria-label="Example list" onChange={(i) => setActiveTab(+i)}>
            <Tab tabId={1} aria-controls="tab-panel-1" active={activeTab === 1}>
              Provision Users
            </Tab>
            {data?.sandboxStatus === "provisioned" && (
              <Tab
                tabId={2}
                aria-controls="tab-panel-2"
                active={activeTab === 2}
              >
                Add Contacts
              </Tab>
            )}
          </Tabs>
          <TabContent active={activeTab === 1}>
            {data && (
              <ProvisionSandboxContacts
                {...data}
                handleOnSuccess={handleOnSuccess}
              />
            )}
          </TabContent>
          <TabContent active={activeTab === 2}>
            <AddSandboxContacts
              {...{ ...data, toggle }}
              handleOnSuccess={handleOnSuccess}
            />
          </TabContent>
        </Box>
      </ModalBody>
    </Modal>
  );
};
