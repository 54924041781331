import {
  Checkbox,
  FieldLabel,
  Flex,
  Input,
  Label,
  TabContent,
  Textarea,
  makeToast,
} from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";
import { JiraIssueRequest, useCreateIssue } from "src/hooks/useCreateIssue";
import { useState } from "react";
import { ThankYou } from "src/pages/thank-you";
import { requiredLabel } from "src/components/required";
import { getUserInformation } from "src/utils";
import {
  ComingSoon,
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  Hyperlink,
  InputLabel,
  RequestForm,
  StyledBodyText,
  SubText,
  SubmitButton,
  TopMargin,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { OpportunitySearchSelect } from "src/components/OpportunitySearchSelect";
import { AccountResult, AppGroupResult } from "src/hooks/types";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { AccountSearchSelect } from "src/components/AccountSearchSelect";
import { AppGroupSearchSelect } from "src/components/AppGroupSearchSelect";
import {
  AnalysisOptions,
  RequestReason,
  RequestReasonOptions,
  RequirementsGuide,
} from "./constants";
import { useForm } from "src/hooks/useForm";
import { AnalyticsReportingFormFields } from "./types";

export const AnalyticsReporting = () => {
  const userInfo = getUserInformation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState(1);
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();

  const { formState, setFormFieldValue } =
    useForm<AnalyticsReportingFormFields>({
      requestReason: "",
      timingConsiderations: "",
      requestSummary: "",
      isConfirmed: false,
      analysis: "",
      risk: "",
      selectedAccount: undefined,
      opportunity: [],
      appGroup: [],
    });

  const {
    requestReason,
    timingConsiderations,
    requestSummary,
    isConfirmed,
    analysis,
    risk,
    selectedAccount,
    opportunity,
    appGroup,
  } = formState;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isConfirmedChecked = (event: any) => {
    setFormFieldValue("isConfirmed", event?.target?.checked);
  };

  const onSubmit = async () => {
    let issueData = `
      *Reporter*: ${userInfo?.email}
      *Account*: ${selectedAccount?.ACCOUNT_NAME}
      *App Group*: ${appGroup}
      *Timing Considerations*: ${timingConsiderations}
      *Request Summary*: ${requestSummary}
      *Request Reason*: ${requestReason}`;
    if (
      requestReason === RequestReason.Sales ||
      requestReason === RequestReason.ISR
    ) {
      issueData += `
        *Opportunity*: ${opportunity}`;
    }
    issueData += `
      *ARR at Risk*: ${risk}
    `;

    const jiraConfig = getJiraConfig("Analytics and Reporting for Customers", {
      analysis,
    });
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!");
      return; // Required
    }
    const jiraIssueRequest: JiraIssueRequest = {
      fields: {
        project: {
          key: jiraConfig.jiraProjectKey,
        },
        description: issueData,
        summary: `${requestReason} Request - ${selectedAccount?.ACCOUNT_NAME}`,
        issuetype: {
          name: "Task",
        },
        // eslint-disable-next-line camelcase
        customfield_10000: jiraConfig.epicLink,
        labels: jiraConfig.labels,
      },
    };
    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };

  const reset = () => {
    setFormFieldValue("requestSummary", "");
  };

  const onSelectAccount = (account?: AccountResult) => {
    // setSelectedAccount(account);
    setFormFieldValue("selectedAccount", account);
  };

  const onSelectOpportunity = (opp: string[]) => {
    setFormFieldValue("opportunity", opp);
  };

  const onAppGroupSelect = (appGroup: AppGroupResult[]) => {
    const appGroupNames = appGroup.map((app) => app.APP_GROUP_NAME);
    setFormFieldValue("appGroup", appGroupNames);
  };

  const submitDisabled =
    !(
      !!requestReason &&
      !!selectedAccount &&
      !!requestSummary &&
      !!isConfirmed
    ) || createJiraIssueRes.loading;

  return (
    <>
      <NavigateBackButton />
      <RequestForm>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>
                Analytics and Reporting for Customers
              </FormHeading>
              <TabContent active={activeTab === 1}>
                <FormDetails>
                  <StyledBodyText>
                    This request type is only available for Strategic Consulting
                    and Customer Success VPs in select cases. Asks coming from
                    individuals outside these groups will be rejected without
                    review.
                    <br />
                    <Hyperlink href={RequirementsGuide} target="_blank">
                      Please consult expectations and requirements for the
                      requests here.
                    </Hyperlink>
                  </StyledBodyText>
                  <br />
                  <FieldLabel
                    label="Account Name / Dashboard Name"
                    formatLabel={requiredLabel}
                  >
                    <SubText>
                      Select the Account Name as it appears in Salesforce.
                    </SubText>
                    <AccountSearchSelect onSelectAccount={onSelectAccount} />
                  </FieldLabel>
                  <FieldLabel label="App Group">
                    <SubText>Select the App Group/s for this request</SubText>
                    <AppGroupSearchSelect
                      onAppGroupSelect={onAppGroupSelect}
                      selectedAccount={selectedAccount}
                    />
                  </FieldLabel>
                  <FieldLabel label="Timing Considerations">
                    <SubText>
                      Are there specific deadlines, related events, or seasonal
                      concerns that impact when this project should be started
                      or delivered?
                    </SubText>
                    <Textarea
                      id="timing-considerations"
                      placeholder=""
                      fullWidth
                      value={timingConsiderations}
                      vSize="lg"
                      onChange={(e) =>
                        setFormFieldValue(
                          "timingConsiderations",
                          e.target.value
                        )
                      }
                    />
                  </FieldLabel>
                  <Flex direction="column">
                    <FieldLabel
                      label="Request Summary"
                      htmlFor="request-summary"
                      formatLabel={requiredLabel}
                    >
                      <Textarea
                        id="other-notes"
                        vSize="xl"
                        placeholder=""
                        fullWidth
                        maxLength={500}
                        value={requestSummary}
                        onChange={(e) =>
                          setFormFieldValue("requestSummary", e.target.value)
                        }
                      />
                    </FieldLabel>
                    <FieldLabel
                      label="Request Reason"
                      htmlFor="describe-request"
                      formatLabel={requiredLabel}
                    >
                      <SubText>What do you need the extract for?</SubText>
                      <Select
                        inputId="describe-request"
                        isClearable
                        isDisabled={false}
                        isSearchable
                        fluid
                        options={RequestReasonOptions}
                        onChange={(selectedOption) => {
                          if (!selectedOption) {
                            reset();
                          }
                          setFormFieldValue(
                            "requestReason",
                            selectedOption?.value
                          );
                        }}
                        value={RequestReasonOptions.find(
                          (o) => o.value === requestReason
                        )}
                      />
                    </FieldLabel>
                    {(requestReason === RequestReason.ISR ||
                      requestReason === RequestReason.Sales) && (
                        <FieldLabel label="Opportunity">
                          <SubText>
                            Select the Opportunity associated with the request -
                            required for an ISR or Sales Opportunity.
                          </SubText>
                          <OpportunitySearchSelect
                            onSelectOpportunity={onSelectOpportunity}
                            selectedAccount={selectedAccount}
                          />
                        </FieldLabel>
                      )}
                    <FieldLabel
                      label="What type of Analysis would you like?"
                      formatLabel={requiredLabel}
                    >
                      <Select
                        inputId="analysis"
                        isClearable
                        isDisabled={false}
                        isSearchable
                        fluid
                        options={AnalysisOptions}
                        onChange={(selectedOption) => {
                          if (!selectedOption) {
                            reset();
                          }
                          setFormFieldValue("analysis", selectedOption?.value);
                        }}
                        value={AnalysisOptions.find(
                          (o) => o.value === analysis
                        )}
                      />
                    </FieldLabel>
                    <InputLabel label="ARR at Risk" htmlFor="at-risk">
                      <Input
                        id="at-risk"
                        placeholder=""
                        required
                        fullWidth
                        value={risk}
                        onChange={(e) =>
                          setFormFieldValue("risk", e.target.value)
                        }
                      />
                    </InputLabel>

                    <Flex style={{ marginTop: "1rem" }}>
                      <Checkbox
                        checked={isConfirmed}
                        onChange={isConfirmedChecked}
                        id="confirmed"
                        indeterminate={false}
                        disabled={false}
                        style={{ marginRight: "1rem" }}
                      />
                      <Flex justifyContent="space-around">
                        <FieldLabel
                          formatLabel={requiredLabel}
                          style={{
                            lineHeight: 0.4,
                          }}
                          label={
                            "I've confirmed that my ask qualifies and that the data is not available via other avenues."
                          }
                        />
                      </Flex>
                    </Flex>
                    <SubmitButton
                      variant="primary"
                      intent="info"
                      disabled={submitDisabled}
                      onClick={onSubmit}
                    >
                      Submit Request
                    </SubmitButton>
                  </Flex>
                </FormDetails>
              </TabContent>
              <TabContent active={activeTab === 2}>
                <ComingSoon style={TopMargin}>
                  <Label>Coming Soon ...</Label>
                </ComingSoon>
              </TabContent>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && !!createJiraIssueRes.value && (
            <ThankYou ticketKey={createJiraIssueRes.value.key} />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
