import {FieldLabel, HelpText} from "@braze/beacon-core";
import {DefaultOptionType, Select} from "@braze/beacon-lab-select";
import React from "react";
import {redirectLocations} from "src/components/QRCodeGenerator/constants";
import {QRCodeGeneratorInput} from "src/components/QRCodeGenerator/styles";

type BasicDetailsSelectProps = {
    demoStartPoint: DefaultOptionType | null
    onDemoStartPointChanged: (startPoint: DefaultOptionType) => void;
}

export const BasicDetailsSelect = (
    {
        demoStartPoint,
        onDemoStartPointChanged
    }: BasicDetailsSelectProps) => (
        <QRCodeGeneratorInput>
            <FieldLabel label="Demo Start Point" htmlFor="basic-select" enforce="required">
                <Select inputId="basic-select"
                        value={demoStartPoint}
                        options={redirectLocations}
                        onChange={(newValue: DefaultOptionType) => {
                            onDemoStartPointChanged(newValue)
                        }}
                />
                <HelpText>Pilot will redirect the user to this screen to begin the demo after the SDK has initialized.</HelpText>
            </FieldLabel>
        </QRCodeGeneratorInput>

    )
