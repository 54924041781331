import { UserClaims } from "@okta/okta-auth-js";

export const parseBoolToYesNo = (input?: boolean): string =>
  input === undefined ? "" : input ? "Yes" : "No";

export const dropDownSort = (
  data: {
    value: string;
    label: string;
  }[]
) => data.sort((a, b) => (a.label < b.label ? -1 : 1));

export const getUserInformation = () => {
  const userInfoString = localStorage.getItem("user");
  const userInfo: UserClaims | null = userInfoString
    ? JSON.parse(userInfoString)
    : null;
  return userInfo;
};
