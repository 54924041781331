import { Flex, Heading, PanelBody } from "@braze/beacon-core";
import styled from "styled-components";

export const StyledTile = styled(PanelBody)<{ disabled?: boolean }>`
  align-items: center;
  width: 350px;
  flex-wrap: wrap;
  border: none;
  border-left: 5px solid transparent;
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      cursor: not-allowed!important;
  `}
  &:after {
    display: block;
    content: "";
    border-left: solid 3px #019fb6;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover {
    background: #cbcbcb59;
    border-left: 5px solid #019fb6;
    cursor: pointer;
    transform: scaleX(1);
  }
`;

export const TileHeader = styled(Flex)`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;

export const TileHeading = styled(Heading)`
  margin-left: 1rem;
  line-height: normal;
`;

export const Description = styled(Flex)`
  margin-top: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  font-weight: 500;
`;
