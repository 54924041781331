import { useCallback, useEffect } from "react";
import {
  Contact,
  SandBoxFreeTrialStatusType,
  SandBoxUserSubmissionStatus,
  SourceType,
} from "src/hooks/types";
import { useApiMutation } from "src/hooks/useApi";
import { Button, FlexBox, StatusText, makeToast } from "@braze/beacon-core";
import { EmailList } from "./EmailList";
import { getUserInformation } from "src/utils";

type ProvisionUsersMutationResponse = {
  message: string;
  data: {
    provisionedUsers: string[];
    unprovisionedUsers: SandBoxUserSubmissionStatus[];
    errors: number;
  };
};

type UseAddSandboxContactsProps = {
  submissionId: string;
  sandboxStatus: SandBoxFreeTrialStatusType;
  contacts: Contact[];
  handleOnSuccess?: () => void;
};

export const ProvisionSandboxContacts = ({
  submissionId,
  sandboxStatus,
  contacts,
  handleOnSuccess,
}: UseAddSandboxContactsProps) => {
  const {
    isLoading,
    error,
    mutateAsync,
    isError,
    isSuccess,
    data: response,
  } = useApiMutation<
    ProvisionUsersMutationResponse,
    unknown,
    {
      REQUESTER: string;
      SOURCE: SourceType;
    }
  >("POST", `/sandbox/provision/${submissionId}`);
  const userInfo = getUserInformation();
  const requester: string = userInfo?.email ?? "";
  const handleSubmit = useCallback(async () => {
    if (isLoading && !requester) {
      return;
    }
    await mutateAsync({
      REQUESTER: requester,
      SOURCE: "admin_page",
    });
  }, [requester]);

  useEffect(() => {
    if (isError) {
      console.error(error);
    }
    if (isSuccess && response) {
      makeToast("success", "Contacts are successfully provisioned!", {
        autoClose: 3000,
        containerId: "toaster-admin-table",
      });
      handleOnSuccess && handleOnSuccess();
    }
  }, [response, isError, error, isSuccess, handleOnSuccess]);
  const isProvisioned = isSuccess || sandboxStatus === "provisioned";
  return (
    <FlexBox direction="column">
      <EmailList
        contacts={contacts}
        provisionSubmitted={isProvisioned}
        // if provisioned, show the contacts as provisioned
        provisionedUsers={response?.data?.provisionedUsers || contacts?.map((contact) => contact.email) || []}
        unprovisionedUsers={response?.data?.unprovisionedUsers ?? []}
      />
      {!isProvisioned && (
        <FlexBox>
          <Button
            loadingState={isLoading ? "loading" : "idle"}
            disabled={isProvisioned}
            onClick={handleSubmit}
          >
            Provision
          </Button>
        </FlexBox>
      )}
      {response?.message && (
        <StatusText displayIcon status="success">
          {response?.message}
        </StatusText>
      )}
      {isError && (
        <StatusText displayIcon status="danger">
          There was an error. Please try again.
        </StatusText>
      )}
      {isProvisioned && (

        <StatusText displayIcon status="success">
          Sandbox already Provisioned
        </StatusText>

      )}
    </FlexBox>
  );
};

export default ProvisionSandboxContacts;
