import { FormField } from "./types";

const generateOptions = (
  enumObject: object
): { label: string; value: string }[] =>
  Object.values(enumObject).map((type) => ({
    label: type,
    value: type,
  }));

export const Templates = [
  { label: "English", value: "PITCHDECK" },
  {
    label: "Brazilian Portuguese",
    value: "PITCHDECK_BRAZILIAN_PORTUGUESE",
  },
  { label: "French", value: "PITCHDECK_FRENCH" },
  { label: "Japanese", value: "PITCHDECK_JAPANESE" },
  { label: "Korean", value: "PITCHDECK_KOREAN" },
  {
    label: "Universal Spanish",
    value: "PITCHDECK_UNIVERSAL_SPANISH",
  },
];

export const FileNamePostfix = {
  PITCHDECK: "Pitch Deck",
  PITCHDECK_BRAZILIAN_PORTUGUESE: "Portuguese Pitch Deck",
  PITCHDECK_FRENCH: "French Pitch Deck",
  PITCHDECK_JAPANESE: "Japanese Pitch Deck",
  PITCHDECK_KOREAN: "Korean Pitch Deck",
  PITCHDECK_UNIVERSAL_SPANISH: "Spanish Pitch Deck",
};

export const EsgValue: string = "ESG";
export const EsgLink: string = "https://www.braze.com/esg-report";

export enum Industry {
  Consumables = "Consumables",
  FinancialServices = "Financial Services",
  Gaming = "Gaming",
  MediaTelecommunications = "Media & Telecommunications",
  OnDemand = "On-Demand",
  ProductivityUtilities = "Productivity & Utilities",
  QSR = "QSR",
  RetailEcommerce = "Retail & eCommerce",
  SocialMessaging = "Social & Messaging",
  TransportationLogistics = "Transportation & Logistics",
  TravelHospitality = "Travel & Hospitality",
  HealthLifestyle = "Health & Lifestyle",
}

export enum Classification {
  SMB = "SMB",
  Scale = "Scale",
  Enterprise = "Enterprise",
  GSA = "GSA",
}

export enum Region {
  AMER = "AMER",
  EMEA = "EMEA",
  APAC = "APAC",
  Japan = "Japan",
}

export enum Products {
  Email = "Email",
  SMS = "SMS",
  WhatsApp = "WhatsApp",
  IAM = "IAM",
  MobilePush = "Mobile Push",
  ContentCards = "Content Cards",
  iOSLiveActivities = "iOS Live Activities",
  WebMessaging = "Web Messaging",
  AudienceSync = "Audience Sync",
  Webhooks = "Webhooks",
  TVSDKs = "TV SDKs",
  Analytics = "Analytics",
  Catalogs = "Catalogs",
  SageAI = "Sage AI",
}

export enum Driver {
  MaximizeRevenueProfit = "Maximize Revenue & Profit",
  ReduceCostEliminateComplexity = "Reduce Cost & Eliminate Complexity",
  ReduceBusinessRiskTodayAsWeScale = "Reduce Business Risk Today & As We Scale",
  BuildBrandEquityWithEveryInteraction = "Build Brand Equity with Every Interaction",
}

export enum Differentiator {
  CrossChannelCapabilitiesSinglePlatform = "Cross-Channel Capabilities on a Single Platform",
  RealTimeCustomerJourneyOrchestration = "Real-Time Customer Journey Orchestration",
  PerformanceReliabilityAtScale = "Performance & Reliability at Scale",
  PrivacyDataSecurityCompliance = "Privacy Data Security & Compliance",
  CorporateMaturity = "Corporate Maturity",
  DomainExpertise = "Domain Expertise",
  FlexibleIntegration = "Flexible Integration",
  EaseOfUse = "Ease of Use",
  OptimizationIntelligentPersonalizationPostSalesExperience = "Optimization/Intelligent Personalization Post-Sales Experience",
}

export enum Competitor {
  Adobe = "Adobe",
  Salesforce = "Salesforce",
  Klaviyo = "Klaviyo",
  Iterable = "Iterable",
  MoEngage = "MoEngage",
}

export enum Narrative {
  POV = "POV",
  Pitch = "Pitch",
}

export const SlidebotPitchDeckFormDefaults: FormField = {
  template: Templates[0],
  opportunity: null,
  narrative: null,
  account: null,
};

export const IndustryOptions = generateOptions(Industry);
export const ClassificationOptions = generateOptions(Classification);
export const RegionOptions = generateOptions(Region);
export const ProductsOptions = generateOptions(Products);
export const DriverOptions = generateOptions(Driver);
export const DifferentiatorOptions = generateOptions(Differentiator);
export const CompetitorOptions = generateOptions(Competitor);
export const NarrativeOptions = generateOptions(Narrative);
