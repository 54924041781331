import { API_BASE_URL } from "src/config";
import { usePost } from "./usePost";
import {
  Classification,
  Competitor,
  Differentiator,
  Driver,
  Industry,
  Products,
  Region,
} from "src/pages/slidebot-pitchdeck/constants";

export type SlidebotRequest = {
  submitter_email?: string;
  submitter_name?: string;
  template_name: string;
  company_name?: string;
  file_name: string;
  config_option: {
    app_group: string;
    ebr_duration: string;
    trend_duration: string;
    industry: string;
    ebr_duration_ago: string;
    trend_duration_ago: string;
    slack_notify_user_email?: string;
    jira_ticket_id?: string;
  };
};

export type PitchDeckSlideBotRequest = {
  submitter_email: string;
  submitter_name: string;
  account_name: string;
  account_id: string;
  template_name: string;
  file_name: string;
  opportunity_id: string;
  narrative?: string;
  config_option: {
    slack_notify_user_email?: string;
    industry?: Industry[];
    micro_classification?: Classification[];
    region?: Region[];
    driver?: Driver[];
    differentiator?: Differentiator[];
    competitor?: Competitor[];
    product?: Products[];
  };
};

export type EbrMergeOptions = {
  Executive_Summary_1?: string;
  Executive_Summary_2?: string;
  Value_Driver_1?: string;
  Value_Driver_2?: string;
  Value_Driver_1_Metric_1?: string;
  Value_Driver_1_Metric_2?: string;
  Value_Driver_1_Metric_3?: string;
  Value_Driver_2_Metric_1?: string;
  Value_Driver_2_Metric_2?: string;
  Value_Driver_2_Metric_3?: string;
};

export type EbrSlideBotRequest = {
  submitter_email: string;
  submitter_name: string;
  company_name?: string;
  company_id?: string;
  template_name: string;
  file_name: string;
  config_option: {
    slack_notify_user_email?: string;
    app_group_id: string;
    end_date_1: string;
    end_date_2: string;
    Report_Type: string;
    Attribution_Window: string;
  };
  merge_option: EbrMergeOptions;
};

export type SlidebotResponse = {
  jobid: string;
  success: boolean;
};

export const useGenerateSlidebot = <
  T extends SlidebotRequest | PitchDeckSlideBotRequest | EbrSlideBotRequest
>(
  route: string
) =>
  usePost<SlidebotResponse, T>({
    url: `${API_BASE_URL}/api/slidebot/${route}`,
  });
