/* eslint-disable camelcase */
import { useEffect, useState } from "react";

import { Select } from "@braze/beacon-lab-select";
import { StatusText } from "@braze/beacon-core";

import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  RequestForm,
  StyledBodyError,
  StyledBodyText,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";

import { requiredLabel } from "src/components/required";
import { AccountResult, AppGroupResult } from "src/hooks/types";
import {
  EbrMergeOptions,
  EbrSlideBotRequest,
  useGenerateSlidebot,
} from "src/hooks/useGenerateSlidebot";
import { SelectOptionsType } from "./types";
import { getUserInformation } from "src/utils";
import { ThankYou } from "src/pages/thank-you";
import {
  EbrAttributionWindow,
  EbrExecutiveSummary,
  EbrMetrics,
  EbrReportType,
  EbrSlidebotTemplates,
  EbrValueDrivers,
} from "./constants";
import { AccountSearchSelect } from "src/components/AccountSearchSelect";
import { AppGroupSearchSelect } from "src/components/AppGroupSearchSelect";
import { SelectedDateInputStyle, SelectedInputStyle } from "./styles";

export const SlidebotFormEbr = () => {
  const [ebrSlidebot, ebrSlidebotResponse] = useGenerateSlidebot<
    EbrSlideBotRequest
  >("generate");

  const userInfo = getUserInformation();
  const now = new Date();
  const previousMonth = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    1
  );
  const [selectedAccount, setSelectedAccount] = useState<AccountResult>();
  const [appGroup, setAppGroup] = useState<string[][]>([]);
  const [reportType, setReportType] = useState<string>(EbrReportType[1].value);
  const [attributionWindow, setAttributionWindow] = useState<string>(
    EbrAttributionWindow[0].value
  );

  const [endDate, setEndDate] = useState<Date>(new Date(previousMonth));
  const arrToOptions = (arrList: string[]): SelectOptionsType[] =>
    arrList.map((arr) => ({ label: arr, value: arr }));

  const defaultRevenueProfitMetrics = [
    "Total Last Touch Revenue",
    "Total Purchase Conversions",
    "Average Days to Purchase",
  ];
  const [revenueProfitMetrics, setRevenueProfitMetrics] = useState<string[]>(
    defaultRevenueProfitMetrics
  );

  const defaultBrandEquityMetrics = [
    "Total Number of Active Users",
    "Avg. Session Duration per Active User",
    "Avg. No. of Sessions per Active User",
  ];

  const monthOptions = [
    { label: "December", value: 11 },
    { label: "November", value: 10 },
    { label: "October", value: 9 },
    { label: "September", value: 8 },
    { label: "August", value: 7 },
    { label: "July", value: 6 },
    { label: "June", value: 5 },
    { label: "May", value: 4 },
    { label: "April", value: 3 },
    { label: "March", value: 2 },
    { label: "February", value: 1 },
    { label: "January", value: 0 },
  ];
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    yearOptions.push({
      label: now.getFullYear() - i,
      value: now.getFullYear() - i,
    });
  }

  // Use last month unless today is before the 5th of the month,
  // then the default end date should be 2 month ago
  const defaultEndDate =
    now.getDate() < 5
      ? new Date(previousMonth.getFullYear(),
      previousMonth.getMonth() - 1, 
      1
    )
      : new Date(previousMonth);
  const [brandEquityMetrics, setBrandEquityMetrics] = useState<string[]>(
    defaultBrandEquityMetrics
  );

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [validEndDate, setValidEndDate] = useState<boolean>(true);

  const arrToMergeConfig = (
    prefix: string,
    type: string,
    arrList: string[],
    max: number = 3
  ) => {
    const mergeConfig: EbrMergeOptions = {};
    for (let i = 0; i < max; i++) {
      mergeConfig[`${prefix}_${type}_${i + 1}` as keyof EbrMergeOptions] =
        arrList[i] || "";
    }
    return mergeConfig;
  };

  useEffect(() => {
    const requiredFieldsFilled =
      !!selectedAccount && !!appGroup.length && !!validEndDate;
    setDisableSubmit(!requiredFieldsFilled);
  }, [selectedAccount, appGroup, validEndDate]);

  const onSelectAccount = (account?: AccountResult) => {
    setSelectedAccount(account);
    setAppGroup([]);
  };

  const onAppGroupSelect = (appGroup: AppGroupResult[]) => {
    const appGroupNames = appGroup.map((app) => [
      app.APP_GROUP_NAME,
      app.APP_GROUP_ID,
    ]);
    setAppGroup(appGroupNames);
  };

  const handleRevenueProfitMetrics = (metrics: SelectOptionsType[]) => {
    setRevenueProfitMetrics(
      metrics?.length ? metrics.map((mt) => mt.value) : []
    );
  };

  const handleBrandEquityMetrics = (metrics: SelectOptionsType[]) => {
    setBrandEquityMetrics(metrics?.length ? metrics.map((mt) => mt.value) : []);
  };

  const handleMonthChange = (month: SelectOptionsType) => {
    const monthValue = parseInt(month.value, 10);
    const newEndDate = new Date(endDate.getFullYear(), monthValue, 1);
    setEndDate(newEndDate);
    validateEndDate(newEndDate);
  };
  const handleYearChange = (year: SelectOptionsType) => {
    const yearValue = parseInt(year.value, 10);
    const newEndDate = new Date(yearValue, endDate.getMonth(), 1);
    setEndDate(newEndDate);
    validateEndDate(newEndDate);
  };

  const validateEndDate = (endDate: Date) => {
    // if a future month/year or previous month is selected but before the 5th,
    // then show error message
    if (
      (now.getDate() < 5 &&
        now.getMonth() === endDate.getMonth() &&
        now.getFullYear() === endDate.getFullYear()) ||
      endDate > previousMonth
    ) {
      setValidEndDate(false);
    } else {
      setValidEndDate(true);
    }
  };
  const onSubmit = async () => {
    try {
      const appGroupInfo = appGroup?.[0] || ["", ""];

      // Set the current and previous date based on the report type
      const monthOffset = reportType === "YoY" ? 12 : 3;
      const endDateFirstOfMonth = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        1
      );
      const previousDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - monthOffset,
        1
      );

      await ebrSlidebot({
        submitter_email: userInfo!.email!,
        submitter_name: userInfo!.name!,
        template_name: EbrSlidebotTemplates.EBR2,
        company_name: selectedAccount?.ACCOUNT_NAME,
        company_id: selectedAccount?.ACCOUNT_ID,
        file_name: `${selectedAccount?.ACCOUNT_NAME || ""} ${
          appGroupInfo?.[0] ? "- " + appGroupInfo?.[0] : ""
        } - `,
        config_option: {
          app_group_id: appGroupInfo[1],
          slack_notify_user_email: userInfo?.email,
          Report_Type: reportType,
          Attribution_Window: attributionWindow,
          end_date_1: [
            endDateFirstOfMonth.getFullYear(),
            ("0" + (endDateFirstOfMonth.getMonth() + 1)).slice(-2),
            ("0" + endDateFirstOfMonth.getDate()).slice(-2),
          ].join("-"),
          end_date_2: [
            previousDate.getFullYear(),
            ("0" + (previousDate.getMonth() + 1)).slice(-2),
            ("0" + previousDate.getDate()).slice(-2),
          ].join("-"),
        },
        merge_option: {
          Executive_Summary_1: EbrExecutiveSummary[0]["value"],
          Executive_Summary_2: EbrExecutiveSummary[1]["value"],
          Value_Driver_1: EbrValueDrivers[0],
          Value_Driver_2: EbrValueDrivers[1],
          ...arrToMergeConfig("Value_Driver_1", "Metric", revenueProfitMetrics),
          ...arrToMergeConfig("Value_Driver_2", "Metric", brandEquityMetrics),
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <NavigateBackButton />
      <RequestForm>
        <FormPanel>
          {ebrSlidebotResponse.called && ebrSlidebotResponse.value ? (
            <>
              <ThankYou textVariation="Slidebot" />
            </>
          ) : (
            <FormPanelBody>
              <>
                <FormHeading level={2} style={{ margin: "12px 0 26px" }}>
                  Slide EBR2.0 Request
                </FormHeading>
                <FormDetails>
                  <StyledBodyText>
                    <b>Overview</b>
                    <br />
                    This request is for the EBR 2.0 deck template and will
                    pre-populated with some initial value metrics based on
                    Attributed Revenue from Braze messaging (sourced from
                    Purchase events), as well as Session Data. If your customer
                    doesn't have purchase or session data, blanks will be shown.
                    The deck also includes a summary of other metrics available
                    for you to choose between + links to relevant Looker
                    dashboards should you wish to further customize (these links
                    will come in future updates).
                    <br />
                    <br />
                    In v1, this model looked to auto-map specific metrics to the
                    value drivers and sub-goals inputted. However, due to the
                    variablity of the actual metrics used by CSMs, the model has
                    been simplified to now auto-generate some standard results
                    with guidance on where to go for richer data sources.
                    <br />
                    <br />
                    Note: Data is not available for US-02 (HIPPA)
                    <br />
                    <br />
                    <b>Any questions?</b>
                    <br />
                    Please read the FAQ at the bottom of the{" "}
                    <a href="https://confluence.braze.com/display/WS/EBR+2.0+Playbook">
                      EBR 2.0 Playbook
                    </a>
                    . Still got questions, post in slack:{" "}
                    <a href="https://app.slack.com/client/E06396JS94K/C01P711TBU7">
                      #ebr-slidebot-questions
                    </a>
                  </StyledBodyText>
                  <SelectedInputStyle
                    label="Company"
                    htmlFor="slidebot-company"
                    formatLabel={requiredLabel}
                  >
                    <AccountSearchSelect onSelectAccount={onSelectAccount} />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="App Group"
                    htmlFor="slidebot-appgroup"
                    formatLabel={requiredLabel}
                  >
                    <StyledBodyText>
                      Data can only be pulled for single app groups. Multi-app
                      group aggregations are not currently possible.
                    </StyledBodyText>
                    <AppGroupSearchSelect
                      onAppGroupSelect={onAppGroupSelect}
                      selectedAccount={selectedAccount}
                      isMulti={false}
                    />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Executive Summary"
                    htmlFor="slidebot-executive-summary"
                  >
                    <StyledBodyText>
                      The Executive Summary slide (slide 5) is now hard coded
                      and will auto-populate *Attributed Revenue* & *Session*
                      count metrics (if available). Once generated, you can
                      either choose to keep or switch out these metrics as you
                      deem most applicable.
                    </StyledBodyText>
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Lookback Comparison Period"
                    htmlFor="slidebot-report-type"
                    formatLabel={requiredLabel}
                  >
                    <StyledBodyText>
                      This is the lookback comparison period for percentage
                      change metrics. You can select either "Quarter over
                      Quarter" or "Year over Year". Customers must have existed
                      for &gt;6months for QoQ data to run and &gt;2 years for
                      YoY. If you're unsure, a 'Customer Lifetime Length' field
                      can be found in Catalyst on the 'CSM Cockpit' view.
                    </StyledBodyText>
                    <Select
                      inputId="slidebot-report-type"
                      isClearable={false}
                      isDisabled={false}
                      isSearchable
                      fluid
                      options={EbrReportType}
                      onChange={(e) => setReportType(e?.value ?? "")}
                      defaultValue={EbrReportType[1]}
                      placeholder="Select a Report Type"
                    />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Starting Month to look back from for Comparison Period"
                    htmlFor="slidebot-time-frame"
                    formatLabel={requiredLabel}
                  >
                    <StyledBodyText>
                      For example, if June 2024 is selected, the Year over Year
                      comparison will start looking back from June 2004 i.e. it
                      will compare the year from June 2024 to July 2023 with
                      June 2023 to July 2022.
                      <br />
                      <br />
                      <b>IMPORTANT:</b> Data loads are conducted monthly, ~5
                      days after the end of a month. Therefore, data for the
                      current month will not be availble until approx. the 5th
                      day of the next month. For example, June data will only be
                      available from the 5th July. Attempting to select data for
                      a month that hasn't yet been loaded will result in zeros
                      in data generated.
                    </StyledBodyText>
                    <SelectedDateInputStyle>
                      <Select
                        inputId="slidebot-time-frame-month"
                        isClearable={false}
                        isDisabled={false}
                        options={monthOptions}
                        onChange={handleMonthChange}
                        defaultValue={
                          monthOptions[12 - defaultEndDate.getMonth() - 1]
                        }
                      />
                      <Select
                        inputId="slidebot-time-frame-year"
                        isClearable={false}
                        isDisabled={false}
                        options={yearOptions}
                        onChange={handleYearChange}
                        defaultValue={{
                          label: defaultEndDate.getFullYear(),
                          value: defaultEndDate.getFullYear(),
                        }}
                      />
                    </SelectedDateInputStyle>
                    {!validEndDate && (
                      <StyledBodyError>
                        Data for the selected month is not yet available
                      </StyledBodyError>
                    )}
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Attribution Window"
                    htmlFor="slidebot-attribution-window"
                    formatLabel={requiredLabel}
                  >
                    <StyledBodyText>
                      We recommend starting with a discussion to align on what
                      best suits your customer’s business model and measurement
                      philosophy. Generally speaking, different industries have
                      found success with the following windows:
                      <br />
                      <br />
                      <b>E-commerce and Retail:</b> A 1-3 day window is common,
                      as customers tend to make quick purchase decisions after
                      engagement. However, if the buying cycle is longer (e.g.,
                      luxury or high-consideration items), a 7-day window may be
                      more suitable.
                      <br />
                      <br />
                      <b>Travel and Hospitality:</b> These industries often
                      benefit from a 7 day window, given the time consumers
                      typically take to research and book trips.
                      <br />
                      <br />
                      <b>Financial Services:</b> Depending on the complexity of
                      the product, a 1-3 day window works well for products like
                      credit cards, while mortgages or investment accounts might
                      require a longer window (e.g., 3-7 days).
                      <br />
                      <br />
                      <b>Media and Entertainment:</b> A 1-3 day window is
                      typical, as users usually decide on media consumption
                      quickly after a touchpoint.
                      <br />
                      <br />
                      <b>General Advice:</b> A shorter window tends to attribute
                      conversions more accurately to specific campaigns but may
                      result in fewer attributed conversions. A longer window
                      captures more attributed revenue but may dilute the
                      strength of attribution.
                      <br />
                      <br />
                    </StyledBodyText>
                    <Select
                      inputId="slidebot-attribution-window"
                      isClearable={false}
                      isDisabled={false}
                      isSearchable
                      fluid
                      options={EbrAttributionWindow}
                      onChange={(e) => setAttributionWindow(e?.value ?? "")}
                      defaultValue={EbrAttributionWindow[0]}
                      placeholder="Select an Attribution Window"
                    />
                  </SelectedInputStyle>
                  <StyledBodyText>
                    The deck will always generate 4 sections/fields for each of 
                    the 4 Value Drivers; delete/update them as applicable for 
                    your customer.
                    <br />
                    <br /> <b>Note</b> - Braze only holds data for the 'Maximise
                    Revenue & Profit' and 'Build Brand Equity' drivers.
                    'Eliminate Cost' and 'Reduce Business Risk' require data
                    from your customer directly and hence this model offers no
                    metric selections for these.
                  </StyledBodyText>
                  <SelectedInputStyle
                    label="Maximize Revenue & Profit Metrics (Max 3)"
                    htmlFor="slidebot-metrics-revenue-profit"
                  >
                    <Select
                      inputId="slidebot-metrics-revenue-profit"
                      isClearable
                      isDisabled={false}
                      isMulti
                      isSearchable
                      fluid
                      options={arrToOptions(
                        EbrMetrics["Maximize Revenue & Profit"]
                      )}
                      defaultValue={arrToOptions(defaultRevenueProfitMetrics)}
                      onChange={handleRevenueProfitMetrics}
                      placeholder="Select relevant metrics for Maximize Revenue & Profit Metrics"
                    />
                  </SelectedInputStyle>
                  <SelectedInputStyle
                    label="Build Brand Equity with Every Interaction Metrics (Max 3)"
                    htmlFor="slidebot-metrics-equity-interaction"
                  >
                    <Select
                      inputId="slidebot-metrics-equity-interaction"
                      isClearable
                      isDisabled={false}
                      isMulti
                      isSearchable
                      fluid
                      options={arrToOptions(
                        EbrMetrics["Build Brand Equity with Every Interaction"]
                      )}
                      defaultValue={arrToOptions(defaultBrandEquityMetrics)}
                      onChange={handleBrandEquityMetrics}
                      placeholder="Select relevant metrics for Build Brand Equity with Every Interaction Metrics"
                    />
                  </SelectedInputStyle>
                  <SubmitButton
                    onClick={onSubmit}
                    disabled={disableSubmit}
                    style={{ marginTop: "60px", width: "100%" }}
                  >
                    Submit Slidebot Request
                  </SubmitButton>
                  {ebrSlidebotResponse.called && ebrSlidebotResponse.error ? (
                    <StatusText displayIcon status="danger">
                      Failed to submit request. Please try again.
                    </StatusText>
                  ) : null}
                </FormDetails>
              </>
            </FormPanelBody>
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
