import { Flex, FlexBox, Label } from "@braze/beacon-core";
import { getSpacing } from "@braze/beacon-styling";
import styled from "styled-components";

export const SignInBackground = styled.div``;

export const SignIn = styled(FlexBox)`
  background: url("../../public/img/braze-gradient.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const SignInBlock = styled(FlexBox)`
  background: #ffffff;
  width: 40%;
  margin: auto;
  padding: 3rem;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-top: 15rem;
  position: relative;
  z-index: 100;

  > * {
    margin-bottom: ${getSpacing("xl")};
  }
`;

export const GrowlitheImgBlock = styled(FlexBox)`
  img {
    height: 168px;
    width: 200px;
    background-blend-mode: soft-light;
  }
`;

export const LogoBlock = styled(FlexBox)`
  justify-content: flex-start;
  > * {
    margin-bottom: 1rem;
  }
  img {
    margin-right: auto;
    margin-bottom: 2rem;
  }
  Button {
    color: #fff;
    cursor: pointer;
    border: none;
    font-size: 16px;
    font-weight: 600;

    -webkit-font-smoothing: antialiased;
    height: 45px;
    width: 200px;
    margin-top: 2.5rem;
  }
`;

export const GrowlitheTitle = styled(Label)`
  font-size: 34px;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
`;

export const NavMenu = styled(Flex)`
  min-height: 93px;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  cursor: pointer;
  img {
    height: 55px;
    padding-left: 2rem;
  }
`;

export const LogoText = styled(Flex)`
  margin-left: 1rem;
  margin-top: 1rem;
  img {
    width: 70px;
    height: 25px;
    padding-right: -1rem;
  }
  Label {
    color: #2e3c47;
    font-size: 34px;
    letter-spacing: -0.75px;
    line-height: 40px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
  }
`;

export const Header = styled(Flex)`
  background: #ffffff;
  padding: 0 4rem;
  box-shadow: 0px 12px 24px rgba(69, 84, 94, 0.1);
  z-index: 1000;
`;

export const Content = styled(Flex)`
  /* min-height: calc(100vh - 104.5px - 32.5px); */
  margin-bottom: ${getSpacing("xxl")};
  display: flex;
  justify-content: center;
`;

export const Footer = styled(Flex)`
  width: 100%;
  font-size: 14px;
  padding: 0.5rem 0;
  justify-content: center;
  background-color: #19242d;
  color: #fff;
  position: fixed;
  bottom: 0;
`;
