import { useOktaAuth } from "@okta/okta-react";
import { useCallback, useState } from "react";

type Props = {
  url: string;
  isRetryable?: boolean;
  retryCount?: number;
};

export const usePost = <T, BodyDataType>(
  props: Props
): [
  (data: BodyDataType) => Promise<T | null>,
  {
    value?: T;
    loading: boolean;
    called: boolean;
    error?: string;
    isSuccess: boolean;
  }
] => {
  const { url, isRetryable = false, retryCount = 3 } = props;
  const { authState } = useOktaAuth();
  const [called, setCalled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<T>();
  const [error, setError] = useState<string>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const fetchCall = useCallback(
    async (data: BodyDataType): Promise<T | null> => {
      setCalled(true);
      setLoading(true);
      setError("");
      setIsSuccess(false);

      const makeRequest = async (attempt: number): Promise<T | null> => {
        try {
          const res = await fetch(`${url}`, {
            headers: {
              "Content-Type": "application/json",
              AUTHORIZATION: authState?.idToken?.idToken || "",
            },
            method: "POST",
            body: JSON.stringify(data),
          });

          if (!res.ok) {
            const errorText = await res.text();
            throw new Error(errorText || res.statusText);
          }

          const result = await res.json();
          setValue(result);
          setIsSuccess(true);
          return result;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (isRetryable && attempt < retryCount) {
            return await makeRequest(attempt + 1);
          } else {
            setError(
              e?.message || "An error occurred while making the API call."
            );
            setIsSuccess(false);
            return null;
          }
        }
      };

      const result = await makeRequest(0);
      setLoading(false);
      return result;
    },
    [url, authState, isRetryable, retryCount]
  );

  return [
    fetchCall,
    {
      value,
      loading,
      error,
      called, // TODO: do we need this?
      isSuccess,
    },
  ];
};
