import { API_BASE_URL } from "src/config";
import { ContactResult } from "./types";
import { useFetch } from "./useFetch";

export const useGetContacts = (contFetchDisabled: boolean, accountId: string) =>
  useFetch<ContactResult[]>({
    url: `${API_BASE_URL}/api/company/contacts`,
    lazyload: contFetchDisabled,
    params: { id: accountId },
  });
