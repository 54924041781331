import { dropDownSort } from "src/utils";

export enum UseCase {
  BadSetup = "Bad Initial Setup",
  UserJourneys = "Build Out User Journeys",
  ChannelExpansion = "Channel Expansion (Web, Content Cards, Email, SMS)",
  DifficultyBrazeSupport = "Difficulty with Braze Support",
  EndlessProductRequests = "Endless Product Requests",
  ExpandingRelationship = "Expanding the Relationship",
  InefficientUsage = "Inefficient Dashboard Usage",
  LeveragingData = "Leveraging Data In A Meaningful Way",
  PartnerOpportunities = "Partner Opportunities",
  PrioritizingFeaturesTools = "Prioritizing New Features & Tools",
  QuantifyingMarketValue = "Quantifying Market Value",
  SufferingBusiness = "Suffering Business",
}

export const UseCaseOptions = dropDownSort([
  {
    value: UseCase.BadSetup,
    label: UseCase.BadSetup,
  },
  {
    value: UseCase.ChannelExpansion,
    label: UseCase.ChannelExpansion,
  },
  {
    value: UseCase.DifficultyBrazeSupport,
    label: UseCase.DifficultyBrazeSupport,
  },
  {
    value: UseCase.EndlessProductRequests,
    label: UseCase.EndlessProductRequests,
  },
  {
    value: UseCase.ExpandingRelationship,
    label: UseCase.ExpandingRelationship,
  },
  {
    value: UseCase.InefficientUsage,
    label: UseCase.InefficientUsage,
  },
  {
    value: UseCase.LeveragingData,
    label: UseCase.LeveragingData,
  },
  {
    value: UseCase.PartnerOpportunities,
    label: UseCase.PartnerOpportunities,
  },
  {
    value: UseCase.PrioritizingFeaturesTools,
    label: UseCase.PrioritizingFeaturesTools,
  },
  {
    value: UseCase.QuantifyingMarketValue,
    label: UseCase.QuantifyingMarketValue,
  },
  {
    value: UseCase.SufferingBusiness,
    label: UseCase.SufferingBusiness,
  },
  {
    value: UseCase.UserJourneys,
    label: UseCase.UserJourneys,
  },
]);

export const CSDataGuide =
  "https://confluence.braze.com/display/GROW/Customer+Success+Data+Guide";
